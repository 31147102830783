
import {
    Badge,
    Card,
    CardHeader,
    CardFooter,
    Media,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    Button
  } from "reactstrap";
  import ReactPaginate from 'react-paginate';
  // core components
//   import Header from "components/Headers/Header.js";
  import React , { useState , useEffect } from "react";
  import axios from 'axios';
  import { useHistory } from 'react-router-dom';
  import queryString from "query-string";
  import { ENDPOINT_CLASSIFIER, ENDPOINT_NODE , ENDPOINT_PDETECTOR, ENDPOINT_PYTHON, ENDPOINT_SRC, ENDPOINT_YOLO } from "../../../backend.js";
  import jwt_decode from "jwt-decode";
  import { useLocation } from "react-router-dom";
  import "./../../../assets/css/tables.css"
//   import { isTemplateSpan } from "typescript";
import MenuLateral from "./../components/menuLateral";
import Pagination from "../../../components/pagination.js";
import Pagination_yolo from "../../../components/pagination_yolo.js";
import Pagination_src from "../../../components/pagination_src.js";
import ImagenesYolo from "./imagenesServersAll/imagenesYolo.js";
import ImagenesSrc from "./imagenesServersAll/imagenesSrc.js";
import ImagenesEye from "./imagenesServersAll/imagenesEye.js";
import ImagenesObjv1 from "./imagenesServersAll/imagenesObjv1.js";
import ImagenesDetector from "./imagenesServersAll/imagenesetector.js";
import Dropdown from "../../../components/hooks/dropdown.js";
  const AllImages = () => {
    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [images, setImages] = useState([]);
    const [allImagesSrc, setAllImagesSrc] = useState([]);
    const [allImagesEye, setAllImagesEye] = useState([]);
    const [allImagesYolo, setAllImagesYolo] = useState([]);
    const [allImagesObjctV1, setAllImagesObjctV1] = useState([]);
    const [allImagesDetector, setAllImagesDetector] = useState([]);
    const [allImagesClassifier, setAllImagesClassifier] = useState([]);
    const [html,setHtml] = useState('')
    const history = useHistory();
    const [msg, setMsg] = useState('');
    const [tokenApi,setTokenApi] =useState('')
    const [tokenApiEye,setTokenApiEye] =useState('')
    const [tokenApiSRC,setTokenApiSRC] =useState('')
    const [tokenApiobjecV1,setTokenApiobjecV1] =useState('')
    const [tokenApidetector,setTokenApidetector] =useState('')
    const [user, setUser] = useState('');
    const [active1,setActive1] = useState(true);
    const [active2,setActive2] = useState(false);
    const [active3,setActive3] = useState(false);
    const [active6, setActive6] = useState(false);
    const [active7, setActive7] = useState(false);
    const [active8, setActive8] = useState(false);
    const [usuarios,setUsuarios] = useState('')
    const [realoadValor,setReloadValor]=useState(0);
    var identificadorTiempoDeEspera = ''
    var identificadorTiempoDeEspera2 = ''
    var identificadorTiempoDeEspera3 = ''
    const items = [
        {
          slug: ()=>server1(),
          anchor: "Eyectracking"
        },
        {
          slug: ()=>server2(),
          anchor: "Rice Field Water Detection"
        },
        {
          slug: ()=>server6(),
          anchor: "Object Detection And Recognition V1"
        },
        {
          slug: ()=>server3(),
          anchor: "Object Detection And Recognition V2"
        },
        {
          slug: ()=>server7(),
          anchor: "Product Detector"
        },
        {
          slug: ()=>server8(),
          anchor: "Product Classifier"
        }
      ];
      const location = useLocation();
      const { button } = queryString.parse(location.search);
    useEffect(() => {
        
        getToken(107,1);
        refreshToken();
        
        tenerTokenSRC();
        // tenerTokenObjec_v1()
        tenerTokenEye();
        tenerTokenDetector()
        tenerToken();
        refrescarToken();
        getUsersAll();
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get(ENDPOINT_NODE+'/token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setUser(decoded.userId)
            setName(decoded.name);
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                history.push("/");
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(ENDPOINT_NODE+'/token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    
    const refrescarToken = () => {
        identificadorTiempoDeEspera = setInterval(tenerTokenEye, 120000);
        identificadorTiempoDeEspera2 = setInterval(tenerTokenSRC, 120000);
        identificadorTiempoDeEspera3 = setInterval(tenerToken, 120000);
    }
    const getToken= async(id_user,service)=>{
        try {
          const response = await axios.post(ENDPOINT_NODE+'/get-password-token', {
              id_user: id_user,
              id_service: service
          });
          console.log("prueba")
          console.log(response.data)
      } catch (error) {
          
          if (error.response) {
            // setMsg(error.response.data.msg);
          }
      }
}
    const tenerToken = () => {
        let formData = new FormData();
        formData.append('username', "cristian@santabros.com.ar");
        formData.append('password', "carlos");
        axios({
            // Endpoint to send files
            url: ENDPOINT_YOLO+"/get-token",
            method: "POST",
            // Attaching the form data
            data: formData,
          })
          .then((response) => {
            setTokenApi(response.data.access_token);
          });
        }
        const tenerTokenEye = () => {
            let formData = new FormData();
            formData.append('username', "cristian@santabros.com.ar");
            formData.append('password', "carlos");
            axios({
                // Endpoint to send files
                url: ENDPOINT_PYTHON+"/get-token",
                method: "POST",
                // Attaching the form data
                data: formData,
              })
              .then((response) => {
                setTokenApiEye(response.data.access_token);
              });
            }
            const tenerTokenSRC = () => {
                let formData = new FormData();
                formData.append('username', "cristian@santabros.com.ar");
                formData.append('password', "carlos");
                axios({
                    // Endpoint to send files
                    url: ENDPOINT_SRC+"/get-token",
                    method: "POST",
                    // Attaching the form data
                    data: formData,
                  })
                  .then((response) => {
                    setTokenApiSRC(response.data.access_token);
                  });
                }
                const tenerTokenObjec_v1 = () => {
                    let formData = new FormData();
                    formData.append('username', "cristian@santabros.com.ar");
                    formData.append('password', "carlos");
                    axios({
                        // Endpoint to send files
                        url: ENDPOINT_PYTHON+"/get-token-yolo",
                        method: "POST",
                        // Attaching the form data
                        data: formData,
                      })
                      .then((response) => {
                        getImages_objecv1(response.data.access_token)
                        setTokenApiobjecV1(response.data.access_token);
                      });
                    }
                    const tenerTokenDetector = () => {
                        let formData = new FormData();
                        formData.append('username', "cristian@santabros.com.ar");
                        formData.append('password', "carlos");
                        axios({
                            // Endpoint to send files
                            url: ENDPOINT_PDETECTOR+"/get-token",
                            method: "POST",
                            // Attaching the form data
                            data: formData,
                          })
                          .then((response) => {
                            setTokenApidetector(response.data.access_token);
                          });
                        }
    const getImages_yolo = async () => {
        try {
          if (allImagesYolo.length != 0) {
            var fecha2 =  "" ;
            const items2 = allImagesYolo; 
  
            for (let i = 0; i<items2.length; i++){
                fecha2 = items2[i].created_at.split(" ")
                items2[i].created_at = fecha2[0] +" " +fecha2[1].slice(0,5);
              }
            var ayuda3 = [];
                for (let i = 0; i<items2.length; i++){
                ayuda3.push(i)
                }
                for (let i = 0; i < items2.length; i++) {
                    const item = items2[i];
                    const user = usuarios.find((u) => u.id === item.id_user);
                    if (user) {
                      item.id_user = user.name;
                    }
                  } 
            
            setHtml(
                <ImagenesYolo informacion={items2.reverse()} ayuda={ayuda3}></ImagenesYolo>
            )
            
          } else {

            await axios.get(ENDPOINT_YOLO+"/api/get-images-all", {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": "Bearer " + tokenApi,
                  }
            }).then((response)=>{
                setAllImagesYolo(response.data);
                var fecha =  "" ;
                const items = response.data    
                for (let i = 0; i<items.length; i++){
                    fecha = items[i].created_at.split(" ") 
                    items[i].created_at = fecha[1] + "-" + fecha[2] + "-" + fecha[3] +" " +fecha[4].slice(0,5);
                }
                var ayuda2 = [];
                    for (let i = 0; i<items.length; i++){
                    ayuda2.push(i)
                    } 
                    for (let i = 0; i < items.length; i++) {
                        const item = items[i];
                        const user = usuarios.find((u) => u.id === item.id_user);
                        if (user) {
                          item.id_user = user.name;
                        }
                      }
                setHtml(
                    <ImagenesYolo informacion={items.reverse()} ayuda={ayuda2}> </ImagenesYolo>
                )
            })
          }
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    }
    const getUsersAll = async () => {
        const response = await axiosJWT.get(ENDPOINT_NODE + "/users", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUsuarios(response.data)
      };
    const getImages_eye = async () => {
        try {
          if (allImagesEye.length != 0) {
            var fecha2 =  "" ;
            const items2 = allImagesEye; 
            for (let i = 0; i<items2.length; i++){
                fecha2 = items2[i].created_at.split(" ")
                items2[i].created_at = fecha2[0] +" " +fecha2[1].slice(0,5);
              }
            var ayuda3 = [];
                for (let i = 0; i<items2.length; i++){
                ayuda3.push(i)
                }
                for (let i = 0; i < items2.length; i++) {
                    const item = items2[i];
                    const user = usuarios.find((u) => u.id === item.id_user);
                    if (user) {
                      item.id_user = user.name;
                    }
                  } 
            
            setHtml(
                <ImagenesEye informacion={items2.reverse()} ayuda={ayuda3}></ImagenesEye>
            )
            
          } else {

            await axios.get(ENDPOINT_PYTHON+"/api/get-images-all",
             {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": "Bearer " + tokenApiEye,
                  }
            }).then((response)=>{
                setImages(response.data)
                setAllImagesEye(response.data);
                var fecha =  "" ;
                const items = response.data    
                for (let i = 0; i<items.length; i++){
                    fecha = items[i].created_at.split(" ") 
                    items[i].created_at = fecha[1] + "-" + fecha[2] + "-" + fecha[3] +" " +fecha[4].slice(0,5);
                }
                var ayuda2 = [];
                    for (let i = 0; i<items.length; i++){
                    ayuda2.push(i)
                    }
                    for (let i = 0; i < items.length; i++) {
                        const item = items[i];
                        const user = usuarios.find((u) => u.id === item.id_user);
                        if (user) {
                          item.id_user = user.name;
                        }
                      }
                setHtml(
                    <ImagenesEye informacion={items.reverse()} ayuda={ayuda2}></ImagenesEye>
                )
            })
          }
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    }
    const getImages_src = async () => {
        try {
          if (allImagesSrc.length != 0) {
          var fecha2 =  "" ;
          const items2 = allImagesSrc; 

          for (let i = 0; i<items2.length; i++){
              fecha2 = items2[i].created_at.split(" ")
              items2[i].created_at = fecha2[0] +" " +fecha2[1].slice(0,5);
            }
          var ayuda3 = [];
              for (let i = 0; i<items2.length; i++){
              ayuda3.push(i)
              }
              for (let i = 0; i < items2.length; i++) {
                  const item = items2[i];
                  const user = usuarios.find((u) => u.id === item.id_user);
                  if (user) {
                    item.id_user = user.name;
                  }
                } 
          
          setHtml(
              <ImagenesSrc informacion={items2.reverse()} ayuda={ayuda3}></ImagenesSrc>
          )
          
        } else {
          
        
            await axios.get(ENDPOINT_SRC+"/api/get-images-all",
             {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": "Bearer " + tokenApiSRC,
                  }
            }).then((response)=>{
                setImages(response.data)
                setAllImagesSrc(response.data);
                var fecha =  "" ;
                const items = response.data    
                for (let i = 0; i<items.length; i++){
                    fecha = items[i].created_at.split(" ") 
                    items[i].created_at = fecha[1] + "-" + fecha[2] + "-" + fecha[3] +" " +fecha[4].slice(0,5);
                }
                var ayuda2 = [];
                    for (let i = 0; i<items.length; i++){
                    ayuda2.push(i)
                    }
                    for (let i = 0; i < items.length; i++) {
                        const item = items[i];
                        const user = usuarios.find((u) => u.id === item.id_user);
                        if (user) {
                          item.id_user = user.name;
                        }
                      } 
                setHtml(
                    <ImagenesSrc informacion={items.reverse()} ayuda={ayuda2}></ImagenesSrc>
                )
            })
          }
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    }
    const getImages_detector = async () => {
        try {
          if (allImagesDetector.length != 0) {
            var fecha2 =  "" ;
            const items2 = allImagesDetector; 
  
            for (let i = 0; i<items2.length; i++){
                fecha2 = items2[i].created_at.split(" ")
                items2[i].created_at = fecha2[0] +" " +fecha2[1].slice(0,5);
              }
            var ayuda3 = [];
                for (let i = 0; i<items2.length; i++){
                ayuda3.push(i)
                }
                for (let i = 0; i < items2.length; i++) {
                    const item = items2[i];
                    const user = usuarios.find((u) => u.id === item.id_user);
                    if (user) {
                      item.id_user = user.name;
                    }
                  } 
            
            setHtml(
                <ImagenesDetector informacion={items2.reverse()} ayuda={ayuda3}></ImagenesDetector>
            )
            
          } else {

            await axios.get(ENDPOINT_PDETECTOR+"/api/get-images-all-detector",
             {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": "Bearer " + tokenApidetector,
                  }
            }).then((response)=>{
                setImages(response.data)
                setAllImagesDetector(response.data);
                var fecha =  "" ;
                const items = response.data    
                for (let i = 0; i<items.length; i++){
                    fecha = items[i].created_at.split(" ") 
                    items[i].created_at = fecha[1] + "-" + fecha[2] + "-" + fecha[3] +" " +fecha[4].slice(0,5);
                }
                var ayuda2 = [];
                    for (let i = 0; i<items.length; i++){
                    ayuda2.push(i)
                    }
                    for (let i = 0; i < items.length; i++) {
                        const item = items[i];
                        const user = usuarios.find((u) => u.id === item.id_user);
                        if (user) {
                          item.id_user = user.name;
                        }
                      }
                setHtml(
                    <ImagenesDetector informacion={items.reverse()} ayuda={ayuda2}></ImagenesDetector>
                )
            })
          }
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    }
    const getImages_classifier = async () => {
        try {
          if (allImagesClassifier.length != 0) {
            var fecha2 =  "" ;
            const items2 = allImagesClassifier; 
  
            for (let i = 0; i<items2.length; i++){
                fecha2 = items2[i].created_at.split(" ")
                items2[i].created_at = fecha2[0] +" " +fecha2[1].slice(0,5);
              }
            var ayuda3 = [];
                for (let i = 0; i<items2.length; i++){
                ayuda3.push(i)
                }
                for (let i = 0; i < items2.length; i++) {
                    const item = items2[i];
                    const user = usuarios.find((u) => u.id === item.id_user);
                    if (user) {
                      item.id_user = user.name;
                    }
                  } 
            
            setHtml(
                <ImagenesDetector informacion={items2.reverse()} ayuda={ayuda3}></ImagenesDetector>
            )
            
          } else {

            await axios.get(ENDPOINT_CLASSIFIER+"/api/get-images-all",
             {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": "Bearer " + tokenApidetector,
                  }
            }).then((response)=>{
                setImages(response.data)
                setAllImagesClassifier(response.data);
                var fecha =  "" ;
                const items = response.data    
                for (let i = 0; i<items.length; i++){
                    fecha = items[i].created_at.split(" ") 
                    items[i].created_at = fecha[1] + "-" + fecha[2] + "-" + fecha[3] +" " +fecha[4].slice(0,5);
                }
                var ayuda2 = [];
                    for (let i = 0; i<items.length; i++){
                    ayuda2.push(i)
                    }
                    for (let i = 0; i < items.length; i++) {
                        const item = items[i];
                        const user = usuarios.find((u) => u.id === item.id_user);
                        if (user) {
                          item.id_user = user.name;
                        }
                      }
                setHtml(
                    <ImagenesDetector informacion={items.reverse()} ayuda={ayuda2}></ImagenesDetector>
                )
            })
          }
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    }
    const getImages_objecv1 = async (tok) => {
        try {
          if (allImagesObjctV1.length != 0) {
            var fecha2 =  "" ;
            const items2 = allImagesObjctV1; 
  
            for (let i = 0; i<items2.length; i++){
                fecha2 = items2[i].created_at.split(" ")
                items2[i].created_at = fecha2[0] +" " +fecha2[1].slice(0,5);
              }
            var ayuda3 = [];
                for (let i = 0; i<items2.length; i++){
                ayuda3.push(i)
                }
                for (let i = 0; i < items2.length; i++) {
                    const item = items2[i];
                    const user = usuarios.find((u) => u.id === item.id_user);
                    if (user) {
                      item.id_user = user.name;
                    }
                  } 
            
            setHtml(
                <ImagenesObjv1 informacion={items2.reverse()} ayuda={ayuda3}></ImagenesObjv1>
            )
            
          } else {

            await axios.get(ENDPOINT_PYTHON+"/api/get-images-all-yolo",
             {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": "Bearer " + tok,
                  }
            }).then((response)=>{
                setImages(response.data)
                setAllImagesObjctV1(response.data);
                var fecha =  "" ;
                const items = response.data    
                for (let i = 0; i<items.length; i++){
                    fecha = items[i].created_at.split(" ") 
                    items[i].created_at = fecha[1] + "-" + fecha[2] + "-" + fecha[3] +" " +fecha[4].slice(0,5);
                }
                var ayuda2 = [];
                    for (let i = 0; i<items.length; i++){
                    ayuda2.push(i)
                    }
                    for (let i = 0; i < items.length; i++) {
                        const item = items[i];
                        const user = usuarios.find((u) => u.id === item.id_user);
                        if (user) {
                          item.id_user = user.name;
                        }
                      }
                setHtml(
                    <ImagenesObjv1 informacion={items.reverse()} ayuda={ayuda2}></ImagenesObjv1>
                )
            })
          }
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    }
    const prueba = (button) => {
        switch (button) {
          case "1":
            setTimeout(() => {
              document.getElementById("button1").click();
            }, 2000);
            break;
          case "2":
            setTimeout(() => {
              document.getElementById("button2").click();
            }, 2000);
            break;
          case "3":
            setTimeout(() => {
              document.getElementById("button3").click();
            }, 2000);
            break
          case "4":
            setTimeout(() => {
              document.getElementById("button4").click();
            }, 2000);
            break;
          case "5":
            setTimeout(() => {
              document.getElementById("button5").click();
            }, 2000);
            break;
          default:
            break;
        }
      }
    const server1 = () => {
        getImages_eye()
        setActive1(true);
        setActive2(false);
        setActive3(false)
        setActive6(false);
        setActive7(false);
        setActive8(false);
    }
    const server2 = () => {
        getImages_src(user)
        setActive1(false);
        setActive2(true);
        setActive3(false);
        setActive6(false);
        setActive7(false);
        setActive8(false);
        
    }
    const server3 = () => {
        getImages_yolo()
        setActive1(false);
        setActive2(false);
        setActive3(true);
        setActive6(false);
        setActive7(false);
        setActive8(false);
        
    }
    const server6 = () => {
        tenerTokenObjec_v1()
        // getImages_objecv1();
        setReloadValor(4)
        setActive1(false);
        setActive2(false);
        setActive3(false);
        setActive6(true);
        setActive7(false);
        setActive8(false);
        
      };
      const server7 = () => {
        getImages_detector();
        setReloadValor(5)
        setActive1(false);
        setActive2(false);
        setActive3(false);
        setActive6(false);
        setActive7(true);
        setActive8(false);
      };
      const server8 = () => {
        getImages_classifier();
        setReloadValor(6)
        setActive1(false);
        setActive2(false);
        setActive3(false);
        setActive6(false);
        setActive7(false);
        setActive8(true);
      };

    return (
        <><MenuLateral></MenuLateral>
        <div className="mcw">
    <div className="cv">
      <div>
       <div className="inbox">
         <div className="inbox-sb">
           
         </div>
         <div className="inbox-bx container-fluid">
           <div className="row">
              <div className='container'>
              <br></br>
              <Container  fluid>
            {/* Table */}
            
            <div className="container mt-5">
            <h3 className='h3 text-white'>Select Service</h3> 
            <div className='row'>
                <div className='col-9 mb-3'>
                <Dropdown dropdownTitle="List of Services" items={items} numberbutton={button} funcion={prueba}/>
                    {/* <div>
                        
                        <button className='btn btn-warning mr-2 mb-3 text-white bordewhite' onClick={server1}>Eye-Tracking</button>
                        <button className='btn btn-warning mr-2 mb-3 text-white bordewhite' onClick={server2}>Rice Field Water Detection</button>
                        <button className='btn btn-warning mr-2 mb-3 text-white bordewhite' onClick={server3}>Object Detection And Recognition</button>
                    </div> */}
                </div>
                <div className='col-3 ms-auto'>
                    
                </div>

            </div>
            
                
            {((active1) && (

                <div>                   
                    {html}
                </div>))
            || ((active2) && (<div className='text-white'>
                    {html}
            </div>))
            || ((active3) && (<div>                   
                    {html}
                </div>))
                || ((active6) && (<div>                   
                    {html}
                </div>))
                || ((active7) && (<div>                   
                    {html}
                </div>))
                || ((active8) && (<div>                   
                    {html}
                </div>))
                }
        </div>
          </Container>
          <br></br>
              </div>
              
           </div>
         </div>
       </div>
      </div>
    </div>
  </div>
            
        </>
        
    )
  };
  
  export default AllImages;
  