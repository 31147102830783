import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Button,
} from "reactstrap";
// core components
//   import Header from "components/Headers/Header.js";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import {
  ENDPOINT_CLASSIFIER,
  ENDPOINT_NODE,
  ENDPOINT_PDETECTOR,
  ENDPOINT_PYTHON,
  ENDPOINT_SRC,
  ENDPOINT_YOLO,
} from "../../backend";
import Header from "./components/headers";
import jwt_decode from "jwt-decode";
import "./../../assets/css/tables.css";
//   import { isTemplateSpan } from "typescript";
import MenuLateral from "./components/menuLateral";
import PaginationEye from "./components/paginationUsers/paginationEye";
import PaginationYolo from "./components/paginationUsers/paginationYolo";

const Tables = () => {
  // const [token, setToken] = useState('');
  // const [expire, setExpire] = useState('');
  const [users, setUsers] = useState([]);
  const [usersPendient, setUsersPendient] = useState([]);
  const history = useHistory();
  // const [id_user,setId_user] = useState();
  const [msg, setMsg] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");
  const [usersAll, setUsersAll] = useState([]);
  const [cambio, setCambio] = useState(true);
  const [cambio2, setCambio2] = useState(true);
  const [html, setHtml] = useState("");
  const [html1, setHtml1] = useState("");
  const [tokenApi, setTokenApi] = useState("");
  const [tokenApiEye, setTokenApiEye] = useState("");
  const [tokenApiSRC, setTokenApiSRC] = useState("");
  const [tokenApiobjecV1, setTokenApiobjecV1] = useState("");
  const [tokenApidetector, setTokenApidetector] = useState("");
  useEffect(() => {
    getUsersAll();
    refreshToken();
    // tenerTokenObjec_v1()
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(ENDPOINT_NODE + "/token");
      setToken(response.data.accessToken);
      const decoded = jwt_decode(response.data.accessToken);

      setExpire(decoded.exp);
    } catch (error) {
      if (error.response) {
        history.push("/");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(ENDPOINT_NODE + "/token");
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwt_decode(response.data.accessToken);

        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getServicesActive = async (usuariosCompletos) => {
    try {
      await axios
        .get(
          ENDPOINT_NODE + "/active_services_uno"
          // {
          //   headers: {
          //     Authorization: `Bearer ${tToken}`,
          //   },
          // }
        )
        .then((response) => {
          let emails = [];
          let usuariosEmail = [];
          if (usuariosCompletos != undefined && response.data != undefined) {
            emails = usuariosCompletos;
            usuariosEmail = response.data;
            for (let j = 0; j < usuariosEmail.length; j++) {
              for (let i = 0; i < emails.length; i++) {
                if (emails[i].id == usuariosEmail[j].user_id) {
                  usuariosEmail[j].email = emails[i].email;
                  usuariosEmail[j].name = emails[i].name;
                }
              }
            }
          }
          var ayuda2 = [];
          for (let i = 0; i < usuariosEmail.length; i++) {
            ayuda2.push(i);
          }
          setHtml(
            <PaginationYolo
              informacion={usuariosEmail.reverse()}
              ayuda={ayuda2}
              baja={darBaja}
            ></PaginationYolo>
          );
        });
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  const getServicesPendient = async (usuariosCompletos) => {
    try {
      await axios
        .get(
          ENDPOINT_NODE + "/active_services_dos"
          // {
          //   headers: {
          //     Authorization: `Bearer ${tToken}`,
          //   },
          // }
        )
        .then((response) => {
          let emails = [];
          let usuariosEmail = [];
          if (usuariosCompletos != undefined && response.data != undefined) {
            emails = usuariosCompletos;
            usuariosEmail = response.data;
            for (let j = 0; j < usuariosEmail.length; j++) {
              for (let i = 0; i < emails.length; i++) {
                if (emails[i].id == usuariosEmail[j].user_id) {
                  usuariosEmail[j].email = emails[i].email;
                  usuariosEmail[j].name = emails[i].name;
                }
              }
            }
          }
          var ayuda2 = [];
          for (let i = 0; i < usuariosEmail.length; i++) {
            ayuda2.push(i);
          }
          setHtml1(
            <PaginationEye
              informacion={usuariosEmail.reverse()}
              ayuda={ayuda2}
              baja={darBaja}
              alta={darAlta}
              send={sendMailConfirm}
            ></PaginationEye>
          );
        });
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  const darBaja = async (user, service, email) => {
    try {
      await axios.post(ENDPOINT_NODE + "/change-status", {
        user_id: user,
        service: service,
        status: 0,
      });

      desactivarServicioServer(email, service);
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  const darAlta = async (user, service, email) => {
    try {
      await axios.post(ENDPOINT_NODE + "/change-status", {
        user_id: user,
        service: service,
        status: 1,
      });
      activarServicioServer(email, service);
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  const sendMailConfirm = async () => {
    try {
      await axios.post(ENDPOINT_NODE + "/forgot-password", {
        username: "edwinfranco06@gmail.com",
      });
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  const activarServicioServer = (usuario, server) => {
    var servidor = 0;
    if (server == "eyetracking") {
      let formData = new FormData();
      formData.append("username", "cristian@santabros.com.ar");
      formData.append("password", "carlos");
      axios({
        // Endpoint to send files
        url: ENDPOINT_PYTHON + "/get-token",
        method: "POST",
        // Attaching the form data
        data: formData,
      }).then((response) => {
        cambiaroEye(usuario, 1, response.data.access_token);
      });
    } else if (server == "rice_field") {
      let formData = new FormData();
      formData.append("username", "cristian@santabros.com.ar");
      formData.append("password", "carlos");
      axios({
        // Endpoint to send files
        url: ENDPOINT_SRC + "/get-token",
        method: "POST",
        // Attaching the form data
        data: formData,
      }).then((response) => {
        cambiaroSRC(usuario, 1, response.data.access_token);
      });
    } else if (server == "object_v2") {
      let formData = new FormData();
      formData.append("username", "cristian@santabros.com.ar");
      formData.append("password", "carlos");
      axios({
        // Endpoint to send files
        url: ENDPOINT_YOLO + "/get-token",
        method: "POST",
        // Attaching the form data
        data: formData,
      }).then((response) => {
        cambiarobjv2(usuario, 1, response.data.access_token);
      });
    } else if (server == "object_v1") {
      let formData = new FormData();
      formData.append("username", "cristian@santabros.com.ar");
      formData.append("password", "carlos");
      axios({
        // Endpoint to send files
        url: ENDPOINT_PYTHON + "/get-token-yolo",
        method: "POST",
        // Attaching the form data
        data: formData,
      }).then((response) => {
        cambiarobjv1(usuario, 1, response.data.access_token);
        setTokenApiobjecV1(response.data.access_token);
      });
    } else if (server == "product_detector") {
      let formData = new FormData();
      formData.append("username", "cristian@santabros.com.ar");
      formData.append("password", "carlos");
      axios({
        // Endpoint to send files
        url: ENDPOINT_PDETECTOR + "/get-token",
        method: "POST",
        // Attaching the form data
        data: formData,
      }).then((response) => {
        cambiardetector(usuario, 1, response.data.access_token);
      });
    }
  };
  const cambiarobjv1 = (usuario, status, tok) => {
    let formData = new FormData();
    formData.append("username", usuario);
    formData.append("status", status);
    axios({
      // Endpoint to send files
      url: ENDPOINT_PYTHON + "/change-status-usertoken-yolo",
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + tok,
      },
      // Attaching the form data
      data: formData,
    }).then((response) => {
      window.location.reload();
    });
  };
  const cambiaroEye = (usuario, status, tok) => {
    let formData = new FormData();
    formData.append("username", usuario);
    formData.append("status", status);
    axios({
      // Endpoint to send files
      url: ENDPOINT_PYTHON + "/change-status-usertoken",
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + tok,
      },
      // Attaching the form data
      data: formData,
    }).then((response) => {
      window.location.reload();
    });
  };

  const cambiaroSRC = (usuario, status, tok) => {
    let formData = new FormData();
    formData.append("username", usuario);
    formData.append("status", status);
    axios({
      // Endpoint to send files
      url: ENDPOINT_SRC + "/change-status-usertoken",
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + tok,
      },
      // Attaching the form data
      data: formData,
    }).then((response) => {
      window.location.reload();
    });
  };
  const cambiarobjv2 = (usuario, status, tok) => {
    let formData = new FormData();
    formData.append("username", usuario);
    formData.append("status", status);
    axios({
      // Endpoint to send files
      url: ENDPOINT_YOLO + "/change-status-usertoken",
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + tok,
      },
      // Attaching the form data
      data: formData,
    }).then((response) => {
      window.location.reload();
    });
  };
  const cambiardetector = (usuario, status, tok) => {
    let formData = new FormData();
    formData.append("username", usuario);
    formData.append("status", status);
    axios({
      // Endpoint to send files
      url: ENDPOINT_PDETECTOR + "/change-status-usertoken",
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + tok,
      },
      // Attaching the form data
      data: formData,
    }).then((response) => {
      window.location.reload();
    });
  };

  const desactivarServicioServer = (usuario, server) => {
    if (server == "eyetracking") {
      let formData = new FormData();
      formData.append("username", "cristian@santabros.com.ar");
      formData.append("password", "carlos");
      axios({
        // Endpoint to send files
        url: ENDPOINT_PYTHON + "/get-token",
        method: "POST",
        // Attaching the form data
        data: formData,
      }).then((response) => {
        cambiaroEye(usuario, 0, response.data.access_token);
      });
    } else if (server == "rice_field") {
      let formData = new FormData();
      formData.append("username", "cristian@santabros.com.ar");
      formData.append("password", "carlos");
      axios({
        // Endpoint to send files
        url: ENDPOINT_SRC + "/get-token",
        method: "POST",
        // Attaching the form data
        data: formData,
      }).then((response) => {
        cambiaroSRC(usuario, 0, response.data.access_token);
      });
    } else if (server == "object_v2") {
      let formData = new FormData();
      formData.append("username", "cristian@santabros.com.ar");
      formData.append("password", "carlos");
      axios({
        // Endpoint to send files
        url: ENDPOINT_YOLO + "/get-token",
        method: "POST",
        // Attaching the form data
        data: formData,
      }).then((response) => {
        cambiarobjv2(usuario, 0, response.data.access_token);
      });
    } else if (server == "object_v1") {
      let formData = new FormData();
      formData.append("username", "cristian@santabros.com.ar");
      formData.append("password", "carlos");
      axios({
        // Endpoint to send files
        url: ENDPOINT_PYTHON + "/get-token-yolo",
        method: "POST",
        // Attaching the form data
        data: formData,
      }).then((response) => {
        cambiarobjv1(usuario, 0, response.data.access_token);
        setTokenApiobjecV1(response.data.access_token);
      });
    } else if (server == "product_detector") {
      let formData = new FormData();
      formData.append("username", "cristian@santabros.com.ar");
      formData.append("password", "carlos");
      axios({
        // Endpoint to send files
        url: ENDPOINT_PDETECTOR + "/get-token",
        method: "POST",
        // Attaching the form data
        data: formData,
      }).then((response) => {
        cambiardetector(usuario, 0, response.data.access_token);
      });
    }
  };
  const getUsersAll = async () => {
    const response = await axiosJWT.get(ENDPOINT_NODE + "/users", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // getClients(response.data);
    // getClientPendient(response.data);
    getServicesPendient(response.data);
    getServicesActive(response.data);
  };
  const getClients = async (usuariosCompletos) => {
    let emails = [];
    let usuariosEmail = [];
    const response = await axios.get(
      ENDPOINT_NODE + "/client-active",
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (usuariosCompletos != undefined && response.data != undefined) {
      emails = usuariosCompletos;
      usuariosEmail = response.data;
      for (let j = 0; j < usuariosEmail.length; j++) {
        if (usuariosEmail[j].id_service == 1) {
          usuariosEmail[j].id_service = "eyectracking";
        } else if (usuariosEmail[j].id_service == 2) {
          usuariosEmail[j].id_service = "rice field";
        } else if (usuariosEmail[j].id_service == 3) {
          usuariosEmail[j].id_service = "object detection";
        }
        for (let i = 0; i < emails.length; i++) {
          if (emails[i].id == usuariosEmail[j].id_user) {
            usuariosEmail[j].email = emails[i].email;
            usuariosEmail[j].name = emails[i].name;
          }
        }
      }
    }

    var ayuda2 = [];
    for (let i = 0; i < usuariosEmail.length; i++) {
      ayuda2.push(i);
    }
    setHtml(
      <PaginationYolo
        informacion={usuariosEmail.reverse()}
        ayuda={ayuda2}
        baja={darBaja}
      ></PaginationYolo>
    );
  };

  const getClientPendient = async (usuariosCompletos) => {
    let emails = [];
    let usuariosEmail = [];

    const response = await axios.get(
      ENDPOINT_NODE + "/client-pendient",
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (usuariosCompletos != undefined && response.data != undefined) {
      emails = usuariosCompletos;
      usuariosEmail = response.data;
      for (let j = 0; j < usuariosEmail.length; j++) {
        if (usuariosEmail[j].id_service == 1) {
          usuariosEmail[j].id_service = "eyectracking";
        } else if (usuariosEmail[j].id_service == 2) {
          usuariosEmail[j].id_service = "rice field";
        } else if (usuariosEmail[j].id_service == 3) {
          usuariosEmail[j].id_service = "object detection";
        }
        for (let i = 0; i < emails.length; i++) {
          if (emails[i].id == usuariosEmail[j].id_user) {
            usuariosEmail[j].email = emails[i].email;
            usuariosEmail[j].name = emails[i].name;
          }
        }
      }
    }
    var ayuda2 = [];
    for (let i = 0; i < usuariosEmail.length; i++) {
      ayuda2.push(i);
    }

    setHtml1(
      <PaginationEye
        informacion={usuariosEmail}
        ayuda={ayuda2}
        baja={darBaja}
        send={sendMailConfirm}
      ></PaginationEye>
    );
  };
  return (
    <>
      <MenuLateral></MenuLateral>
      <div className="mcw">
        <div className="cv">
          <div>
            <div className="inbox">
              <div className="inbox-sb"></div>
              <div className="inbox-bx container-fluid">
                <div className="row">
                  <div className="container">
                    <br></br>
                    <Container fluid>
                      {/* Table */}
                      <Row>
                        <div className="col">
                          <Card className="shadow">
                            <CardHeader className="border-0">
                              <h3 className="mb-0">Active Client List </h3>
                            </CardHeader>
                            {html}
                          </Card>
                        </div>
                      </Row>
                      {/* Dark table */}
                      <Row className="mt-5">
                        <div className="col">
                          <Card className="bg-default shadow">
                            <CardHeader className="bg-transparent border-0">
                              <h3 className="text-white mb-0">
                                Pending To Activate{" "}
                              </h3>
                            </CardHeader>
                            {html1}
                          </Card>
                        </div>
                      </Row>
                    </Container>
                    <br></br>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tables;
