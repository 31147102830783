import { useState, useRef, useEffect } from "react";
import axios from "axios";
import {  ENDPOINT_VIDEO } from "../../backend";
import { Toaster, toast } from "react-hot-toast";
import Alert from "../../components/error/ErrorAlert";
import Select from "react-select";
import DEEP_GAZE_Image from './../../assets/nodisponible.png';
import { ProgressBar } from "react-bootstrap";
// import styled from "styled-components";

function DragVideo(props) {
  const [myvalue, setmyValue] = useState("");
  const [prueba, setPrueba] = useState([]);
  const [base64, setBase64] = useState([]);
  const [activate, setActivate] = useState(false);
  const [progress, setProgress] = useState(0);
  const [token, setToken] = useState("");
  const [finish, setFinish] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [videos, setVideos] = useState([]);
  const [thumbnails, setThumbnails] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [fileInfo, setFileInfo] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const originalOptions = [
    {
      value: "face-m",
      label: "Face Match",
    },
    {
      value: "objv2",
      label: "Object detection V2",
    },
    {
      value: "air-p",
      label: "Airport V1",
    },
    {
      value: "arm-v",
      label: "Armored Vehicles",
    },
  ];
  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };
  const videoRef = useRef(null);

  const handleSelectVideo = (e) => {
    añadirImagen(e.target.files);
    const file = e.target.files[0];
    if (file) {
      if (file.type.startsWith('video/mp4')) {
        console.log(file.name)

        const reader = new FileReader();
        const newFileInfo = {
          type: file.type,
          size: file.size,
          name:file.name
        };
        setFileInfo((fileInfo) => [...fileInfo, newFileInfo]);
        setThumbnails((prevThumbnails) => [...prevThumbnails, DEEP_GAZE_Image]);
      reader.readAsDataURL(file);

      // Actualiza el estado con el nuevo video
      setVideos((prevVideos) => [...prevVideos, URL.createObjectURL(file)]);
      } else {
        const reader = new FileReader();
        const newFileInfo = {
          type: file.type,
          size: file.size,
          name:file.name
        };

        // Actualizar el estado para agregar el nuevo objeto a la lista
        setFileInfo((fileInfo) => [...fileInfo, newFileInfo]);
        const imagePath = DEEP_GAZE_Image;
          setThumbnails((prevThumbnails) => [...prevThumbnails, DEEP_GAZE_Image]);

      reader.readAsDataURL(file);

      // Actualiza el estado con el nuevo video
      setVideos((prevVideos) => [...prevVideos, URL.createObjectURL(file)]);
      
      }
      
    }
  };

  const UploadFiles = async () => {
    try {
      setActivate(true);
      setProgress(1); // Iniciar progreso en 1
      const time = 100
      if (totalSize<100) {
        time = 300
      } else if (totalSize>=100 && totalSize<=500){
        time = 1000
      } else if (totalSize>500 && totalSize<=1000){
        time = 3000
      } else if (totalSize>1000 && totalSize<=1500){
        time = 5000
      } else if (totalSize>1500){
        time = 8000
      }
      
    // Crear un temporizador para incrementar gradualmente el progreso
    const intervalId = setInterval(() => {
      // Incrementar progreso en 1
      setProgress((prevProgress) => {
        // Si el progreso llega a 99 y aún no se ha completado la carga, mantenerlo en 99
        if (prevProgress < 99) {
          return prevProgress + 1;
        } else {
          return prevProgress;
        }
      });
    }, time); // Incrementar cada segundo (puedes ajustar el intervalo según tu preferencia)

      const EndPoint = ENDPOINT_VIDEO + "/api-send-videos";
      let formData = new FormData();
      for (let i = 0; i < prueba.length; i++) {
        formData.append("image", prueba[i]);
      }
      formData.append("username", props.email);
      formData.append("service", selectedOption.value);
      await axios({
        url: EndPoint,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
        // Attaching the form data
        data: formData,
        
      }).then((response) => {
        clearInterval(intervalId); // Limpiar temporizador cuando se recibe la respuesta

        // Actualizar el progreso a 100 después de recibir la respuesta
 
        toast.success("Your image has been processed");

        setProgress(100);
        setFinish(true);
        setPrueba([]);
        setBase64([]);
        setVideos([]);
        setTotalSize(0)
        setFileInfo([])
        // sumarCantidad(this.id_usuario,this.servicio);

        // this.guardarNombreImage( this.id_usuario ,this.servicio,currentFile.name,response.data.Jobid)
      });
    } catch (error) {
      setShowAlert(true);
      setActivate(false);
      if (error.response) {
        console.log(error.response.data); // Información detallada sobre el error de respuesta
        console.log(error.response.status); // Código de estado HTTP de la respuesta
        setMessageAlert("Error displaying");
      } else if (error.request) {
        console.log(error.request); // Información sobre la solicitud sin respuesta
        setMessageAlert("Request error");
      } else {
        console.log(error.message); // Otros errores
        setMessageAlert("Unknown error");
      }
    }
  };

  const añadirImagen = (value) => {
    Array.from(value).forEach((archivos) => {
      setPrueba((prueba) => prueba.concat(archivos));
    });
  };
  const removerImage = (index) => {
    setVideos((prevVideos) => {
      const updatedVideos = [...prevVideos];
      updatedVideos.splice(index, 1);
      return updatedVideos;
    });

    setPrueba((prevPrueba) => {
      const updatedPrueba = [...prevPrueba];
      updatedPrueba.splice(index, 1);
      return updatedPrueba;
    });
    setFileInfo((prevFileInfo) => {
      const updatedfileInfo = [...prevFileInfo];
      updatedfileInfo.splice(index, 1);
      return updatedfileInfo;
    });
    const updatedThumbnails = [...thumbnails];
    updatedThumbnails.splice(index, 1);
    setThumbnails(updatedThumbnails);
  };
  useEffect(() => {
    // Calcular la suma total de la duración y el tamaño de los videos
    
    let sizeSum = 0;

    // Iterar sobre cada objeto en fileInfo y sumar la duración y el tamaño
    fileInfo.forEach((file) => {
      sizeSum += file.size;
    });

    // Actualizar el estado de totalDuration y totalSize
    setTotalSize(sizeSum);
  }, [fileInfo]); // Ejecutar el efecto cuando fileInfo cambie
  return (
    <div className="mb-5">
      {/* <StyleDragArea> */}
      {showAlert && <Alert message={messageAlert} />}
      <br />
      <div className="row mb-5">
        <div className="col-4 pl-5">
          <div className="text-center">
            <Select options={originalOptions} onChange={handleOptionChange} />
          </div>
        </div>
        <div className="col-8 "></div>
      </div>
      <div className="image-upload-wrap">
        <input
          className="file-upload-input"
          type="file"
          accept="video/*"
          // multiple
          value={myvalue}
          onChange={handleSelectVideo}
        />
        <div className="text-information my-3 ">
          <h6 className="h6 text-white">
            Drag and drop a file or select add video
          </h6>
          <h4 className="h4 text-white">
            Number of videos uploaded: {prueba.length}{" "}
          </h4>
        </div>
      </div>
      <div className="mt-2 text-center">
        {10 - prueba.length < 0 && (
          <h4 className="h4 text-danger">
            You have {prueba.length - 10} more videos{" "}
          </h4>
        )}
        {10 - prueba.length >= 0 && (
          <h4 className="h4 text-white">
            Total remaining videos: {10 - prueba.length}{" "}
          </h4>
        )}
      </div>
      <div className="row mt-5 ">
        {videos.map((video, index) => (
          <div key={index} className="text-center col-2">
            <img
              src={thumbnails[index]}
              alt=""
              height="100px"
              width="150px"
              className="imagenredonda"
            />
            <p className="text-center text-white">{fileInfo[index].name.slice(0,17)}</p>
            <div>
              <button
                onClick={() => removerImage(index)}
                className="my-3 btn btn-danger"
              >
                REMOVE 
              </button>
            </div>
          </div>
        ))}
      </div>
      {/* <div className="text-center">
        <div>
          <p>Preview last uploaded video format mp4</p>
        </div>
        <video
          ref={videoRef}
          controls
          style={{ width: "400px", zIndex: -999 }}
        />
        
      </div> */}
      {fileInfo && (
        <div className="file-info">
          <p className="text-white ml-3">
            Total video size: {(totalSize / 1000000).toFixed(2)} MegaBytes.{" "}
            {(totalSize / 1000000).toFixed(2) > 2500 && (
              <span className="text-danger">Max 2500 Megabytes</span>
            )}
          </p>
        </div>
      )}
      {/* </StyleDragArea> */}
      {activate && (
        <div className="progress2 mt-5">
          <div className="progress-bar2" style={{ width: progress + "%" }}>
            <span className="progress-bar-text2">{progress}%</span>
          </div>
        </div>
      )}
      {finish && (
        <div className="mt-5">
          <h4 className="h4 text-white">
            Your videos has been sent successfully
          </h4>
        </div>
      )}
      <div className="row">
        <div className="col-10 text-left mt-5">
          <a href="/services">
            <button className="btn btn-primary btn-lg ml-2 mb-4">Return</button>
          </a>
        </div>

        <div className="col-2 ">
          <div className=" my-5 text-center">
            <div className="text-center">
              <button
                className="btn btn-success"
                disabled={
                  prueba == "" ||
                  prueba.length > 10 ||
                  !selectedOption ||
                  (totalSize / 1000000).toFixed(2) > 2500
                }
                onClick={UploadFiles}
              >
                Start Audit
              </button>
            </div>
            {selectedOption && (
              <small className="text-white text-center">
                Send to {selectedOption.label}
              </small>
            )}
          </div>
        </div>
      </div>
      <Toaster position="bottom-center" reverseOrder={false} />
    </div>
  );
}

export default DragVideo;
