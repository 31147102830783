import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import {
  ENDPOINT_NODE,
  ENDPOINT_PYTHON,
  ENDPOINT_YOLO,
  ENDPOINT_CLASSIFIER
} from "../../backend.js";
import emailjs from "emailjs-com";
const NewuserClassifier = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirpassword, setConfirPassword] = useState("");
  const [ip_address, setIpAddress] = useState("https://");
  const [isCheckedUrl, setIsCheckedUrl] = useState(false);
  const [isCheckedHttps, setIsCheckedHttps] = useState(false);
  const [msg, setMsg] = useState("");
  const history = useHistory();
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [tokenApi, setTokenApi] = useState("");
  const [expire, setExpire] = useState("");
  const [id_user, setId_user] = useState();
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    // getToken(107,1);
    refreshToken();
    tenerToken();
    
  }, []);
 
  const refreshToken = async () => {
    try {
      const response = await axios.get(ENDPOINT_NODE + "/token");
      setToken(response.data.accessToken);
      const decoded = jwt_decode(response.data.accessToken);
      setId_user(decoded.userId);
      setEmail(decoded.email);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (error) {
      if (error.response) {
        history.push("/");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(ENDPOINT_NODE + "/token");
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwt_decode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const sentEmail = (email ) => {
    axios({
      // Endpoint to send files
      url: ENDPOINT_NODE +"/sent-service",
      method: "POST",
      // Attaching the form data
      data: {username: email,
      },
    })
    .then((response) => {
  
    });
   }
  const getToken= async(id_user,service,ip_address,password)=>{
    try {
      const response = await axios.post(ENDPOINT_NODE+'/get-password-token', {
          id_user: id_user,
          id_service: service
      });
      try {
        let formData = new FormData();
        formData.append("username", email);
        formData.append("password", password);
        formData.append("ip_address", ip_address);
        formData.append("id_user", id_user);
        formData.append("status", 2);
        axios({
          url: ENDPOINT_CLASSIFIER + "/create-user-token",
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + tokenApi,
          },
          // Attaching the form data
          data: formData,
        }).then((response) => {
          // activarServicio();
          sentEmail(email)
          history.push("/services");
        });
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
  } catch (error) {
      
      if (error.response) {
        // setMsg(error.response.data.msg);
      }
  }
}
  const tenerToken = () => {
    let formData = new FormData();
    formData.append("username", "cristian@santabros.com.ar");
    formData.append("password", "carlos");
    axios({
      // Endpoint to send files
      url: ENDPOINT_CLASSIFIER + "/get-token",
      method: "POST",
      // Attaching the form data
      data: formData,
    }).then((response) => {
      setTokenApi(response.data.access_token);
    });
  };
  const activarServicio = async (e) => {
    try {
      await axios.post(ENDPOINT_NODE + "/change-status", {
        user_id: id_user,
        service: "product_classifier",
        status: 2,
      });
      
      history.push("/services");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  
  const Auth = async (e) => {
    
    e.preventDefault();
    if (password == confirpassword && password != "") {
      setMsg("");
      try {
        activarServicio();
        register_password_node(id_user, email, password, 6, 0, ip_address);
        // sendEmail(e);
        // let formData = new FormData();
        // formData.append("username", email);
        // formData.append("password", password);
        // formData.append("ip_address", ip_address);
        // formData.append("id_user", id_user);
        // axios({
        //   url: ENDPOINT_PYTHON + "/create-user-token",
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "multipart/form-data",
        //     Authorization: "Bearer " + tokenApi,
        //   },
        //   // Attaching the form data
        //   data: formData,
        // }).then((response) => {
          
        //   // activarServicio();
        //   history.push("/services");
        // });
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    } else {
      setMsg("The password must match");
    }
  };
  const handleCheckboxChangeUrl = (e) => {
    setIsCheckedUrl(e.target.checked);
    if (e.target.checked) {
      setIpAddress("");
    } else {
      // Mantener el estado de isCheckedHttps al deseleccionar "Do not use URL"
      if (!isCheckedHttps) {
        setIpAddress("https://");
      }
    }
  };
  const handleCheckboxChangeHttps = (e) => {
    setIsCheckedHttps(e.target.checked);
    if (e.target.checked) {
      // Si se selecciona "Do not use HTTPS", eliminar "https://"
      setIpAddress(ip_address.replace("https://", ""));
    } else {
      // Si se deselecciona "Do not use HTTPS", agregar "https://"
      setIpAddress("https://" + ip_address);
    }
  };
  const register_password_node = async (id_user, correo,password,id_service, status, ip_address) =>{
    
    await axios.post(ENDPOINT_NODE+'/register-password-token', {
      id_user: id_user,
      username: correo,
      password: password,
      id_service: id_service,
      status: status,
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
    }});
  getToken(id_user,id_service,ip_address,password);    
}
  return (
    <div>
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column">
              <form onSubmit={Auth} className="box">
                <h1 className="has-text-centered font-weight-bold h2">
                  Credentials for <span name="servicio">Product Classifier </span>{" "}
                </h1>
                <p className="has-text-centered text-danger">{msg}</p>
                <div className="field mt-5">
                  <label className="label">Email</label>
                  <div className="controls" data-title="The email is not modified">
                    <input
                      type="text"
                      className="input"
                      placeholder="email"
                      value={email}
                      disabled
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div>
                  <div className="field mt-5">
                    <label className="label">URL Destinations</label>
                    <small>This URL is for use in an api<i className="bi bi-question-circle" data-title="This is a web address with your own domain, which you can use to receive the images"></i> </small>
                    <div className="controls" data-title="Through this URL you will receive the processed images">
                      <input
                        type="text"
                        className="input"
                        placeholder="URL Address"
                        value={isCheckedUrl ? "" : ip_address}
            onChange={(e) => setIpAddress(e.target.value)}
            disabled={isCheckedUrl}
                      />
                    </div>
                  </div>
                  <div>
                    <input type="checkbox" onChange={handleCheckboxChangeUrl} />
                    <label style={{ marginLeft: "10px" }}>Do not use URL</label>
                  </div>
                  <div>
                    <input type="checkbox" onChange={handleCheckboxChangeHttps} />
                    <label style={{ marginLeft: "10px" }}>Do not use HTTPS</label>
                  </div>
                </div>
                <div className="field mt-5">
                  <label className="label">Token Password</label>
                  <div className="controls">
                    <input
                      type="password"
                      className="input"
                      placeholder="******"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="field mt-5">
                  <label className="label">Confirm Token Password</label>
                  <div className="controls">
                    <input
                      type="password"
                      className="input"
                      placeholder="******"
                      value={confirpassword}
                      onChange={(e) => setConfirPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="field mt-5">
                      <label className="label">Confirm This password must be distinct from the login password. It will be used in the context of an API.</label>
                    </div>
                <div className="field mt-5">
                  <button
                    className="button is-success is-fullwidth btnInfo "
                    disabled={!(password == confirpassword && password != "")}
                    data-title="The URL and password can be changed in your profile"

                  >
                    Create
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewuserClassifier;
