import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Toast,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import axios from "axios";
import {
  ENDPOINT_NODE,
  ENDPOINT_PYTHON,
  ENDPOINT_SRC,
  ENDPOINT_YOLO,
  ENDPOINT_PDETECTOR,
} from "../../backend";
import { Country } from "../FormulariosServicios.js/phoneNumbers";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom";
import useModal from "../hooks/modals";
import CredencialesServices from "../FormulariosServicios.js/credenciales_services";
import Modal from "../Modals/modal_userNew";
import { Toaster, toast } from "react-hot-toast";

const Profile = () => {
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");
  const [deshabilitado, setDeshabilitado] = useState(true);
  const history = useHistory();
  const [user, setUser] = useState("");
  const [infoUser, setInfoUser] = useState("");
  const [msg, setMsg] = useState("");
  const [email, setEmail] = useState("");
  const [serviciosActivos, SetServiciosActivos] = useState([0, 0, 0, 0]);
  const [serviciosActivos2, SetServiciosActivos2] = useState({});
  const [ip_address, setip_address] = useState("");
  const [ip_addressEye, setip_addressEye] = useState("");
  const [ip_addressSrc, setip_addressSrc] = useState("");
  const [ip_addressObjV1, setip_addressObjV1] = useState("");
  const [ip_addressProduct, setip_addressProduct] = useState("");
  const [password_actual, setPassword_actual] = useState("");
  const [password_actual_eye, setPassword_actual_eye] = useState("");
  const [password_actual_src, setPassword_actual_src] = useState("");
  const [password_actual_objV1, setPassword_actual_objV1] = useState("");
  const [password_actual_product, setPassword_actual_product] = useState("");
  const [tokenYolo, setTokenYolo] = useState("");
  const [tokenEye, setTokenEye] = useState("");
  const [tokenSrc, setTokenSrc] = useState("");
  const [tokenobjv1, setTokenobjv1] = useState("");
  const [tokenDetector, setTokenDetector] = useState("");
  const [activar, setActivar] = useState(true);
  const [nameedit, setNameedit] = useState("");
  const [numberedit, setNumberedit] = useState("");
  const [companyedit, setCompanyedit] = useState("");
  const [countryedit, setCountryEdit] = useState("");
  const [companyNumberedit, setCompanyNumberedit] = useState("");
  const [phoneedit, setPhoneedit] = useState("");
  const [isOpen, openModal, closeModal] = useModal(false);
  const [id_user, setId_user] = useState();
  const [html, setHtml] = useState("");
  const [activate, SetActivate] = useState(false);

  const refreshToken = async () => {
    try {
      const response = await axios.get(ENDPOINT_NODE + "/token");
      setToken(response.data.accessToken);
      const decoded = jwt_decode(response.data.accessToken);
      tenerTokenYolo(decoded.email);
      tenerTokenSrc(decoded.email);
      tenerTokenEye(decoded.email);
      tenerTokenDetector(decoded.email);
      setId_user(decoded.userId);
      getClientes(decoded.userId, response.data.accessToken);
      getServicesActivobyid(decoded.userId, response.data.accessToken);
      setEmail(decoded.email);
      setExpire(decoded.exp);
      getUserInfo(decoded.userId, response.data.accessToken);
    } catch (error) {
      if (error.response) {
        history.push("/");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(ENDPOINT_NODE + "/token");
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwt_decode(response.data.accessToken);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  useEffect(() => {
    getToken(107, 1);
    refreshToken();
  }, []);

  const getServicesActivobyid = async (user, tToken) => {
    try {
      await axios
        .post(
          ENDPOINT_NODE + "/active_servicesbyid",
          {
            user_id: user,
          },
          {
            headers: {
              Authorization: `Bearer ${tToken}`,
            },
          }
        )
        .then((response) => {
          SetServiciosActivos2(response.data[0]);
        });
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  const getUserInfo = async (usuario, tToken) => {
    try {
      await axios
        .post(
          ENDPOINT_NODE + "/get-info",
          {
            user_id: usuario,
          },
          {
            headers: {
              Authorization: `Bearer ${tToken}`,
            },
          }
        )
        .then((response) => {
          setInfoUser(response.data[0]);
          setNameedit(response.data[0].contact_name);
          setCompanyedit(response.data[0].company_name);
          setCompanyNumberedit(response.data[0].company_registration_number);
          setCountryEdit(response.data[0].country);
          setNumberedit(response.data[0].phone_number);
        });
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  const getClientes = async (usuario, tToken) => {
    try {
      await axios
        .post(
          ENDPOINT_NODE + "/clientById",
          {
            id_user: usuario,
          },
          {
            headers: {
              Authorization: `Bearer ${tToken}`,
            },
          }
        )
        .then((response) => {
          ServiciosActivos(response.data);
        });
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  const Update = async (e) => {
    e.preventDefault();
    try {
      refreshToken();
      await axios
        .post(
          ENDPOINT_NODE + "/update-info",
          {
            contact_name: nameedit,
            phone_number: numberedit,
            company_name: companyedit,
            company_registration_number: companyNumberedit,
            country: countryedit,
            user_id: id_user,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          update_name(nameedit, id_user, token);
          getUserInfo(id_user, token);
          toast.success("Your information has been updated");
          setActivar(!activar);
        });
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  const update_name = async (name, id, tToken) => {
    try {
      await axios.post(
        ENDPOINT_NODE + "/update-user",
        {
          name: name,
          id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${tToken}`,
          },
        }
      );
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  const getToken = async (id_user, service) => {
    try {
      const response = await axios.post(ENDPOINT_NODE + "/get-password-token", {
        id_user: id_user,
        id_service: service,
      });
    } catch (error) {
      if (error.response) {
        // setMsg(error.response.data.msg);
      }
    }
  };
  const tenerTokenYolo = (correo) => {
    let formData = new FormData();
    formData.append("username", "cristian@santabros.com.ar");
    formData.append("password", "carlos");
    axios({
      // Endpoint to send files
      url: ENDPOINT_YOLO + "/get-token",
      method: "POST",
      // Attaching the form data
      data: formData,
    }).then((response) => {
      setTokenYolo(response.data.access_token);
      Obtener_ip_address(correo, response.data.access_token);
    });
  };
  const tenerTokenEye = (correo) => {
    let formData = new FormData();
    formData.append("username", "cristian@santabros.com.ar");
    formData.append("password", "carlos");
    axios({
      // Endpoint to send files
      url: ENDPOINT_PYTHON + "/get-token",
      method: "POST",
      // Attaching the form data
      data: formData,
    }).then((response) => {
      setTokenEye(response.data.access_token);
      Obtener_ip_address_eye(correo, response.data.access_token);
    });
  };
  const tenerTokenSrc = (correo) => {
    let formData = new FormData();
    formData.append("username", "cristian@santabros.com.ar");
    formData.append("password", "carlos");
    axios({
      // Endpoint to send files
      url: ENDPOINT_SRC + "/get-token",
      method: "POST",
      // Attaching the form data
      data: formData,
    }).then((response) => {
      setTokenSrc(response.data.access_token);
      Obtener_ip_address_src(correo, response.data.access_token);
    });
  };
  const tenerTokenDetector = (correo) => {
    let formData = new FormData();
    formData.append("username", "cristian@santabros.com.ar");
    formData.append("password", "carlos");
    axios({
      // Endpoint to send files
      url: ENDPOINT_PDETECTOR + "/get-token",
      method: "POST",
      // Attaching the form data
      data: formData,
    }).then((response) => {
      setTokenDetector(response.data.access_token);
      Obtener_ip_address_detector(correo, response.data.access_token);
    });
  };

  const Obtener_ip_address = (username, tok) => {
    try {
      axios({
        url: ENDPOINT_YOLO + "/get-ipaddress-usertoken/" + username,
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + tok,
        },
      }).then((response) => {
        setip_address(response.data.ip_address);
        setPassword_actual(response.data.password);
      });
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  const Obtener_ip_address_eye = (username, tok) => {
    try {
      axios({
        url: ENDPOINT_PYTHON + "/get-ipaddress-usertoken/" + username,
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + tok,
        },
      }).then((response) => {
        setip_addressEye(response.data.ip_address);
        setPassword_actual_eye(response.data.password);
        let formData = new FormData();
        formData.append("username", "cristian@santabros.com.ar");
        formData.append("password", "carlos");
        axios({
          // Endpoint to send files
          url: ENDPOINT_PYTHON + "/get-token-yolo",
          method: "POST",
          // Attaching the form data
          data: formData,
        }).then((response) => {
          setTokenobjv1(response.data.access_token);
          Obtener_ip_address_obj_v1(username, response.data.access_token);
        });
      });
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  const Obtener_ip_address_src = (username, tok) => {
    try {
      axios({
        url: ENDPOINT_SRC + "/get-ipaddress-usertoken/" + username,
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + tok,
        },
      }).then((response) => {
        setip_addressSrc(response.data.ip_address);
        setPassword_actual_src(response.data.password);
      });
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  const Obtener_ip_address_obj_v1 = (username, tok) => {
    try {
      axios({
        url: ENDPOINT_PYTHON + "/get-ipaddress-usertoken-yolo/" + username,
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + tok,
        },
      }).then((response) => {
        setip_addressObjV1(response.data.ip_address);
        setPassword_actual_objV1(response.data.password);
      });
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  const Obtener_ip_address_detector = (username, tok) => {
    try {
      axios({
        url: ENDPOINT_PDETECTOR + "/get-ipaddress-usertoken/" + username,
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + tok,
        },
      }).then((response) => {
        setip_addressProduct(response.data.ip_address);
        setPassword_actual_product(response.data.password);
      });
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  const ServiciosActivos = (servicios) => {
    var eyetracking = 0;
    var src = 0;
    var objectDetection = 0;

    const momentoServicio = servicios.map(function (servicio) {
      if (servicio.id_service == 1) {
        eyetracking = servicio.status;
      } else if (servicio.id_service == 2) {
        src = servicio.status;
      } else if (servicio.id_service == 3) {
        objectDetection = servicio.status;
      }
      return servicio;
    });
    SetServiciosActivos([eyetracking, src, objectDetection, 0]);
  };

  const activarUpdate = () => {
    setActivar(!activar);
    if (!activar) {
      toast.error("Your data was not updated");
      setNameedit(infoUser.contact_name);
      setCompanyedit(infoUser.company_name);
      setCompanyNumberedit(infoUser.company_registration_number);
      setNumberedit(infoUser.phone_number);
    }
  };

  const credenciales = (email, servicios, token_enviar) => {
    // openModal();
    SetActivate(true);
    setHtml(
      <CredencialesServices
        email={email}
        tokenApi={token_enviar}
        servicio={servicios}
      ></CredencialesServices>
    );
  };
  return (
    <>
      <Navbar />
      <Container className="mt--7 mt-5" fluid>
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <Card className="card-profile shadow">
              <Row className="justify-content-center">
                <Col className="order-lg-2" lg="3">
                  <div className="card-profile-image">
                    <a href="#" onClick={(e) => e.preventDefault()}>
                      {/* <img
                        alt="..."
                        className="rounded-circle"
                        src={require("../../assets/img/theme/team-4-800x800.jpg")}
                      /> */}
                    </a>
                  </div>
                </Col>
              </Row>
              <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                <div className="d-flex justify-content-between">
                  {/* <Button
                    className="mr-4"
                    color="info"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="sm"
                  >
                    Connect
                  </Button>
                  <Button
                    className="float-right"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="sm"
                  >
                    Message
                  </Button> */}
                </div>
              </CardHeader>
              <CardBody className="pt-0 pt-md-4">
                {/* <Row>
                  <div className="col">
                    <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                      <div>
                        <span className="heading">3</span>
                        <span className="description">Services</span>
                      </div>
                      <div>
                        <span className="heading">100</span>
                        <span className="description">Amount Images</span>
                      </div>
                      <div>
                        <span className="heading">1000</span>
                        <span className="description">Pack Images</span>
                      </div>
                    </div>
                  </div>
                </Row> */}
                <div className="text-center">
                  <h3 className="h3 mt-5">{infoUser.contact_name}</h3>
                  <div className="h5 font-weight-300">
                    <i className="ni location_pin mr-2" />
                    {infoUser.country}
                  </div>
                  {/* <div className="h5 mt-4">
                    <i className="ni business_briefcase-24 mr-2" />
                    Director of proyect
                  </div> */}

                  <hr className="my-4" />
                  <div>
                    <h2
                      className="h2"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                    >
                      List of AI services APIs{" "}
                    </h2>
                  </div>
                  <small>
                    Here you can find and change information related to your API
                    account for each artificial intelligence
                  </small>
                  <Row>
                    <Col lg="12">
                    {activate && (
                          <button
                            className="btn btn-warning mt-3 mb-3"
                            onClick={() => SetActivate(false)}
                          >
                            Close X
                          </button>
                        )}
                    </Col>
                  </Row>
                  
                  {!activate && ( <> 
                  {serviciosActivos2.eyetracking == 1 ? (
                    <Row>
                      <Col lg="12">
                        

                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-city"
                          >
                            Eyectraking
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={ip_addressEye}
                            id="input-city"
                            placeholder="URL"
                            type="text"
                            disabled={deshabilitado}
                          />

                          <small
                            className="text-primary"
                            onClick={(e) => credenciales(email, 1, tokenEye, e)}
                            data-title="Here you can modify your information of Eyecktracking"
                          >
                            Edit Info
                          </small>
                          <i
                            className="bi bi-question-circle ml-3"
                            data-title="Here you can modify your information of Eyecktracking"
                          ></i>
                        </FormGroup>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                  {serviciosActivos2.object_v2 == 1 ? (
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-city"
                          >
                            Object Detection V2
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={ip_address}
                            id="input-city"
                            placeholder="URL"
                            type="text"
                            disabled={deshabilitado}
                          />
                          <small
                            className="text-primary pointer"
                            onClick={(e) =>
                              credenciales(email, 3, tokenYolo, e)
                            }
                            data-title="Here you can modify your information of Object Detection V2"
                          >
                            Edit Info
                          </small>
                          <i
                            className="bi bi-question-circle ml-3"
                            data-title="Here you can modify your information of Object Detection V2"
                          ></i>
                        </FormGroup>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                  {serviciosActivos2.rice_field == 1 ? (
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-city"
                          >
                            Rice Field
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={ip_addressSrc}
                            id="input-city"
                            placeholder="URL"
                            type="text"
                            disabled={deshabilitado}
                          />
                          <small
                            className="text-primary pointer"
                            onClick={(e) => credenciales(email, 2, tokenSrc, e)}
                            data-title="Here you can modify your information of Rice Field"
                          >
                            Edit Info
                          </small>
                          <i
                            className="bi bi-question-circle ml-3"
                            data-title="Here you can modify your information of Rice Field"
                          ></i>
                        </FormGroup>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                  {serviciosActivos2.object_v1 == 1 ? (
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-city"
                          >
                            Object Detection v1
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={ip_addressObjV1}
                            id="input-city"
                            placeholder="URL"
                            type="text"
                            disabled={deshabilitado}
                          />
                          <small
                            className="text-primary pointer"
                            onClick={(e) =>
                              credenciales(email, 4, tokenobjv1, e)
                            }
                            data-title="Here you can modify your information of Object Detection V1"
                          >
                            Edit Info
                          </small>
                          <i
                            className="bi bi-question-circle ml-3"
                            data-title="Here you can modify your information of Object Detection V1"
                          ></i>
                        </FormGroup>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}

                  {serviciosActivos2.product_detector == 1 ? (
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-city"
                          >
                            Product Detector
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={ip_addressProduct}
                            id="input-city"
                            placeholder="URL"
                            type="text"
                            disabled={deshabilitado}
                          />
                          <small
                            className="text-primary pointer"
                            onClick={(e) =>
                              credenciales(email, 5, tokenDetector, e)
                            }
                            data-title="Here you can modify your information of Product Detector"
                          >
                            Edit Info
                          </small>
                          <i
                            className="bi bi-question-circle ml-3"
                            data-title="Here you can modify your information of Product Detector"
                          ></i>
                        </FormGroup>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                  </>
                  )}
                  { activate && html && (
                          html
                        )

                        }
                  {serviciosActivos2.eyetracking == 0 &&
                    serviciosActivos2.object_v2 == 0 &&
                    serviciosActivos2.rice_field == 0 &&
                    serviciosActivos2.object_v1 == 0 &&
                    serviciosActivos2.product_detector == 0 && (
                      <h2 className="h2"> There are no active services</h2>
                    )}
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    Show more
                  </a> */}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="6">
                    <h3 className="mb-0">My account</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    {activar ? (
                      <Button
                        color="primary"
                        onClick={(e) => activarUpdate()}
                        size="sm"
                        data-title="Edit your personal information"
                      >
                        Edit
                      </Button>
                    ) : (
                      <Button
                        color="danger"
                        onClick={(e) => activarUpdate()}
                        size="sm"
                        data-title="Changes will not be saved"
                      >
                        Cancel
                      </Button>
                    )}
                  </Col>
                  <Col xs="1">
                    <i
                      className="bi bi-question-circle"
                      data-title="This information is used for control and contact with you."
                    ></i>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    User information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                            data-title="Personal Name "
                          >
                            Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={nameedit}
                            onChange={(e) => setNameedit(e.target.value)}
                            id="input-username"
                            disabled={activar}
                            placeholder="Username"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                            data-title="Email cannot be changed "
                          >
                            Email Address
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            disabled={deshabilitado}
                            placeholder={email}
                            type="email"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                            data-title="Name of the company you work with"
                          >
                            Company Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={companyedit}
                            onChange={(e) => setCompanyedit(e.target.value)}
                            disabled={activar}
                            id="input-Company-name"
                            placeholder="Company Name"
                            type="text"
                            data-title="Name of the company you work with"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                            data-title="Registration number of the company you work with"
                          >
                            Company Registration Number
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={companyNumberedit}
                            onChange={(e) =>
                              setCompanyNumberedit(e.target.value)
                            }
                            id="input-Registration-Number"
                            disabled={activar}
                            placeholder="Company Registration Number"
                            type="text"
                            data-title="Registration number of the company you work with"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    Contact information
                  </h6>
                  <div className="pl-lg-4">
                    {/* <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-address"
                          >
                            Address
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue="Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09"
                            id="input-address"
                            placeholder="Home Address"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row> */}
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          {activar ? (
                            <>
                              <label
                                className="form-control-label"
                                htmlFor="input-city"
                                data-title="Country where it is located"
                              >
                                Country
                              </label>
                              <Input
                                className="form-control-alternative"
                                disabled={activar}
                                value={infoUser.country}
                                id="input-Country"
                                placeholder="Country"
                                type="text"
                              />
                            </>
                          ) : (
                            <>
                              <label
                                className="form-control-label"
                                htmlFor="input-country"
                                data-title="Country where it is located"
                              >
                                country
                              </label>
                              <div className="form-control-alternative">
                                <select
                                  className="form-select"
                                  value={countryedit}
                                  onChange={(e) =>
                                    setCountryEdit(e.target.value)
                                  }
                                >
                                  {Country &&
                                    Country.map((pais, index) => (
                                      <option>{pais.country}</option>
                                    ))}
                                </select>
                              </div>
                            </>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                            data-title="Contact phone number"
                          >
                            Phone Number
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={numberedit}
                            disabled={activar}
                            id="input-Phone"
                            onChange={(e) => setNumberedit(e.target.value)}
                            placeholder="Phone Number"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup></FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4"></Col>
                      <Col lg="4"></Col>
                      <Col lg="4">
                        <FormGroup>
                          {!activar ? (
                            <Button
                              color="success"
                              onClick={(e) => Update(e)}
                              size="md"
                              data-title="Edit your personal information"
                            >
                              Update Info
                            </Button>
                          ) : (
                            <></>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Description */}
                  {/* <h6 className="heading-small text-muted mb-4">About me</h6>
                  <div className="pl-lg-4">
                    <FormGroup>
                      <label>About Me</label>
                      <Input
                        className="form-control-alternative"
                        placeholder="A few words about you ..."
                        rows="4"
                        defaultValue="A beautiful Dashboard for Bootstrap 4. It is Free and
                        Open Source."
                        type="textarea"
                      />
                    </FormGroup>
                  </div> */}
                </Form>
                <Toaster position="bottom-center" reverseOrder={false} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal isOpenform={isOpen} closeform={closeModal}>
          {html}
        </Modal>
      </Container>
    </>
  );
};

export default Profile;
