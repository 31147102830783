import React, {useState} from 'react'
import axios from 'axios';
import logo2 from './../assets/deepgazeweb1.png';
import "../assets/css/styles.css"
import { ENDPOINT_NODE } from '../backend';
import { HeaderWrapper } from '../assets/css/header';
import { useHistory } from 'react-router-dom';
import Navbar3 from './menu/navbar';
import MenuButton from './menu/menuButton';
const Navbar = () => {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };
    const history = useHistory();
    //uno es administrador
    //dos es usuario de compañia
    const [user,setUser] = useState(1)
    
    const Logout = async () => {
        try {
            await axios.delete(ENDPOINT_NODE+'/logout');
            history.push("/");
        } catch (error) {
            console.log(error);
        }
    }
    
    return (
        <HeaderWrapper>
            <Navbar3 open={open} />
            <MenuButton open={open} handleClick={handleClick} />
            </HeaderWrapper>
        

        // <nav className="navbar navbar-dark bg-dark"  role="navigation" aria-label="main navigation">
        //     <div className="container  "  >
        //         <div className="navbar-brand ">
        //             <a className="navbar-item" href="#" >
        //                 <img src={logo2} width="112" height="28" alt="logo" />
        //             </a>

        //             <a href="/" role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
        //                 <span aria-hidden="true"></span>
        //                 <span aria-hidden="true"></span>
        //                 <span aria-hidden="true"></span>
        //             </a>
        //         </div>
        //         <div id="navbarBasicExample" className="navbar-menu ">
        //             <div className="navbar-start ">
        //                 <a href="/services" className="navbar-item text-white presion">
        //                     Home
        //                 </a>
        //                 <a href="/services" className="navbar-item text-white presion">
        //                     Services
        //                 </a>
        //                 {((user==1)&& (<a href="/dashboard" className="navbar-item text-white presion">
        //                     Users
        //                 </a>) ) || ((user==2)&& ("") )}
        //                 <a href="/image" className="navbar-item text-white presion">
        //                     Images
        //                 </a>
        //                 <a href="/statistics" className="navbar-item text-white presion">
        //                     Statistics
        //                 </a>
        //                 <a href="/docs" className="navbar-item text-white presion">
        //                     Docs
        //                 </a>
        //                 <a href="/profile" className="navbar-item text-white presion">
        //                     Profile
        //                 </a>
        //             </div>

        //             <div className="navbar-end">
        //                 <div className="navbar-item">
        //                     <div className="buttons">
        //                         <button onClick={Logout} className="button is-light">
        //                             Log Out
        //                         </button>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
           
        // </nav>
        
    )
}

export default Navbar
