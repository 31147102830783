import React, { useState, useEffect } from "react";
import "../../../assets/css/menuLateral.scss";
import logo2 from '../../../assets/DEEP_GAZE (1).png';
import logo3 from '../../../assets/logo_cognitive_sinfondo.png';
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { ENDPOINT_NODE } from "../../../backend";
import { Navbar, Nav, NavItem ,Collapse} from 'react-bootstrap';
const MenuLateral = () => {
	const [name, setName] = useState("");
	const [token, setToken] = useState("");
	const [expire, setExpire] = useState("");
	const history = useHistory();
	const [user, setUser] = useState("");
	
	const refreshToken = async () => {
	  try {
		const response = await axios.get(ENDPOINT_NODE + "/token");
		setToken(response.data.accessToken);
		const decoded = jwt_decode(response.data.accessToken);
		setUser(decoded.userId);
		setName(decoded.name);
		setExpire(decoded.exp);
	  } catch (error) {
		if (error.response) {
		  history.push("/");
		}
	  }
	};
  
	const axiosJWT = axios.create();
  
	axiosJWT.interceptors.request.use(
	  async (config) => {
		const currentDate = new Date();
		if (expire * 1000 < currentDate.getTime()) {
		  const response = await axios.get(ENDPOINT_NODE + "/token");
		  config.headers.Authorization = `Bearer ${response.data.accessToken}`;
		  setToken(response.data.accessToken);
		  const decoded = jwt_decode(response.data.accessToken);
		  setName(decoded.name);
		  setExpire(decoded.exp);
		}
		return config;
	  },
	  (error) => {
		return Promise.reject(error);
	  }
	);
	useEffect(() => {  
	  refreshToken()
	},[] );
	const [isCollapsed, setIsCollapsed] = useState(false);
	const handleToggleMenu = () => {
		setIsCollapsed(!isCollapsed);
	  };


	  return (
		<div>
		  {isCollapsed ? (
			<button className="btn btn-primary mobile-toggle-btn" onClick={handleToggleMenu}>
			  <i className="bi bi-list"></i>
			</button>
		  ) : null}
		  <Collapse in={!isCollapsed}>
			<div className={`msb ${isCollapsed ? 'msb-collapsed' : ''}`}>
			  <nav className="navbar navbar-default mb-5" role="navigation">
				<div className="navbar-header">
				  <div className="brand-wrapper text-center">
					<div className="brand-name-wrapper">
					  <span className="navbar-brand">
						<img src={logo2} width="140" height="28" alt="logo" />
						<span className="mobile-toggle-btn" onClick={handleToggleMenu}>
						  <i className="bi bi-arrow-left-square-fill"></i>
						</span>
					  </span>
					</div>
				  </div>
				</div>
				<div className="side-menu-container">
				  <ul className="nv">
					<li>
					  <a href="/cms/head-day">
						<i className="bi bi-table" style={{ marginRight: "5px" }}></i>  Daily-stadistic
					  </a>
					</li>
					<li>
					  <a href="/cms/head-month">
						<i className="bi bi-table" style={{ marginRight: "5px" }}></i> Monthly-stadistic
					  </a>
					</li>
					<li>
					  <a href="/cms/tables">
						<i className="bi bi-people-fill" style={{ marginRight: "5px" }}></i> Users Services
					  </a>
					</li>
					<li>
					  <a href="/cms/all-images">
						<i className="bi bi-person-lines-fill" style={{ marginRight: "5px" }}></i> Users images
					  </a>
					</li>
					<li>
					  <a href="/cms/monitor">
						<i className="bi bi-list-check" style={{ marginRight: "5px" }}></i> Monitor control
					  </a>
					</li>
					<li>
					  <a href="/cms/userAdmin">
						<i className="bi bi-person-check-fill" style={{ marginRight: "5px" }}></i> User permits
					  </a>
					</li>
				  </ul>
				</div>
			  </nav>
			  <div className="m-5 footerhead">
				<div className="brand-wrapper text-center">
				  <div className="brand-name-wrapper">
					<a className="navbar-brand">
					  <img src={logo3} width="140" height="28" alt="logo" />
					</a>
				  </div>
				</div>
			  </div>
			</div>
		  </Collapse>
		</div>
	  );
}

export default MenuLateral;
