import React , {useState} from 'react';
import axios from 'axios';
import { ENDPOINT_NODE, ENDPOINT_YOLO } from '../../backend';
import { useHistory } from 'react-router-dom';
import {Toaster, toast } from 'react-hot-toast';

const Password = ({closeform}) => {
    const [email, setEmail] = useState('');
    const [msg, setMsg] = useState('');
    const history = useHistory();

    const recover = () => {
        
        
        axios({
            // Endpoint to send files
            url: ENDPOINT_NODE +"/check-email",
            method: "POST",
            // Attaching the form data
            data: {email: email,
            },
          })
          .then((response) => {
            if (response.data.exists){
                axios({
            // Endpoint to send files
            url: ENDPOINT_NODE +"/forgot-password",
            method: "POST",
            // Attaching the form data
            data: {username: email,
            },
          })
          .then((response) => {
            toast.success('Email sent')
            setTimeout(()=>closeform(), 1000) 
          });
            } else if (!response.data.exists){
                toast.error('Email dont exist')
            }
          });
        
        }
    return (
        <section>
                <div className="container">
                <Toaster
                    position="bottom-center"
                    reverseOrder={false}
                    />
                    <form className="box">
                        <a href="/login">
                            <i className="bi bi-arrow-left"></i>
                        </a>
                        <h3 className='h3 text-black mt-2'>We will send you an email to recover your password</h3>
                        <p className="has-text-centered text-danger">{msg}</p>

                        <div className="field mt-5">
                            <label className="label">Email</label>
                            <div className="controls">
                                <input type="email" className="input" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                        </div>
                        <div className="field mt-5">
                            <button className="button is-success is-fullwidth btnInfo " onClick={(e)=>recover(e.preventDefault())} disabled={email==''} >SEND</button>
                        </div>
                    </form>
                    
                </div>
        </section>
    );
}

export default Password;
