import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import "../assets/css/styles.css"
import axios from 'axios';
import Alert from '../components/error/ErrorAlert';
import { useHistory } from 'react-router-dom';
import  Select from 'react-select';
import { ENDPOINT_YOLO,ENDPOINT_NODE, ENDPOINT_PDETECTOR } from '../backend';
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  Media,
  Table,
  Row,
} from "reactstrap";

// import useAlert from 'react-alert'

const Pagination_detector_cms = (props) => {
    var ayuda = [];
    const items = props.informacion
        for (let i = 0; i<items.length; i++){
          ayuda.push(i)
        }
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [itemsPerPage, setitemsPerPage]= useState(5);
    const [nameImage,setNameImage] = useState('');
    const [vista,setVista] = useState(3);
    const [newImage,setNewImage]= useState();   
    const [itemsImage,setItemsImage]= useState();
    const [imagen,setImagen]=useState('')
    const history = useHistory();
    const [arrayitems,setArrayItems] = useState([])
    const [arrayitemsVeces,setArrayItemsVeces] = useState([])
    const [token,setToken ] = useState('')  
    const [busqueda,setBusqueda] = useState('')
    const [tabladFiltrado,setTablaFiltrado] = useState(props.informacion)
    const [valores,setValores]=useState(props.informacion)
    const [valoresaux,setValoresAux]=useState(props.ayuda)
    const [expire, setExpire] = useState('');
    const [usersAll, setUsersAll] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [messageAlert, setMessageAlert] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const [total,settotal]= useState(0)
    const customStyles = {
      container: (provided, state) => ({
        ...provided,
        width: '80px', // Ajusta el ancho según tus necesidades
      }),
    };
    const handleOptionChange = (selectedOption) => {
      setSelectedOption(selectedOption);
      console.log(selectedOption)
      setitemsPerPage(selectedOption.value)
      filtrarArray(props.informacion)
    };
    const originalOptions = [
      { value: 5, label: '5' },
      { value: 10, label: '10' },
      { value: 25, label: '25' },
      { value: 50, label: '50' },
      { value: 100, label: '100' },
      { value: 250, label: '250' }]
      const options = originalOptions.map((option) => ({
        value: option.value,
        label: option.label,
      }));
    React.useEffect(() => {
      // abriregistro(26);
      filtrarArray(props.informacion)
      getToken(107,1);
    }, []);
    const filtrarArray=(array) =>{
      var ayuda2 = [];
      var items2 = array
        for (let i = 0; i<items2.length; i++){
          ayuda2.push(i)
        }
        setValores(items2)
        setValoresAux(ayuda2)
        
        setCurrentItems(ayuda2.slice(itemOffset, itemOffset + itemsPerPage));
        setPageCount(Math.ceil(items2.length / itemsPerPage));
    }
    const abririmagen = async (a) => {
      try {
        setVista(2)
        const Endpoint= a
        const baseURL = ENDPOINT_PDETECTOR + "/procs/"+Endpoint
        await axios.get(baseURL).then((response) => {
          console.log(response.data)
          if (response.data.status == 1) {
            enlistar(response.data.result)
            
            imagenCreada(response.data)
          }else if (response.data.status == 0){
            setVista(4)
            
          }
          
        }); 
      } catch (error) {
        setShowAlert(true);
        if (error.response) {
          console.log(error.response.data); // Información detallada sobre el error de respuesta
          console.log(error.response.status); // Código de estado HTTP de la respuesta
          setMessageAlert("Error displaying");
        } else if (error.request) {
          console.log(error.request); // Información sobre la solicitud sin respuesta
          setMessageAlert("Request error");
        } else {
          console.log(error.message); // Otros errores
          setMessageAlert("Unknown error");
        }
      } 
      
    } 
    const getToken= async(id_user,service)=>{
      try {
        const response = await axios.post(ENDPOINT_NODE+'/get-password-token', {
            id_user: id_user,
            id_service: service
        });
        console.log(response.data)
    } catch (error) {
        
        if (error.response) {
          // setMsg(error.response.data.msg);
        }
    }
}
    const tenerToken = () => {
      let formData = new FormData();
      formData.append('username', "cristian@santabros.com.ar");
      formData.append('password', "carlos");
      axios({
          // Endpoint to send files
          url: ENDPOINT_YOLO+"/get-token",
          method: "POST",
          // Attaching the form data
          data: formData,
        })
        .then((response) => {
          setToken(response.data.access_token);
        });
      }
    const enlistar = (array) => {
      let items = []
      settotal(array.length)
      for (let i = 0; i< array.length; i++){
       items.push("Products")
      }
      items= items.sort()
      let UnicosElementos = []
      let elementosNveces=[]
      let contador = 1
      for(let j = 0; j < items.length ; j++){
        if(items[j+1]== items[j]){
          contador++;
        }else {
          UnicosElementos.push(items[j])
          elementosNveces.push(contador);
          contador = 1;
        }
      }
      console.log(UnicosElementos)
      setArrayItems(UnicosElementos)
      setArrayItemsVeces(elementosNveces)

    }
    const eliminarimagen = async (name) => {
      // alert("seguro quiere eliminar el contenido")
      try {
          await axios.get(ENDPOINT_YOLO+"/delete/"+name, {
            headers: {
              "Content-Type": "multipart/form-data",
              "Authorization": "Bearer " + token,
            }
              // filename: name,

          }).then((response)=>{
            console.log(response.data.error);
            if(response.data.error) {setShowAlert(true); setMessageAlert("Error al borrar");}
            else{window.location.reload()}
              
          })
      } catch (error) {
        setShowAlert(true);
        if (error.response) {
          console.log(error.response.data); // Información detallada sobre el error de respuesta
          console.log(error.response.status); // Código de estado HTTP de la respuesta
          setMessageAlert("Error al borrar");
        } else if (error.request) {
          console.log(error.request); // Información sobre la solicitud sin respuesta
          setMessageAlert("Request error");
        } else {
          console.log(error.message); // Otros errores
          setMessageAlert("Unknown error");
        }
      }
  }

   
    const imagenCreada = (data) => {
      setVista(1)
      setImagen([data.name_b64_heat])
      setNameImage(data.name)
      setItemsImage(data.result)
    }
    const handleChange = (e) => {
      setBusqueda(e.target.value)
      filtrar(e.target.value)
    }
    useEffect(() => {
        
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(valoresaux.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(valores.length / itemsPerPage));
      }, [itemOffset, itemsPerPage]);
      const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % valores.length;
        
        setItemOffset(newOffset);
      };
      const filtrar =(terminoBusqueda)=>{
        var resultadosBusqueda=tabladFiltrado.filter((elemento) => {
          console.log(tabladFiltrado)
          if(
            (elemento.name_image != null &&  elemento.name_image.toString().includes(terminoBusqueda.toLowerCase()))
          || 
           (elemento.created_at != null && elemento.created_at.toString().includes(terminoBusqueda.toLowerCase() ))
           || 
           (elemento.job_id_queue != null && elemento.job_id_queue.toString().includes(terminoBusqueda.toLowerCase() ))
          ){
            return elemento;
          }
        });
        
        
        filtrarArray(resultadosBusqueda)
      }
    return (
            <>
            <Row>
            <div className="col">
            {showAlert && <Alert message={messageAlert} />}
              <Card className="shadow">
              <div className='row'>
                <CardHeader className="border-0">
                  <div className='col-6'>
                  
                  <h3 className="mb-0 h3">Imagen List Product Detector</h3>
      
                  </div>
                  <div className='col-6'>
                  <div className="input-group rounded">
                        <input type="search" className="form-control " onChange={handleChange} value={busqueda} placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
                        
                        </div>
                        
                  </div>
                  </CardHeader>
                </div>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" >#</th>
                      <th scope="col" >Name</th>
                      <th scope="col" >Server</th>
                      <th scope="col" >Job Id</th>
                      <th scope="col" >Date</th>
                      <th scope="col" >Status</th>
                    </tr>
                  </thead>
                  <tbody>
                  {valores.length !=0 && currentItems && currentItems.map((item,index) => (
                    <tr key={index}>
                      <th scope="row">
                        <Media className="align-items-center">
                          <Media>
                            <span className="mb-0 text-sm">
                            {index + 1} 
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>{valores[item].name_image.length > 10 ? valores[item].name_image.slice(0, 20) : valores[item].name_image}</td>
                      <td>
                        <Badge color="" className="badge-dot mr-4 ">
                          <i className="bg-warning" />
                          <span className="text-black">{item.id_service == 1 ? "Eyetracking" 
                          :
                          item.id_service == 2  ? "Rice Field" : 
                          "Product Detector"}</span>
                        </Badge>
                      </td>
                      <td>
                        <Badge color="" className="badge-dot mr-4 ">
                          <i className="bg-warning" />
                          <span className="text-black">{valores[item].job_id_queue} </span>
                        </Badge>
                      </td>
                      <td>
                      {valores[item].created_at}
                      </td>
                      <td>
                      <div className="d-flex align-items-center">
                      {valores[item].message}
                      </div>
                      </td>
                    </tr>
                    
                  ))}
                  {valores.length == 0 && (<h1 className='h1 text-center'> Does not have processed images </h1>)}
                  </tbody>
                </Table>
                <CardFooter className="text-center">
                <div className='text-center text-white alinear my-5'>
                <ReactPaginate

                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="< "
                  renderOnZeroPageCount={null}
                  containerClassName="pagination justify-content-center"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  activeClassName="active"
                  />
                </div>
                
                </CardFooter>
                <div className="row">
                <div className="col-4 ml-5 mb-3 d-flex align-items-center">
            <label className="text-dark mr-2" >Number of rows: </label> 
            <span>
            <Select options={options} menuPlacement="top" 
            placeholder="" onChange={handleOptionChange}
            styles={customStyles} />
            </span>
                </div>
            </div>
              </Card>
            </div>
          </Row>
            
      <br></br>
      {((vista == "1" ) && (
        <div className='container bg-dark bordewhite mb-5'>
          <h1 className='h1 text-white '>NAME: {nameImage.slice(0, 20)}</h1>
          <div className='row mt-5'>
            
            <div className='col-6 mb-5'>
              <img src={imagen[0]} width="448" height="84" alt="logo" className='bordewhite'></img>
            </div>
            <div className='col-6 mb-5'>
            <div className='row'>
                <div className='col-6'> 
                <label className='label text-white'> Products Detected </label>
                <p className='text-white'> Products</p>
                {/* <br></br>
                <p className='text-white'><strong className='text-white'>Product Classification:</strong></p>
                {arrayitems && arrayitems.map((item,index) => (
                    
                    <p className='text-white' key={index}>{index+1} - {arrayitems[index].charAt(0).toUpperCase()+arrayitems[index].slice(1)} 
                    </p>
                
             ))} */}

                </div>
                <div className='col-6'>  
                  <label className='label text-white text-center'> Quantity </label>

                  <p className='text-white text-center'> {total}</p>
                  {/* <br></br>
                  <br></br>
                  {arrayitemsVeces && arrayitemsVeces.map((item,index) => (
                    
                    <p className='text-white text-center' key={index}>{arrayitemsVeces[index]} 
                    </p>
                
             ))} */}
                </div>

            </div>
            
             
            </div>

          </div>
      </div>
            
    )) || ((vista == "2") && (
      <h1 className='h1 text-white'>PROCESSING...</h1>
      ))|| ((vista == "3") && (
      <h1 className='h1 text-white'>You can see your image below <i class="bi bi-arrow-down"></i></h1>
      ))|| ((vista == "4") && (
      <h1 className='h1 text-white'>Your image is not ready yet, try in a few minutes </h1>
      ))}
      <br/>
      <br/>
    </>
    
    );
}

export default Pagination_detector_cms;


