import React , { useState, useEffect }from 'react';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { useHistory } from 'react-router-dom';
import { ENDPOINT_NODE } from '../../backend';
import UploadFilesNew from '../upload_new';

const Servicio6 = () => {

    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const history = useHistory();    
    const[id_user,setId_user] = useState('')
    const [html,setHtml] = useState('')
    const [email,setEmail] = useState('')
    const [msg, setMsg] = useState("");
    const [serviciosActivos2, SetServiciosActivos2] = useState({});
    useEffect(() => {
        refreshToken();
        
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get(ENDPOINT_NODE+'/token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);            
            setId_user(decoded.userId)
            setEmail(decoded.email)
            // cambiarhtml(decoded.userId,decoded.email);
            getServicesActivobyid(decoded.userId,response.data.accessToken,decoded.email);

            setId_user(decoded.userId)
            setName(decoded.name);
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                history.push("/");
            }
        }
    }
    // const cambiarhtml = (id,ema) => {
    //     const usuario = {
    //         servicio:1,
    //         id_cliente: id,
    //         email:ema
    //     }
    //     setHtml(
    //         <div className='card bg-dark'>
           
    //             <h2 className='text-white h2 text-center my-3'>Object Detection and Recognition V.1</h2>

                
    //             <h5 className='my-3 text-white h5  text-center'>Select the images you want to process by selection files on your computer</h5>
    //             <UploadFilesNew servicio={usuario}></UploadFilesNew>
    //         </div>
    //     )
    // }
    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(ENDPOINT_NODE+'/token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setName(decoded.name);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
    const getServicesActivobyid = async (user,tToken,email) => {

        try {
          await axios
            .post(
              ENDPOINT_NODE + "/active_servicesbyid",
              {
                user_id: user,
              },
              {
                headers: {
                  Authorization: `Bearer ${tToken}`,
                },
              }
            )
            .then((response) => {
              SetServiciosActivos2(response.data[0])
              cambiarhtml(user,email,response.data[0].object_v1)
            });
        } catch (error) {
          if (error.response) {
            setMsg(error.response.data.msg);
          }
        }
      };
      const cambiarhtml = async (id,ema,object_v1) => {
        const usuario = {
            servicio:1,
            id_cliente: id,
            email:ema
        }
        setHtml(
            <div className='card bg-dark'>
           
                <h2 className='text-white h2 text-center my-3'>Object Detection and Recognition V.1</h2>
                
                {(object_v1==1 ? <>
                <h5 className='my-3 text-white h5  text-center'>Select the images you want to process by selection files on your computer</h5>
                        <UploadFilesNew servicio={usuario}></UploadFilesNew>
                </>
                
                : history.push("/services")) }
                
            </div>
        )
    }
    
    return (
        <div className='my-5 container height' >
            {html}
        </div>
    );
}

export default Servicio6;
