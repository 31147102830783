import React, {useState} from 'react'
import axios from 'axios';
import { NavbarWrapper } from '../../assets/css/navbarWrapper';

import { useHistory } from 'react-router-dom';

import { ENDPOINT_NODE } from '../../backend';
import logo2 from '../../assets/deepgazeweb1.png';
const Navbar3 = (props) => {
    const history = useHistory();
    //uno es administrador
    //dos es usuario de compañia
    const [user,setUser] = useState(1)
    
    const Logout = async () => {
        try {
            await axios.delete(ENDPOINT_NODE+'/logout');
            history.push("/");
        } catch (error) {
        }
    }
    return (
        <NavbarWrapper open={props.open} clas >
        <a className="navbar-item presion espacio " href="#" >
                         <img src={logo2} width="112" height="28" alt="logo" />
                     </a>
        <a href="/services" className="navbar-item text-white presion espacio espacio4"
        data-title="See all our services">
                             Home
                         </a>
                         <a href="/services" className="navbar-item text-white presion espacio"
                         data-title="See all our services">
                             Services
                         </a>
                         {/* {((user==1)&& (<a href="/dashboard" className="navbar-item text-white presion espacio">
                             Users
                         </a>) ) || ((user==2)&& ("") )} */}
                         <a href="/image" className="navbar-item text-white presion espacio"
                         data-title="See all processed images">
                             Images
                         </a>
                         <a href="/statistics" className="navbar-item text-white presion espacio"
                         data-title="See statistics of the services used">
                             Statistics
                         </a>
                         {/* <a href="/docs" className="navbar-item text-white presion espacio">
                             Docs
                         </a> */}
                         <a href="/profile" className="navbar-item text-white presion espacio5 espacio"
                         data-title="Make changes to your account">
                             Profile
                         </a>
                         {/* <a href="/contact-us" className="navbar-item text-white presion espacio espacio5">
                             Contact Us
                         </a> */}

                     
                                 <button onClick={Logout} className="button espacio is-light ">
                                     Log Out
                                 </button>
                         
                       
        </NavbarWrapper>
    );
}

export default Navbar3;
