import React ,{useState,useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { ENDPOINT_NODE } from '../../backend';

const BotonDarBaja = (props) => {
    const servicio = props.servicio; 
    const history = useHistory();
    const [msg, setMsg] = useState('');
    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const[infocliente,setInfoCLiente] = useState()
    const[id_user,setId_user] = useState()

    useEffect(() => {
        refreshToken();
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get(ENDPOINT_NODE+'/token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setId_user(decoded.userId);
            setName(decoded.name);
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                history.push("/");
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(ENDPOINT_NODE+'/token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setName(decoded.name);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const desactivarServicio = async (e) => {
        getCantImage(id_user,servicio, token)
        
        restaurarServicio(e, token)
        history.push("/services");
    }

    const createHistoria = async (id_usuario,id_servicio,cant_imagen, tToken) => {
        
        try {
            await axios.post(ENDPOINT_NODE+'/register-history', {
                id_client: id_usuario,
                id_service: id_servicio,
                cant_image: cant_imagen,
            }, {
                headers: {
                  Authorization: `Bearer ${tToken}`,
                }});
        } catch (error) {
            if (error.response) {

                setMsg(error.response.data.msg);
                
            }
        }
    }
    const getCantImage = async (id_usuario,id_servicio, token) => {
        
        try {
            await axios.post(ENDPOINT_NODE+'/getClientByService', {
                id_user: id_usuario,
                id_service: id_servicio,
            }, {
                headers: {
                  Authorization: `Bearer ${token}`,
                }}).then( (response)=>{
                const data = response.data
                setInfoCLiente(data)
                createHistoria(id_usuario,id_servicio,data[0].cant_image, token)
            });
            
            
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    }
    const restaurarServicio = async (e, tToken) => {
        e.preventDefault();
        try {
            await axios.post(ENDPOINT_NODE+'/restart-client-service', {
                id_user: id_user,
                id_service: servicio,
            }, {
                headers: {
                  Authorization: `Bearer ${tToken}`,
                }})
            
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    }

    return (
        <div>
            <button className='btn btn-warning text-white btn-lg' onClick={desactivarServicio}>Unsubscribe</button>
        </div>
    );
}

export default BotonDarBaja;
