import React, {useEffect} from 'react';
import { ENDPOINT_NODE } from '../../../backend';
import axios from 'axios';
const PasswordIA = () => {
    useEffect(() => {
        getToken(107,1);
    }, []);
    const getToken= async(id_user,service)=>{
            try {
              const response = await axios.post(ENDPOINT_NODE+'/get-password-token', {
                  id_user: id_user,
                  id_service: service
              });
              console.log("prueba")
              console.log(response.data)
          } catch (error) {
              
              if (error.response) {
                // setMsg(error.response.data.msg);
              }
          }
    }
    return (
        <div>
            
        </div>
    );
}

export default PasswordIA;
