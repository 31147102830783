import React from 'react';
import "../../assets/css/styles.css"

const Alert = ({ message }) => {
  return (
    <div className="alert">
      {message}
    </div>
  );
};

export default Alert;