/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { useHistory } from 'react-router-dom';
import { ENDPOINT_NODE,ENDPOINT_YOLO } from '../backend';
import { Country } from './FormulariosServicios.js/phoneNumbers';
import Modal from './Modals/modal_userNew';
import useModal from './hooks/modals';
import CredencialesServices from './FormulariosServicios.js/credenciales_services';
import Email from './documentation/email/email';
import Navbar from './Navbar';
const Dashboard = () => {
    const [name, setName] = useState('');
    const [name2, setName2] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confPassword, setConfPassword] = useState('');
    const [token, setToken] = useState('');
    const [ tokenApi,setTokenApi] = useState('')
    const [expire, setExpire] = useState('');
    const [users, setUsers] = useState([]);
    const history = useHistory();
    const [vista,setVista] = useState(false)
    const [msg, setMsg] = useState('');   
    const [id_user,setId_user] = useState()
    const services = ["Object Detection and Classification",
         "Eye Tracking",
        "Weapons Detection",
        "Rice Field Analysis (Special Subscription)",
        "Face ID Verification (Biometrics)",
        "License Plate Recognition (Not Available)"]
    const [emailedit, setEmailedit] = useState('');
    const [nameedit, setNameedit] = useState('');
    const [numberedit, setNumberedit] = useState('');
    const [companyedit, setCompanyedit] = useState('');
    const [companyNumberedit, setCompanyNumberedit] = useState('');
    const [phoneedit, setPhoneedit] = useState("34");
    const [isOpen, openModal, closeModal] = useModal(false)
    const [isOpenEdit, openModalEdit, closeModalEdit] = useModal(false)
    const [html,setHtml] = useState('')

    useEffect(() => {
        refreshToken();
        tenerToken();
        getUsers();
        getToken(107,1);
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get(ENDPOINT_NODE+'/token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setEmail(decoded.email)
            setId_user(decoded.userId);
            setName(decoded.name);
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                history.push("/");
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(ENDPOINT_NODE+'/token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const close = () => {
        setVista(false)
    }
    const CreateClientes= async (id_usuario,id_servicio, tToken) => {
        
        try {
            await axios.post(ENDPOINT_NODE+'/register-client', {
                id_user: id_usuario,
                id_service: id_servicio,
                cant_image: 0,
                status: 0
            }, {
                headers: {
                  Authorization: `Bearer ${tToken}`,
                }});
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    }
    const update_name= async (name,id) => {
        try {
            await axios.post(ENDPOINT_NODE+'/update-user', {
                name: name,
                id: id,
            }, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    }
    const Register = async (e) => {
        e.preventDefault();
        const country = Country.filter((item)=> phoneedit == item.code)
        const pais= country[0].country
        try {
            await axios.post(ENDPOINT_NODE+'/update-info', {
                contact_name: nameedit,
                phone_number: "+"+ phoneedit+ numberedit,
                company_name: companyedit,
                company_registration_number: companyNumberedit,
                country: pais ,
                user_id:id_user
            }, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
            update_name(nameedit, id_user)
            history.push("/dashboard");
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        
    }
}
    const edit =(email,name) =>{
        setNameedit('')
        setEmailedit('')
        setCompanyedit('')
        setCompanyNumberedit('')
        setNumberedit('')
        setPhoneedit("34")
        setVista(true)
        setNameedit(name)
        setEmailedit(email)
        openModalEdit()
    
    }
    const getToken= async(id_user,service)=>{
        try {
          const response = await axios.post(ENDPOINT_NODE+'/get-password-token', {
              id_user: id_user,
              id_service: service
          });
          console.log("prueba")
          console.log(response.data)
      } catch (error) {
          
          if (error.response) {
            // setMsg(error.response.data.msg);
          }
      }
}
    const tenerToken = () => {
        let formData = new FormData();
        formData.append('username', "cristian@santabros.com.ar");
        formData.append('password', "carlos");
        axios({
            // Endpoint to send files
            url: ENDPOINT_YOLO+"/get-token",
            method: "POST",
            // Attaching the form data
            data: formData,
          })
          .then((response) => {
            setTokenApi(response.data.access_token);
          });
        }
    const credenciales = (email) =>{
        openModal()
        setHtml(
            <CredencialesServices email={email} tokenApi={tokenApi}></CredencialesServices>
        )
    }
    const getUsers = async () => {
        const response = await axiosJWT.get(ENDPOINT_NODE+'/users', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setUsers(response.data);
        
    }
    return (
        <>
            <Navbar/>
            <div className="container mt-5 height">
            <h1 className='h1 text-white'>Welcome Back: {name}</h1>
            <Email></Email>
            <button onClick={openModalEdit} className="button is-info my-3">Create User</button>
            <table className="table is-striped is-fullwidth">
                <thead>
                    <tr className="text-center">
                        <th>No</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Services</th>
                        <th>Edit</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {(id_user==26 ) && users.map((user, index) => (
                        <tr key={user.id}>
                            <td>{index + 1}</td>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td><i title="Credentials and ip url" className="bi bi-file-earmark-person-fill" onClick={(e)=>credenciales(user.email,e)}></i></td>
                            <td><i title="Edit user" className="bi bi-pencil" onClick={(e)=>edit(user.email,user.name,e)}></i></td>
                            <td><i title="Delete user" className="bi bi-trash-fill"></i></td>
                        </tr>
                    ))}

                </tbody>
            </table>
            <Modal isOpenform={isOpenEdit} closeform={closeModalEdit}>            
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column ">
                            <form onSubmit={Register} className="box">
                                <p className="has-text-centered">{msg}</p>
                                <div className="field mt-5">
                                    <label className="label">Contact Name</label>
                                    <div className="controls">
                                        <input type="text" className="input" placeholder="Contact Name"
                                            value={nameedit} onChange={(e) => setNameedit(e.target.value)} />
                                    </div>
                                </div>
                                <div className="controls">
                                    <div className="field mt-5">
                                    <label className="label">Country</label>
                                    <div className="controls">
                                    <select onChange={(e)=>setPhoneedit(e.target.value)}>
                                                {Country &&
                                                Country.map((pais, index) => (
                                                    <>
                                                    {pais.country == "Spain" ? <option  value={pais.code} selected>{pais.country}</option> :
                                                    <option  value={pais.code} >{pais.country}</option>  
                                                      }
                                                    </>
                                                    
                                                                                      
                                                ))}
                                            </select>
                                    </div>
                                </div>
                                <div className="field mt-5">
                                <label className="label">Contact Telephone Number</label>
                                
                                    <div className='row'>
                                        <div className='col-2'>
                                        <label className='label'>+{phoneedit}</label>
                                        </div>
                                        
                                        <div className='col-10'>
                                        
                                        <input type="text" className="input" placeholder="Contact Number"
                                            value={numberedit} onChange={(e) => setNumberedit(e.target.value)} />
                                        </div>
                                    </div>
                                    
                                    
                                        
                                    </div>
                                </div>
                                <div className="field mt-5">
                                    <label className="label">Email</label>
                                    <div className="controls">
                                        <input type="text" className="input" placeholder={emailedit} disabled value={emailedit} onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                </div>
                                <div className="field mt-5">
                                    <label className="label">Company Name</label>
                                    <div className="controls">
                                        <input type="text" className="input" placeholder="Company Name"
                                            value={companyedit} onChange={(e) => setCompanyedit(e.target.value)} />
                                    </div>
                                </div>
                                <div className="field mt-5">
                                    <label className="label">Company Registration Number</label>
                                    <div className="controls">
                                        <input type="text" className="input" placeholder="Company Registration Number"
                                            value={companyNumberedit}  onChange={(e) => setCompanyNumberedit(e.target.value)} />
                                    </div>
                                </div>
                                
                                <div className="field mt-5">
                                    
                                    <div className="controls">
                                    
                                                {services &&
                                                services.map((servicio, index) => (
                                                    <>
                                                    <label className="label">Service</label>
                                                <div className='row'>

                                                    <div className='col-5'>
                                                        <label><input type="checkbox" id={index} value={servicio}/>  {servicio}<br></br></label>
                                                    </div>
                                                    <div className='col-3'>
                                                        <label className=''>IP WhiteList</label><input type="text" className='input'  />
                                                    </div>
                                                    <div className='col-4'>
                                                        <label className=''>Destination URL</label><input type="text" className='input'  />
                                                    </div>

                                                    
                                                    
                                                    
                                                    
                                                </div>  
                                                <hr width="400" />
                                                    </>
                                                                             
                                                ))}
                                            
                                    </div>
                                </div>
                                <div className="field mt-5">
                                    <button type="submit" onClick={Register} className="button is-success is-fullwidth">Edit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
        </Modal>
    
            
        <Modal isOpenform={isOpen} closeform={closeModal}>
            {html}
        </Modal>
        </div>
        </>
        
        
    )
}

export default Dashboard
