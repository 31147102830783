import React, { useState, useRef } from 'react';
import ExifReader from 'exif-js';

const VideoMetadataViewer = () => {
  const videoRef = useRef(null);
  const [metadata, setMetadata] = useState(null);

  const handleVideoUpload = (event) => {
    const file = event.target.files[0];
    const videoBlob = URL.createObjectURL(file);
    videoRef.current.src = videoBlob;
  };

  const extractMetadata = () => {
    const video = videoRef.current;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    // Define el tamaño del canvas según el tamaño del video
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    // Dibuja el video en el canvas
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    // Obtén el contenido del canvas como una imagen
    const imageData = canvas.toDataURL('image/png');

    // Aquí puedes procesar la imagen para buscar metadatos

    // Ejemplo: obtén la información de geoposicionamiento de la imagen
    extractGeolocationFromImage(imageData)
      .then((geolocation) => {
        // Actualiza el estado con los metadatos
        setMetadata({ geolocation });
      })
      .catch((error) => {
        console.error('Error al extraer metadatos:', error);
      });
  };

  const extractGeolocationFromImage = (imageData) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
        ExifReader.getData(this, function () {
          const lat = ExifReader.getTag(this, 'GPSLatitude');
          const lon = ExifReader.getTag(this, 'GPSLongitude');
          const latRef = ExifReader.getTag(this, 'GPSLatitudeRef');
          const lonRef = ExifReader.getTag(this, 'GPSLongitudeRef');

          if (lat && lon && latRef && lonRef) {
            const latitude = convertDMSToDD(lat[0], lat[1], lat[2], latRef);
            const longitude = convertDMSToDD(lon[0], lon[1], lon[2], lonRef);
            resolve({ latitude, longitude });
          } else {
            reject(new Error('No se encontró información de geoposicionamiento en la imagen.'));
          }
        });
      };
      img.src = imageData;
    });
  };

  const convertDMSToDD = (degrees, minutes, seconds, direction) => {
    let dd = degrees + minutes / 60 + seconds / (60 * 60);
    if (direction === 'S' || direction === 'W') {
      dd = dd * -1;
    }
    return dd;
  };

  return (
    <div>
      <input type="file" onChange={handleVideoUpload} />
      <video ref={videoRef} controls onLoadedMetadata={extractMetadata} style={{ width: 400 }} />
      {metadata && (
        <div>
          <h2>Metadata</h2>
          <pre>{JSON.stringify(metadata, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default VideoMetadataViewer;
// "c:\Users\Edwin\Videos\exiftool.exe" -ee "c:\Users\Edwin\Videos\DJI_20221227115356_0002_S\frame_20.jpg"

// "c:\Users\Edwin\Videos\exiftool.exe" -ee "c:\Users\Edwin\Videos\IMG_2849.MOV" >"c:\Users\Edwin\Videos\IMG_2849.txt"
// "c:\Users\Edwin\Videos\exiftool.exe" -ee "c:\Users\Edwin\Videos\VID_20240224_083438.mp4" >"c:\Users\Edwin\Videos\VID_20240224_083438.txt"

// "c:\Users\Edwin\Videos\exiftool.exe" -ee "c:\Users\Edwin\Videos\VID_20240224_092.mp4" >"c:\Users\Edwin\Videos\VID_20240224_092.txt"
// "c:\Users\Edwin\Videos\exiftool.exe" -ee "c:\Users\Edwin\Videos\VID_20240224_083546.mp4" >"c:\Users\Edwin\Videos\VID_20240224_083546.txt"
// "c:\Users\Edwin\Videos\exiftool.exe" -ee "c:\Users\Edwin\Videos\VID_20240224_083620.mp4" >"c:\Users\Edwin\Videos\VID_20240224_083620.txt"
// "c:\Users\Edwin\Videos\exiftool.exe" -ee "c:\Users\Edwin\Videos\VID_20240224_084539.mp4" >"c:\Users\Edwin\Videos\VID_20240224_084539.txt"
// "c:\Users\Edwin\Videos\exiftool.exe" -ee "c:\Users\Edwin\Videos\VID_20240224_092356.mp4" >"c:\Users\Edwin\Videos\VID_20240224_092356.txt"