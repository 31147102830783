import http from "../http-commons";
import axios from "axios";
import { ENDPOINT_NODE, ENDPOINT_PYTHON } from "../backend";

class UploadFilesService {
  upload(file, name, base64, EndPoint,token, onUploadProgress,) {
    let formData = new FormData();
    formData.append("name", name);
    formData.append("image", base64);
    console.log(ENDPOINT_NODE);
   return  (axios({
      // Endpoint to send files
      url: ENDPOINT_PYTHON+"/api-send-images",
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": "Bearer " + token,
      },
      // Attaching the form data
      data: formData,
    })
    .then((response) => {
    }))
    // return http.post(EndPoint, formData, {
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //     "Authorization": "Bearer " + token,
    //   },
    //   onUploadProgress,
    // });
  }

  getToken(username, password) {
    let formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);

  return http.post("/login", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  });}

  getFiles() {
    return http.get("/files");
  }
}

export default new UploadFilesService();