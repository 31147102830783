import React from 'react';
import "../../assets/css/doc.css";
import logo2 from '../../assets/deepgazeweb1.png';
import "../documentation/scripts"

const Doc = () => {
    return (
        <div >
            <div className="left-menu ">
                <div className="content-logo  mt-5" >
                    <div className="logo">
                        <img alt="platform by Emily van den Heever from the Noun Project" title="platform by Emily van den Heever from the Noun Project" src={logo2} height="32" />
                        <span>API Documentation</span>
                    </div>
                    <button className="burger-menu-icon" id="button-menu-mobile">
                        <svg width="34" height="34" viewBox="0 0 100 100"><path className="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"></path><path className="line line2" d="M 20,50 H 80"></path><path className="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"></path></svg>
                    </button>
                </div>
                <div className="mobile-menu-closer"></div>
                <div className="content-menu">
                    <div className="content-infos">
                        <div className="info"><b>Version:</b> 1.0.5</div>
                        <div className="info"><b>Last Updated:</b> 15th Sep, 2021</div>
                    </div>
                    <ul>
                        <li className="scroll-to-link active" data-target="content-get-started">
                            <a>GET STARTED</a>
                        </li>
                        <li className="scroll-to-link" data-target="content-get-characters">
                            <a>Get Characters</a>
                        </li>
                        <li className="scroll-to-link" data-target="content-errors">
                            <a>Errors</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='content-page'>
                <div className="content-code"></div>
                <div className="content">
                    <div className="overflow-hidden content-section" id="content-get-started">
                        <h1>Get started</h1>
                            <pre>
                    API Endpoint

                        https://api.westeros.com/
                                </pre>
                        <p>
                            The Westeros API provides programmatic access to read Game of Thrones data. Retrieve a character, provide an oauth connexion, retrieve a familly, filter them, etc.
                        </p>
                        <p>
                            To use this API, you need an <strong>API key</strong>. Please contact us at <a href="mailto:jon.snow@nightswatch.wes">jon.snow@nightswatch.wes</a> to get your own API key.
                        </p>
                    </div>
                    <div className="overflow-hidden content-section" id="content-get-characters">
                <h2>get characters</h2>
                <pre><code className="bash">
                        # Here is a curl example
                        curl \
                        -X POST http://api.westeros.com/character/get \
                        -F 'secret_key=your_api_key' \
                        -F 'house=Stark,Bolton' \
                        -F 'offset=0' \
                        -F 'limit=50'
                    </code></pre>
                    <p>
                To get characters you need to make a POST call to the following url : 
                <code className="higlighted break-word">http://api.westeros.com/character/get</code>
            </p>
            <br></br>
            <pre><code className="json">
                Result example :

                
                result: [
                    
                    id: 1,
                    first_name: "Jon",
                    last_name: "Snow",
                    alive: true,
                    house: "Stark",
                    gender: "m",
                    age: 14,
                    location: "Winterfell"
                    ,
                    
                    id: 2,
                    first_name: "Eddard",
                    last_name: "Stark",
                    alive: false,
                    house: "Stark",
                    gender: "m",
                    age: 35,
                    location: 'Winterfell'
                    ,
                    
                    id: 3,
                    first_name: "Catelyn",
                    last_name: "Stark",
                    alive: false,
                    house: "Stark",
                    gender: "f",
                    age: 33,
                    location: "Winterfell"
                    ,
                    
                    id: 4,
                    first_name: "Roose",
                    last_name: "Bolton",
                    alive: false,
                    house: "Bolton",
                    gender: "m",
                    age: 40,
                    location: "Dreadfort"
                    ,
                    
                    id: 5,
                    first_name: "Ramsay",
                    last_name: "Snow",
                    alive: false,
                    house: "Bolton",
                    gender: "m",
                    age: 15,
                    location: "Dreadfort"
                    ,
                ]
                
                </code></pre>
                <h4>QUERY PARAMETERS</h4>
                <table className="central-overflow-x">
                <thead>
                <tr>
                    <th>Field</th>
                    <th>Type</th>
                    <th>Description</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>secret_key</td>
                    <td>String</td>
                    <td>Your API key.</td>
                </tr>
                <tr>
                    <td>search</td>
                    <td>String</td>
                    <td>(optional) A search word to find character by name.</td>
                </tr>
                <tr>
                    <td>house</td>
                    <td>String</td>
                    <td>
                        (optional) a string array of houses:
                    </td>
                </tr>
                <tr>
                    <td>alive</td>
                    <td>Boolean</td>
                    <td>
                        (optional) a boolean to filter alived characters
                    </td>
                </tr>
                <tr>
                    <td>gender</td>
                    <td>String</td>
                    <td>
                        (optional) a string to filter character by gender:<br/>
                        m: male<br/>
                        f: female
                    </td>
                </tr>
                <tr>
                    <td>offset</td>
                    <td>Integer</td>
                    <td>(optional - default: 0) A cursor for use in pagination. <br/>Pagination starts offset the specified offset.</td>
                </tr>
                <tr>
                    <td>limit</td>
                    <td>Integer</td>
                    <td>(optional - default: 10) A limit on the number of objects to <br/> be  returned, between 1 and 100.</td>
                </tr>
                </tbody>
                                </table>
                                
            </div>
            <div className="overflow-hidden content-section" id="content-errors">
            <h2>Errors</h2>
            <p>
                The Westeros API uses the following error codes:
            </p>
            <table>
                <thead>
                <tr>
                    <th>Error Code</th>
                    <th>Meaning</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>X000</td>
                    <td>
                        Some parameters are missing. This error appears when you don't <br/>pass every mandatory parameters.
                    </td>
                </tr>
                <tr>
                    <td>X001</td>
                    <td>
                        Unknown or unvalid <code className="higlighted">secret_key</code>.<br/> This error appears if you use an unknow API key or if your API<br/> key expired.
                    </td>
                </tr>
                <tr>
                    <td>X002</td>
                    <td>
                        Unvalid <code className="higlighted">secret_key</code> for this domain.<br/> This error appears if you use an  API key non specified for your domain.<br/> Developper or Universal API keys doesn't have domain checker.
                    </td>
                </tr>
                <tr>
                    <td>X003</td>
                    <td>
                        Unknown or unvalid user <code className="higlighted">token</code>.<br/> This error appears if you use an unknow user <code className="higlighted">token</code> or if the user <code className="higlighted"><br/> token</code> expired.
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
                </div>
                <div className="content-code"></div>

            </div>
            
        </div>
    );
}

export default Doc;
