import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupText,
    InputGroup,
    Row,
    Container,
    Col
  } from "reactstrap";
  import React, { useState } from 'react'
  import axios from 'axios';
  import { useHistory } from 'react-router-dom';
  import { ENDPOINT_NODE } from "../../backend";
  import { Toaster, toast } from "react-hot-toast";
  import useModal from '../../components/hooks/modals';
  import Modal from '../../components/Modals/modal_userNew';
  // import Password from './password/password';
  import Password from '../../components/password/password'

  const LoginCms = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [msg, setMsg] = useState('');
    const history = useHistory();
    const [error,setError]=useState(false);
    const [isOpen, openModal, closeModal] = useModal(false)
  
    const Auth = async (e) => {

          setError(false);
          e.preventDefault();
            try {
              const response = await axios.post(ENDPOINT_NODE+'/login', {
                  email: email,
                  password: password
              });
              if (response.data.user[0].type == 20 ){
                toast.success("Successful login")
                setTimeout(()=>history.push("/cms/tables"),1000)
              } else{
                toast.error("You do not have administrator permissions")
              }
              
              
          } catch (error) {
              
              if (error.response) {
                toast.error("wrong data")
                setError(true);
                setMsg(error.response.data.msg);
              }
          }
          
          
      }
  
    return (
      <>
      <br></br>
      <br></br>
      <Container >
          <Row className="justify-content-center">
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0 mt-5">
            <CardHeader className="bg-transparent pb-5">
              {/* <div className="text-muted text-center mt-2 mb-3">
                <small>Sign in with</small>
              </div> */}
              <div className="btn-wrapper text-center">
                
                  
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Sign in with credentials</small>
              </div>
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    <Input
                      placeholder="Email"
                      type="email"
                      autoComplete="new-email"
                      value={email} 
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    <Input
                      placeholder="Password"
                      type="password"
                      autoComplete="new-password"
                      value={password} 
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                {error ?
                <div className="alert alert-danger" role="alert">
                  The credentials are not correct
                </div>
                : ""
                }
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor=" customCheckLogin"
                  >
                    <span className="text-muted">Remember me</span>
                  </label>
                </div>
                <div onClick={Auth} className="text-center">
                  <Button  className="my-4" color="primary" type="button">
                    Sign in
                  </Button>
                </div>
                <center> <a className='texto-primary' ><u>Forgot password?</u> </a></center>
              </Form>
            </CardBody>
          </Card>
          <Toaster
            position="bottom-center"
            reverseOrder={false}
          />
          {/* <Row className="mt-3">
            <Col xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <small>Forgot password?</small>
              </a>
            </Col>
            <Col className="text-right" xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <small>Create new account</small>
              </a>
            </Col>
          </Row> */}
        </Col>
        </Row>
      </Container>
      <Modal isOpenform={isOpen} closeform={closeModal}>
                <Password closeform={closeModal}></Password>
                
        </Modal>
      </>
    );
  };
  
  export default LoginCms;
  