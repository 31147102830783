import React, {useState} from 'react'
import axios from 'axios';
import { NavbarWrapper } from '../../assets/css/navbarWrapper';

import { useHistory } from 'react-router-dom';

import { ENDPOINT_NODE } from '../../backend';
import logo2 from '../../assets/deepgazeweb1.png';
const Navbar2 = (props) => {
    //uno es administrador
    //dos es usuario de compañia
    const [user,setUser] = useState(1)
    
    const history = useHistory();
    const login= ()=> {
        history.push("/login");
    }
    const register= ()=> {
        history.push("/register");
    }
    return (
        <NavbarWrapper open={props.open}>
        <a className="navbar-item presion espacio" href="#" >
                         <img src={logo2} width="112" height="28" alt="logo" />
                     </a>
        

                     
                         <div className="buttons">
                                 <button onClick={login} className="button is-light text-bold espacio"
                                 data-title="Enter the system">
                                     Login
                                 </button>
                                 <button onClick={register} className="button is-light espacio"
                                 data-title="Register to the system">
                                     Register
                                 </button>
                             </div>
                         
                       
        </NavbarWrapper>
    );
}

export default Navbar2;
