import React, {useState} from 'react';
import axios from 'axios';
import { ENDPOINT_NODE, ENDPOINT_YOLO } from '../../backend';
import { useHistory } from 'react-router-dom';
const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmpassword, setConfirmPassword] = useState('');
    const [url,setUrl] = useState(window.location.href)
    const token_reset= url.split("?reset=")
    const history = useHistory();

    const recover = (e) => {
        e.preventDefault()
        let formData = new FormData();
        formData.append('password', password);
        axios({
            // Endpoint to send files
            url: ENDPOINT_NODE +"/new-password",
            method: "POST",
            headers: {
                reset: `${token_reset[1]}`
            },
            // Attaching the form data
            data: {newPassword: password,
            },
          })
          .then((response) => {
            
            history.push("/login")
          });
        }
        

    return (
        <section className="hero has-background-grey-light is-fullheight is-fullwidth division2" >
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-4-desktop">
                            <form className="box">
                                <a href="/login">
                                    <i className="bi bi-arrow-left"></i>
                                </a>
                                <p className="has-text-centered text-danger"></p>
                                <div className="field mt-5">
                                    <label className="label">New Password</label>
                                    <div className="controls">
                                        <input type="password" className="input" placeholder="Username" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    </div>
                                </div>
                                <div className="field mt-5">
                                    <label className="label">Confirm New Password</label>
                                    <div className="controls">
                                        <input type="password" className="input" placeholder="******" value={confirmpassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                                    </div>
                                </div>
                                <div className="field mt-5">
                                    <button className="button is-success is-fullwidth btnInfo " onClick={recover} disabled={!(password==confirmpassword && password!='')} >Reset Password</button>
                                </div>
                                
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ResetPassword;
