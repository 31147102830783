import React, { Component} from "react";
import UploadService from "../services/upload.service"
import Download from "./FormulariosServicios.js/Download";
import BotonDarBaja from "./FormulariosServicios.js/botonDarBaja";
import axios from 'axios';
import { ENDPOINT_NODE, ENDPOINT_PYTHON, ENDPOINT_YOLO , ENDPOINT_SRC} from "../backend";
import DragArea from "./DragArea/dragArea";
import DragAreaEye from "./DragArea/DragAreaEye";
import DragAreaSrc from "./DragArea/dragAreaSrc";

export default class UploadFiles extends Component {
    constructor(props) {
      super(props);
      this.selectFile = this.selectFile.bind(this);
      this.upload = this.upload.bind(this);
      this.servicio = this.props.servicio.servicio;
      this.id_usuario = this.props.servicio.id_cliente;
      this.email = this.props.servicio.email;
      this.ImageSelectedPrevious = []
      this.state = {
        selectedFiles: undefined,
        currentFile: undefined,
        base64: undefined,
        progress: 0,
        message: "",
        data: "",
        darmeBaja: false,
        previa: undefined,  
        fileInfos: undefined,
        activate: false,
        finish:false
      };
      this.access_token= "";
      this.msg="";
      this.mensaje="";
      this.files = [];
      this.files64=[];

    }
    addImagen(image){
      this.ImageSelectedPrevious = this.ImageSelectedPrevious.concat(image)
    }

    selectFile(event) {
      let arrayAuxiliar=[];
      
      Array.from(event.target.files).forEach(archivos=>{
        if (this.servicio == 3) {
          this.files.push(archivos)
        }
        
          var reader = new FileReader();
          reader.readAsDataURL(archivos);
          reader.onload=function(){
              
              var base64 = reader.result;
              arrayAuxiliar.push(base64);

          }
          
         
      })
      this.tenerToken();
      this.setState({
        selectedFiles: event.target.files,
        base64: arrayAuxiliar,
        fileInfos: arrayAuxiliar
      });
      var aux = []
      Array.from(this.files).forEach(archivos=>{
          var reader = new FileReader();
          reader.readAsDataURL(archivos);
          reader.onload=function(){              
              var preview = reader.result;
              aux.push(preview);            
          }
      })      
      this.setState({
        previa: aux
      });
    }
    tenerToken(){
      if (this.servicio==1){
        this.EndPoint = ENDPOINT_PYTHON+"/get-token"
      }else if (this.servicio ==2 ){
        this.EndPoint = ENDPOINT_SRC+"/get-token"
      }
      else if (this.servicio ==3 ) {
        this.EndPoint = ENDPOINT_YOLO+"/get-token"
      }

      let formData = new FormData();
      formData.append('username', "cristian@santabros.com.ar");
      formData.append('password', "carlos");
      axios({
          // Endpoint to send files
          url: this.EndPoint,
          method: "POST",
          // Attaching the form data
          data: formData,
        })
        .then((response) => {
          this.token = response.data.access_token;
        });
      }
    
    async guardarNombreImage(id_client, id_servicio, name,jobid) {
        
      try {
          await axios.post(ENDPOINT_NODE+'/register-image', {
              id_client: id_client,
              id_service: id_servicio,
              name: name,
              jobid:jobid,
          });
      } catch (error) {
          if (error.response) {
              this.msg = error.response.data.msg;
          }
      }
  }

    upload() {
      if (this.servicio == "1") {
        this.EndPoint = ENDPOINT_PYTHON+"/api-send-images"
        let currentFile = this.state.selectedFiles[0];
        let nameSinExtension = currentFile.name.split('.');
        let sinExtension = nameSinExtension[0];
        let name = sinExtension;
        let base64 = this.state.base64[0];        
        this.mensaje="Tu mensaje esta siendo procesada";
        
      this.setState({
        progress: 0,
        currentFile: currentFile,
        base64: base64,
        activate:true
      });
      // Esta es el nombre de la respuesta de la consulta token
      this.setState({
        progress: Math.round(100/2),

      });
      
      let formData = new FormData();
      formData.append("name", name);
      formData.append("image", base64);
      formData.append("username", this.email)
      axios({
        // Endpoint to send files
        // url: ENDPOINT_PYTHON+"/upload64",
        url: this.EndPoint,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": "Bearer " + this.token,
        },
        // Attaching the form data
        data: formData,
      })
      .then((response) => {
        this.setState({
          progress: Math.round(100),
          finish:true
        });
        // sumarCantidad(this.id_usuario,this.servicio);
        
        // this.guardarNombreImage( this.id_usuario ,this.servicio,currentFile.name,response.data.Jobid)
      })
        .catch(() => {
          this.setState({
            progress: 0,
            message: "Could not upload the file!",
            currentFile: undefined,
          });
        });
  
      this.setState({
        selectedFiles: undefined,
      });

      } else if (this.servicio == "2") {
        this.EndPoint = ENDPOINT_SRC + "/api-send-images"
        let currentFile = this.state.selectedFiles[0];
        let nameSinExtension = currentFile.name.split('.');
        let sinExtension = nameSinExtension[0];
        let name = sinExtension;
        let base64 = this.state.base64[0];        
        this.mensaje="Tu mensaje esta siendo procesada";
        
      this.setState({
        progress: 0,
        currentFile: currentFile,
        base64: base64
      });
      // Esta es el nombre de la respuesta de la consulta token
      this.setState({
        progress: Math.round(100/2),

      });
      
      let formData = new FormData();
      formData.append("name", name);
      formData.append("image", base64);
      formData.append("username", this.email)
      axios({
        // Endpoint to send files
        // url: ENDPOINT_PYTHON+"/upload64",
        url: this.EndPoint,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": "Bearer " + this.token,
        },
        // Attaching the form data
        data: formData,
      })
      .then((response) => {
        this.setState({
          progress: Math.round(100),

        });
        // sumarCantidad(this.id_usuario,this.servicio);
        
        // this.guardarNombreImage( this.id_usuario ,this.servicio,currentFile.name,response.data.Jobid)
      })
        .catch(() => {
          this.setState({
            progress: 0,
            message: "Could not upload the file!",
            currentFile: undefined,
          });
        });
  
      this.setState({
        selectedFiles: undefined,
      });
      } else if (this.servicio == "3") {
        this.EndPoint = ENDPOINT_YOLO+"/api-send-images"
        let currentFile = this.state.selectedFiles;
        this.mensaje="Tu mensaje esta siendo procesada";
        this.setState({
          progress: 0,
          currentFile: currentFile,
          // base64: base64
        });
        // Esta es el nombre de la respuesta de la consulta token
        this.setState({
          progress: Math.round(100/2),

        });
        let formData = new FormData();
        for (let i= 0 ; i<currentFile.length; i++){
          formData.append("image", currentFile[i]);
        }
        formData.append("descargar_imagen", "True");
        formData.append("username", this.email)
        formData.append("id_user",this.id_usuario)
        axios({
          url: this.EndPoint,
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": "Bearer " + this.token,
          },
          // Attaching the form data
          data: formData,
        })
        .then((response) => {
          this.setState({
            progress: Math.round(100),

          });
          // sumarCantidad(this.id_usuario,this.servicio);
          
          // this.guardarNombreImage( this.id_usuario ,this.servicio,currentFile.name,response.data.Jobid)
        })
          .catch(() => {
            this.setState({
              progress: 0,
              message: "Could not upload the file!",
              currentFile: undefined,
            });
          });
    
        this.setState({
          selectedFiles: undefined,
        });
      } else if (this.servicio == "4") {
        this.EndPoint = "/url4"
      }
      const sumarCantidad = async (id_usuario,id_servicio) => {
        
        try {
            await axios.post(ENDPOINT_NODE+'/update-sum-cantimage', {
                id_user: id_usuario,
                id_service: id_servicio,
            });
        } catch (error) {
            if (error.response) {
                this.msg = error.response.data.msg;
            }
        }
    }
        
    }
    render() {
      const {
        selectedFiles,
        currentFile,
        progress,
        message,
        fileInfos,
        data,
        darmeBaja,
        activate,
        finish
      } = this.state;
      
      
      return (
        <div >
          <div className="container">
            <div className="row">
              <div className="col-12">
                
                {this.servicio == 1 ? 
                <>
                <DragAreaEye email={this.email} id={this.id_usuario}></DragAreaEye>
                {/* <label className="btn btn-default">
                <input type="file"  onChange={this.selectFile} /> 
                </label> */}
                </>
                
                
                :
                <div >
                </div>              
                  
                }
                {this.servicio == 2 ? 
                <>
                  <DragAreaSrc email={this.email} id={this.id_usuario}></DragAreaSrc>
                  {/* <label className="btn btn-default">
                  <input type="file"  onChange={this.selectFile} /> 
                  </label> */}
                </>
                  
                :
                <div >
                  {/* <input className="file-upload-input" type="file" multiple onChange={this.selectFile} />
                  <div className="text-information my-3">
                    <h3 className="h3 text-white">Drag and drop a file or select add Image</h3>
                    <h4 className="h4 text-white" >Number of files: {this.files.length} - {this.state.fileInfos}</h4>
                  </div> */}
                </div>              
                  
                }
                {/* <div className="row mt-5 ">
        {this.ImageSelectedPrevious.map((imagen, index) => (
            <div key={index} className="text-center col-2">
            <img 
              src={imagen}
              alt=""
              height="100px"
              width="150px"
              className="imagenredonda"
            />
            <div>
            <button  className="my-3 btn btn-danger">REMOVE </button>
            </div>
          </div>
                    ))}

        </div> */}
                
              </div>
              {this.servicio==3 ? <DragArea email={this.email} id={this.id_usuario} /> :
              <></>}
            
          </div>      
  
          
          
          {/* <div className="alert alert-light" role="alert">
            {message}
          </div> */}
            <div className="container">
            <div className="card">
            
            {(darmeBaja && (this.servicio=="1") && (<div className="bg-black text-white">
              <div className="card-header bg-white text-black">List of Images</div>
              <ul className="list-group list-group-flush bg-black">
                      {fileInfos &&
                        fileInfos.map((file, index) => (
                          <li className="list-group-item" key={index}>
                            <a href={file.url}>{file.name}</a>
                          </li>
                          
                        ))}
                      </ul>
                    <div className="row my-6 ">
                    <div className="col-12 text-center">
                          {this.mensaje}
                    </div>
                      </div>
                      
          
            </div>
      
    )) || (darmeBaja && !(this.servicio == "1") && !(this.servicio == "3") && (<div className="bg-black text-white">
              <div className="card-header bg-white text-black">List of Images</div>
              <ul className="list-group list-group-flush">
                      {fileInfos &&
                        fileInfos.map((file, index) => (
                          <li className="list-group-item" key={index}>
                            <a href={file.url}>{file.name}</a>
                          </li>
                          
                        ))}
                      </ul>
                      <div className="row my-5">
                          <div className="col-4">
                            
                          </div>
                          <div className="col-4">
                            <div className="text-center">
                      
                              <img src={data} width="448" height="84" alt="logo"></img>
                            </div>
                          </div>
                          <div className="col-4"> 

                          </div>
                      </div>
                      
          
            </div>
      
    ))
    || (darmeBaja && (this.servicio == "3") && (<div className="bg-black text-white">
              <div className="card-header bg-white text-black">List of Images</div>
              <ul className="list-group list-group-flush">
                      {fileInfos &&
                        fileInfos.map((file, index) => (
                          <li className="list-group-item" key={index}>
                            <a href={file.url}>{file.name}</a>
                          </li>
                          
                        ))}
                      </ul>
                      <div className="row my-6 ">
                          {/* <div className="col-6">
                            <div className="text-center">
                      
                              <img src={data[1]} width="448" height="84" alt="logo"></img>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="text-center">

                              <img src={data[0]} width="448" height="84" alt="logo"></img>
                            </div>
                          </div> */}
                      </div>
                      
          
            </div>
      
    )) || (!darmeBaja && (<div>
      {/* <div className="bg-black text-white upload text-center" >
    <i class="bi bi-upload logoUpload"></i>
    </div> */}
      
      </div>))}
            
          </div>
          
            </div>
          
          {/* <div>
            <Download base64={this.arrayAuxiliar}></Download>
          </div> */}
          {/* {this.servicio == 1 || this.servicio == 2 ? 
          <>
          {activate && (
        <div class="progress2">
            <div class="progress-bar2" style={{ width: progress + "%" }}>
                <span class="progress-bar-text2">{progress}%</span>
            </div>
        </div>
          )}
          {finish && (<div>
            <h4 className="h4 text-black">
              
            You can view your image in <a href="/image"> Images</a>
            </h4>
          </div>)

          }
          <div className="row">
            <div className="col-10 text-left mt-5">
              <a href="/services">
                <button className="btn btn-primary btn-lg ml-2 mb-4">Return</button>
              </a>
            </div>
            
            <div className="col-2 ">
              <div className=" my-5 " >
              <div className="text-center">
              <button
                  className="btn btn-success"
                  disabled={!selectedFiles}
                  onClick={this.upload}
                >
                  Start Audit
                </button>
              </div>
                
              </div>
            </div>
          </div>
          </>
          
          :<></>} */}
          </div>
          
          
        </div>
      );
    }
  }