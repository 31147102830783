import React , { useState, useEffect }from 'react';
import UploadFiles from '../Upload';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { useHistory } from 'react-router-dom';
import { ENDPOINT_NODE } from '../../backend';

const Servicio4 = () => {

    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const history = useHistory();
    
    const[id_user,setId_user] = useState()

    useEffect(() => {
        refreshToken();
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get(ENDPOINT_NODE+'/token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setId_user(decoded.userId)
            setName(decoded.name);
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                history.push("/");
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(ENDPOINT_NODE+'/token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
    const usuario = {
        servicio: 4,
        id_cliente: id_user

    }
    return (
        <div className='my-5 container height' >
            <div className='card '>
                <h2 className='text-black h2 text-center my-3'>Con nuestro servicio de control de entrada </h2>
                <h5 className='my-3 text-black h5  text-center'>Disfruta de este servicio que te trae nuestra compañia para poder hacer un conteo de las personas que entran a tu local</h5>
                <UploadFiles servicio={usuario}></UploadFiles>
            </div>
            
        </div>
        
    );
}

export default Servicio4;
