import React, {useState} from 'react'
import logo2 from './../assets/deepgazeweb1.png';
import { useHistory } from 'react-router-dom';
import '../../src/assets/css/menu.css';
import MenuButton from './menu/menuButton';
import Navbar2 from './menu/navbarout';
import { HeaderWrapper } from '../assets/css/header';
const Navbarout = () => {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };
    const history = useHistory();
    const login= ()=> {
        history.push("/login");
    }
    const register= ()=> {
        history.push("/register");
    }
    
    
    return (
        <HeaderWrapper>
            <Navbar2 open={open} />
            <MenuButton open={open} handleClick={handleClick} />
        </HeaderWrapper>
        //  <nav className="navbar navbar-dark bg-dark  navbar-expand-sm" >
        //     <div className="container">
        //         <div className="navbar-brand ">
        //             <a className="navbar-item" href="#" >
        //                 <img src={logo2} width="112" height="28" alt="logo" />
        //             </a>

        //             <button className='navbar-toggler' type='button' data-bs-toggle="collapse" data-bs-target="#opciones">
                   
        //             </button>
                    
        //         </div>
        //         <div  className="navbar-menu collapse navbar-collapse" id="opciones" >
        //             <div className="navbar-start">
                        
        //             </div>
                   

        //             <div className="navbar-end" id="opciones">
        //                 <div className="navbar-item" >
        //                     <div className="buttons">
        //                         <button onClick={login} className="button is-light text-bold">
        //                             Login
        //                         </button>
        //                         <button onClick={register} className="button is-light">
        //                             Register
        //                         </button>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
           
        // </nav> 
        
    )
}

export default Navbarout
