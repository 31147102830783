import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "bulma/css/bulma.css";
import axios from "axios";
import 'react-tooltip/dist/react-tooltip.css'
import Spinner from './components/hooks/spinners';

axios.defaults.withCredentials = true;

ReactDOM.render(
  <React.StrictMode>
  <div className='division'>
    <App />
    
  </div>
    
  </React.StrictMode>,
  document.getElementById('root')
);

