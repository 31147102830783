import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import {
  ENDPOINT_PYTHON,
  ENDPOINT_NODE,
  ENDPOINT_YOLO,
  ENDPOINT_SRC,
  ENDPOINT_PDETECTOR,
} from "../../backend";
import { getDefaultNormalizer } from "@testing-library/react";

const CredencialesServices = (props) => {
  const [servicio, setServicio] = useState(props.servicio);
  const [email, setEmail] = useState(props.email);
  const [password_actual, setPassword_actual] = useState("");
  const [password_actual_verifi, setPassword_actual_verifi] = useState("");
  const [password, setPassword] = useState("");
  const [confirpassword, setConfirPassword] = useState("");
  const [ip_address, setip_address] = useState("");
  const [ip_address_new, setip_address_new] = useState("");
  const [msg, setMsg] = useState("");
  const history = useHistory();
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");
  const [id_user, setId_user] = useState();
  const [edit, setEdit] = useState(false);
  const [edit_password, setEdit_password] = useState(false);
  const [token_api, setToken_api] = useState(props.tokenApi);

  useEffect(() => {
    refreshToken();
    // Obtener_token_api();
    Obtener_ip_address(props.email, props.tokenApi);
  }, []);

  // const Obtener_token_api = () => {
  //     try {
  //         let formData = new FormData();
  //         formData.append('username', "cristian@santabros.com.ar");
  //         formData.append('password', "carlos");
  //         axios({
  //           url: ENDPOINT_YOLO+'/get-token',
  //           method: "POST",
  //           data: formData,
  //         })
  //         .then((response) => {
  //             setToken_api(response.data.accessToken)
  //             Obtener_ip_address(props.email,response.data.accessToken);
  //         })
  //     } catch (error) {
  //         if (error.response) {
  //             setMsg(error.response.data.msg);
  //         }
  //     }

  // }
  const Obtener_ip_address = (username, tok) => {
    var Endpoint = "";
    if (servicio == 1) {
      Endpoint = ENDPOINT_PYTHON+ "/get-ipaddress-usertoken/"  + username;
    } else if (servicio == 2) {
      Endpoint = ENDPOINT_SRC + "/get-ipaddress-usertoken/"  + username;
    } else if (servicio == 3) {
      Endpoint = ENDPOINT_YOLO + "/get-ipaddress-usertoken/"  + username;
    } else if (servicio == 4){
      Endpoint = ENDPOINT_PYTHON+ "/get-ipaddress-usertoken-yolo/"  + username;
    } else if (servicio == 5){
      Endpoint = ENDPOINT_PDETECTOR+ "/get-ipaddress-usertoken/"  + username;
    }

    try {
      axios({
        url: Endpoint,
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + tok,
        },
      }).then((response) => {
        setip_address(response.data.ip_address);
        setPassword_actual(response.data.password)
        console.log("password actual")
        console.log(response.data.password)
      });
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  const refreshToken = async () => {
    try {
      const response = await axios.get(ENDPOINT_NODE + "/token");
      setToken(response.data.accessToken);
      const decoded = jwt_decode(response.data.accessToken);
      setId_user(decoded.userId);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (error) {
      if (error.response) {
        history.push("/");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(ENDPOINT_NODE + "/token");
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwt_decode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const Edit_ipAddress = async (e) => {
    e.preventDefault();
    var Endpoint = "";
    if (servicio == 1) {
      Endpoint = ENDPOINT_PYTHON+ "/change-ipaddress-usertoken";
    } else if (servicio == 2) {
      Endpoint = ENDPOINT_SRC+ "/change-ipaddress-usertoken";
    } else if (servicio == 3) {
      Endpoint = ENDPOINT_YOLO+ "/change-ipaddress-usertoken";
    } else if (servicio == 4) {
      Endpoint = ENDPOINT_PYTHON+ "/change-ipaddress-usertoken-yolo";
    } else if (servicio == 5) {
      Endpoint = ENDPOINT_PDETECTOR+ "/change-ipaddress-usertoken";
    }
    try {
      let formData = new FormData();
      formData.append("username", email);
      formData.append("ip_address", ip_address_new);
      axios({
        url: Endpoint ,
        method: "POST",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token_api,
        },
      }).then((response) => {
        window.location.reload();
      });
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  const changePassword = (e) => {
    e.preventDefault();
    var Endpoint = "";
    if (props.servicio == 1) {
      Endpoint = ENDPOINT_PYTHON+'/change-password-token';
    } else if (props.servicio == 2) {
      Endpoint = ENDPOINT_SRC+'/change-password-token';
    } else if (props.servicio == 3) {
      Endpoint = ENDPOINT_YOLO+'/change-password-token';
    }else if (props.servicio == 4) {
      Endpoint = ENDPOINT_PYTHON+'/change-password-token-yolo';
    } else if (props.servicio == 5) {
      Endpoint = ENDPOINT_PDETECTOR+'/change-password-token';
    }
    
      
      setMsg("");
      try {
          let formData = new FormData();
          formData.append("username", email);
          formData.append("password", password)
          axios({
            url: Endpoint,
            method: "POST",
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
              "Authorization": "Bearer " + token_api,
            },
          })
          .then((response) => {
            update_password_node(email, password, servicio);
            window.location.reload()

          })
      } catch (error) {
          if (error.response) {
              setMsg(error.response.data.msg);
          }
      }
    
  };
  const edit_ip = () => {
    setEdit(true);
    setEdit_password(false);
  };
  const editPassword = () => {
    setEdit(false);
    setEdit_password(true);
  };
  const update_password_node = async (email_user, password, id_service) => {
    await axios.post(ENDPOINT_NODE + "/update-password-token", {
      username: email_user,
      password: password,
      id_service: id_service,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      }});
  };
  return (
    <div>
      <div className="hero">
        <div className="container">
          <div className="columns is-centered">
            <div className="column">
              <form className="box">
                <p className="has-text-centered text-danger">{msg}</p>
                <div className="field mt-5">
                  <label className="label">Email</label>
                  <div className="controls">
                    <input
                      type="text"
                      className="input"
                      placeholder="Username"
                      disabled="true"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="field mt-5 mb-5">
                  <label className="label">
                    URL Destination{" "}
                    {props.servicio == 1
                      ? "Eyectracking"
                      : props.servicio == 2
                      ? "Rice Field"
                      : props.servicio ==3
                      ? "Object Detection V2"
                      : props.servicio ==4
                      ? "Object DetectionV1"
                      : "Product Detector"}
                  </label>
                  <div className="controls">
                    <input
                      type="text"
                      className="input"
                      placeholder="URL Address"
                      disabled="true"
                      value={ip_address}
                      onChange={(e) => setip_address(e.target.value)}
                    />
                    <small
                      className="float-right mx-2 text-primary mt-2"
                      onClick={edit_ip}
                      data-title="Change api address"
                    >
                      Edit URL
                      <i className="bi bi-question-circle ml-1" data-title="Here you can modify your information of Eyecktracking"></i>
                    </small>
                    <small
                      className="float-right mx-2 text-primary mt-2"
                      onClick={editPassword}
                    >
                      Change Password Service
                      <i className="bi bi-question-circle ml-1" data-title="Here you can modify your information of Eyecktracking"></i>
                    </small>
                  </div>
                </div>
                      <br></br>
                {edit && (
                  <section>
                    <div className="field mt-5">
                      <label className="label">New URL Destinations</label>
                      <div className="controls">
                        <input
                          type="text"
                          className="input"
                          placeholder=" New URL Address"
                          value={ip_address_new}
                          onChange={(e) => setip_address_new(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="field mt-5">
                      <button
                        className="button is-success is-fullwidth btnInfo "
                        onClick={Edit_ipAddress}
                      >
                        Edit
                      </button>
                    </div>
                  </section>
                )}
                {edit_password && (
                  <section>
                    <div className="field mt-5">
                      <label className="label">New Token Password</label>
                      <div className="controls">
                        <input
                          type="password"
                          className="input"
                          placeholder="******"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                    </div>
                    
                    <div className="field mt-5">
                      <label className="label">Confirm Token Password</label>
                      <div className="controls">
                        <input
                          type="password"
                          className="input"
                          placeholder="******"
                          value={confirpassword}
                          onChange={(e) => setConfirPassword(e.target.value)}
                        />
                      </div>
                    </div>
                    <small>{msg}</small>
                    <div className="field mt-5">
                      <button
                        className="button is-success is-fullwidth btnInfo "
                        disabled={
                          !(password == confirpassword && password != "")
                        }
                        onClick={changePassword}
                      >
                        Change Password
                      </button>
                    </div>
                  </section>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CredencialesServices;
