import styled from "styled-components";

export const HeaderWrapper = styled.header`
  ${'' /* margin-top: -1.25rem; */}
  ${'' /* height: 15vh; */}
  display: flex;
  position:sticky;
  top: 0; 
  z-index:10;
  justify-content: space-between;
  align-items: center;
`;