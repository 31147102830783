import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import "../../../../assets/css/styles.css";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { ENDPOINT_YOLO, ENDPOINT_NODE } from "../../../../backend";
import Select from "react-select";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  Media,
  Table,
  Row,
  Progress,
  Button,
} from "reactstrap";
const PaginationEye = (props) => {
  var ayuda = [];
  const items = props.informacion;
  for (let i = 0; i < items.length; i++) {
    ayuda.push(i);
  }

  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setitemsPerPage] = useState(5);
  const history = useHistory();
  const [token, setToken] = useState("");
  const [busqueda, setBusqueda] = useState("");
  const [tabladFiltrado, setTablaFiltrado] = useState(props.informacion);
  const [valores, setValores] = useState(props.informacion);
  const [valoresaux, setValoresAux] = useState(props.ayuda);
  const [expire, setExpire] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [cambio, setCambio] = useState(true);
  React.useEffect(() => {
    filtrarArray(props.informacion);
  }, []);
  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      width: '80px', // Ajusta el ancho según tus necesidades
    }),
  };
  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setitemsPerPage(selectedOption.value);
    filtrarArray(props.informacion);
  };
  const originalOptions = [
    { value: 5, label: "5" },
    { value: 10, label: "10" },
    { value: 25, label: "25" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
    { value: 250, label: "250" },
  ];
  const options = originalOptions.map((option) => ({
    value: option.value,
    label: option.label,
  }));
  const filtrarArray = (array) => {
    var ayuda2 = [];
    var items2 = array;
    for (let i = 0; i < items2.length; i++) {
      ayuda2.push(i);
    }
    setValores(items2);
    setValoresAux(ayuda2);

    setCurrentItems(ayuda2.slice(itemOffset, itemOffset + itemsPerPage));
    setPageCount(Math.ceil(items2.length / itemsPerPage));
  };
  const handleChange = (e) => {
    setBusqueda(e.target.value);
    filtrar(e.target.value);
  };
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(valoresaux.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(valores.length / itemsPerPage));
  }, [itemOffset, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % valores.length;

    setItemOffset(newOffset);
  };
  const filtrar = (terminoBusqueda) => {
    var resultadosBusqueda = tabladFiltrado.filter((elemento) => {
      if (
        (elemento.servicio != null &&
          elemento.servicio
            .toString()
            .includes(terminoBusqueda.toLowerCase())) ||
        (elemento.user_id != null &&
          elemento.user_id
            .toString()
            .includes(terminoBusqueda.toLowerCase())) 
            ||
        (elemento.name != null &&
          elemento.name
            .toString()
            .includes(terminoBusqueda.toLowerCase())) 
            ||
        (elemento.email != null &&
          elemento.email
            .toString()
            .includes(terminoBusqueda.toLowerCase())) 
        //     ||
        // (elemento.id != null &&
        //   elemento.id.toString().includes(terminoBusqueda.toLowerCase()))
      ) {
        return elemento;
      }
    });

    filtrarArray(resultadosBusqueda);
  };
  const activarUser = (user_id,servicio,id_email)=>{
    props.send()
    // props.baja(user_id,servicio)
  }

  return (
    <>
      <Row>
        <div className="col">
          <Card className="shadow">
            <div className="row">
              <CardHeader className="border-0">
                <div className="col-6"></div>
                <div className="col-6">
                  <div className="input-group rounded">
                    <input
                      type="search"
                      className="form-control "
                      onChange={handleChange}
                      value={busqueda}
                      placeholder="Search"
                      aria-label="Search"
                      aria-describedby="search-addon"
                    />
                  </div>
                </div>
              </CardHeader>
            </div>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Users</th>
                  <th scope="col">Email</th>
                  <th scope="col">Service</th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {valores.length != 0 &&
                  currentItems &&
                  currentItems.map((item, index) => (
                    <tr key={index}>
                      <th scope="row">
                        <Media className="align-items-center">
                          <Media>
                            <span className="mb-0 text-sm">
                              {valores[item].name}
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>{valores[item].email}</td>
                      <td>
                        <Badge color="" className="badge-dot mr-4 ">
                          <i className="bg-warning" />
                          <span className="text-black">
                            {valores[item].servicio}
                          </span>
                        </Badge>
                      </td>
                      <td></td>
                      
                      <td>
                      <Button
                          color="success"
                          disabled={!cambio}
                          onClick={() =>
                            props.alta(
                              valores[item].user_id,
                              valores[item].servicio,
                              valores[item].email
                            )
                          }
                        >
                          <i class="bi bi-check-circle" data-title2="Accept user"></i>
                        </Button>
                        <Button
                          color="danger"
                          disabled={!cambio}
                          onClick={() =>
                            props.baja(
                              valores[item].user_id,
                              valores[item].servicio,
                              valores[item].email
                            )
                          }
                        >
                          <i class="bi bi-x-square" data-title2="No Accept user"></i>
                        </Button>
                      </td>
                    </tr>
                  ))}
                {valores.length == 0 && (
                  <tr className="h1 text-center">
                    <td>Does not have user actived</td>
                  </tr>
                )}
              </tbody>
            </Table>
            <CardFooter className="text-center">
              <div className="text-center text-white alinear my-5">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="< "
                  renderOnZeroPageCount={null}
                  containerClassName="pagination justify-content-center"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  activeClassName="active"
                />
              </div>
            </CardFooter>
            <div className="row">
            <div className="col-4 ml-5 mb-3 d-flex align-items-center">
            <label className="text-dark mr-2" >Number of rows: </label> 
            <span>
            <Select options={options} menuPlacement="top" 
            placeholder="" onChange={handleOptionChange}
            styles={customStyles} />
            </span>
                </div>
            </div>
          </Card>
        </div>
      </Row>

      <br></br>
      <br />
      <br />
    </>
  );
};

export default PaginationEye;
