import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import "../../../../assets/css/styles.css";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { ENDPOINT_YOLO, ENDPOINT_NODE, ENDPOINT_PYTHON, ENDPOINT_SRC, ENDPOINT_PDETECTOR, ENDPOINT_CLASSIFIER } from "../../../../backend";
// import Modal from "../../../../components/Modals/modal_userNew";
import Modal from 'react-modal';
import useModal from "../../../../components/hooks/modals";
import Select from "react-select";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  Media,
  Table,
  Row,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  PaginationItem,
  PaginationLink,
  Progress,
  Container,
  UncontrolledTooltip,
  Button,
} from "reactstrap";
import Pagination_yolo from "../../../../components/pagination_yolo";
import Pagination_src from "../../../../components/pagination_src";
import Pagination from "../../../../components/pagination";
import PaginationObjv1 from "../../../../components/pagination_objv1"
import PaginationPdetector from "../../../../components/pagination_detector"
import PaginationClassifier from "../../../../components/pagination_clasifiyer"
const PaginationYolo = (props) => {
  var ayuda = [];
  const items = props.informacion;
  for (let i = 0; i < items.length; i++) {
    ayuda.push(i);
  }
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setitemsPerPage] = useState(5);
  const [nameImage, setNameImage] = useState("");
  const [vista, setVista] = useState(3);
  const [newImage, setNewImage] = useState();
  const [itemsImage, setItemsImage] = useState();
  const [imagen, setImagen] = useState("");
  const history = useHistory();
  const [arrayitems, setArrayItems] = useState([]);
  const [arrayitemsVeces, setArrayItemsVeces] = useState([]);
  const [token, setToken] = useState("");
  const [busqueda, setBusqueda] = useState("");
  const [tabladFiltrado, setTablaFiltrado] = useState(props.informacion);
  const [valores, setValores] = useState(props.informacion);
  const [valoresaux, setValoresAux] = useState(props.ayuda);
  const [expire, setExpire] = useState("");
  const [usersAll, setUsersAll] = useState([]);
  const [cambio, setCambio] = useState(true);
  const [isOpen, openModal, closeModal] = useModal(false);
  const [msg, setMsg] = useState("");
  const [html, setHtml] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [ventana,setVentana] = useState(false)
  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      width: '80px', // Ajusta el ancho según tus necesidades
    }),
  };
  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setitemsPerPage(selectedOption.value);
    filtrarArray(props.informacion);
  };
  const originalOptions = [
    { value: 5, label: "5" },
    { value: 10, label: "10" },
    { value: 25, label: "25" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
    { value: 250, label: "250" },
  ];
  const options = originalOptions.map((option) => ({
    value: option.value,
    label: option.label,
  }));
  React.useEffect(() => {
    filtrarArray(props.informacion);
    getToken(107,1);
  }, []);

  const filtrarArray = (array) => {
    var ayuda2 = [];
    var items2 = array;
    for (let i = 0; i < items2.length; i++) {
      ayuda2.push(i);
    }
    setValores(items2);
    setValoresAux(ayuda2);

    setCurrentItems(ayuda2.slice(itemOffset, itemOffset + itemsPerPage));
    setPageCount(Math.ceil(items2.length / itemsPerPage));
  };
  const handleChange = (e) => {
    setBusqueda(e.target.value);
    filtrar(e.target.value);
  };
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(valoresaux.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(valores.length / itemsPerPage));
  }, [itemOffset, itemsPerPage]);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % valores.length;

    setItemOffset(newOffset);
  };
  const filtrar = (terminoBusqueda) => {
    var resultadosBusqueda = tabladFiltrado.filter((elemento) => {
      if (
        (elemento.servicio != null &&
          elemento.servicio
            .toString()
            .includes(terminoBusqueda.toLowerCase())) ||
        (elemento.name != null &&
          elemento.name
            .toString()
            .includes(terminoBusqueda.toLowerCase()))
            ||
        (elemento.email != null &&
          elemento.email
            .toString()
            .includes(terminoBusqueda.toLowerCase()))
        //      ||
        // (elemento.id != null &&
        //   elemento.id.toString().includes(terminoBusqueda.toLowerCase()))
      ) {
        return elemento;
      }
    });

    filtrarArray(resultadosBusqueda);
  };
  const  obtener_listado = async (token,endpoint,usuario,servicio) => {

    setHtml('')
    if (servicio == "object_v1") {
      try {
        await axios
          .get(endpoint + "/get-images-data-service-yolo/" + usuario, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + token,
            },
          })
          .then((response) => {
            var fecha = "";
            const items = response.data;
            for (let i = 0; i < items.length; i++) {
              fecha = items[i].created_at.split(" ");
              items[i].created_at = fecha[1] + "-" + fecha[2] + "-" + fecha[3] +" " +fecha[4].slice(0,5);
            }
            var ayuda2 = [];
            for (let i = 0; i < items.length; i++) {
              ayuda2.push(i);
            }
            if(endpoint == ENDPOINT_YOLO){
              setHtml(
                <Pagination_yolo informacion={items.reverse()} ayuda={ayuda2}>
                </Pagination_yolo>
              );
            }else if (endpoint == ENDPOINT_PYTHON && servicio == "eyetracking" ){
              setHtml(
                <Pagination informacion={items.reverse()} ayuda={ayuda2}>
                </Pagination>
              );
            }else if (endpoint == ENDPOINT_PYTHON && servicio == "object_v1" ){
              setHtml(
                <PaginationObjv1 informacion={items.reverse()} ayuda={ayuda2}>
                </PaginationObjv1>
              );
            }else if (endpoint == ENDPOINT_SRC){
              setHtml(
                <Pagination_src informacion={items.reverse()} ayuda={ayuda2}>
                </Pagination_src>
              );
            }else if (endpoint == ENDPOINT_PDETECTOR && servicio == "product_detector" ){
              setHtml(
                <PaginationPdetector informacion={items.reverse()} ayuda={ayuda2}>
                </PaginationPdetector>
              );
            }else if (endpoint == ENDPOINT_CLASSIFIER && servicio == "product_classifier" ){
              setHtml(
                <PaginationClassifier informacion={items.reverse()} ayuda={ayuda2}>
                </PaginationClassifier>
              );
            }
            
          });
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    } else {
      try {
        await axios
          .get(endpoint + "/get-images-data-service/" + usuario, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + token,
            },
          })
          .then((response) => {
            var fecha = "";
            const items = response.data;
            for (let i = 0; i < items.length; i++) {
              fecha = items[i].created_at.split(" ");
              items[i].created_at = fecha[1] + "-" + fecha[2] + "-" + fecha[3] +" " +fecha[4].slice(0,5);
            }
            var ayuda2 = [];
            for (let i = 0; i < items.length; i++) {
              ayuda2.push(i);
            }
            if(endpoint == ENDPOINT_YOLO){
              setHtml(
                <Pagination_yolo informacion={items.reverse()} ayuda={ayuda2}>
                </Pagination_yolo>
              );
            }else if (endpoint == ENDPOINT_PYTHON && servicio == "eyetracking" ){
              setHtml(
                <Pagination informacion={items.reverse()} ayuda={ayuda2}>
                </Pagination>
              );
            }else if (endpoint == ENDPOINT_PYTHON && servicio == "object_v1" ){
              setHtml(
                <PaginationObjv1 informacion={items.reverse()} ayuda={ayuda2}>
                </PaginationObjv1>
              );
            }else if (endpoint == ENDPOINT_SRC){
              setHtml(
                <Pagination_src informacion={items.reverse()} ayuda={ayuda2}>
                </Pagination_src>
              );
            }
            else if (endpoint == ENDPOINT_PDETECTOR && servicio == "product_detector" ){
              setHtml(
                <PaginationPdetector informacion={items.reverse()} ayuda={ayuda2}>
                </PaginationPdetector>
              );
            }else if (endpoint == ENDPOINT_CLASSIFIER && servicio == "product_classifier" ){
              setHtml(
                <PaginationClassifier informacion={items.reverse()} ayuda={ayuda2}>
                </PaginationClassifier>
              );
            }
            
          });
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    }
    setVentana(true)
  };
  const getToken= async(id_user,service)=>{
    try {
      const response = await axios.post(ENDPOINT_NODE+'/get-password-token', {
          id_user: id_user,
          id_service: service
      });
  } catch (error) {
      
      if (error.response) {
        // setMsg(error.response.data.msg);
      }
  }
}
  const registro = (e,usuario, servicio) => {
    
    console.log(servicio)
    let EndPoint = ''
    e.preventDefault();
    if (servicio == "eyetracking") {
      EndPoint = ENDPOINT_PYTHON;
    } else if (servicio == "rice_field") {
      EndPoint = ENDPOINT_SRC;
    } else if (servicio == "object_v2") {
      EndPoint = ENDPOINT_YOLO;
    } else if (servicio == "object_v1") {
      EndPoint = ENDPOINT_PYTHON;
    } else if (servicio == "product_detector") {
      EndPoint = ENDPOINT_PDETECTOR;
    }
    else if (servicio == "product_classifier") {
      EndPoint = ENDPOINT_CLASSIFIER;
    }
    openModal();
    if (servicio == "object_v1"){
      let formData = new FormData();
    formData.append("username", "cristian@santabros.com.ar");
    formData.append("password", "carlos");
    axios({
      // Endpoint to send files
      
      url: EndPoint + "/get-token-yolo",
      method: "POST",
      // Attaching the form data
      data: formData,
    }).then((response) => {
      obtener_listado(response.data.access_token,EndPoint, usuario,servicio);
    });
    } else {
      let formData = new FormData();
    formData.append("username", "cristian@santabros.com.ar");
    formData.append("password", "carlos");
    axios({
      // Endpoint to send files
      
      url: EndPoint + "/get-token",
      method: "POST",
      // Attaching the form data
      data: formData,
    }).then((response) => {
      obtener_listado(response.data.access_token,EndPoint, usuario,servicio);
    });
    }
    
  };
  
  return (
    <>
      <Row>
        <div className="col">
        {ventana && (
          <button className="btn" onClick={()=>setVentana(false)}>Go back to List</button>
        )}
        {!ventana && (
          <Card className="shadow">
            <div className="row">
              <CardHeader className="border-0">
                <div className="col-6"></div>
                <div className="col-6">
                  <div className="input-group rounded">
                    <input
                      type="search"
                      className="form-control "
                      onChange={handleChange}
                      value={busqueda}
                      placeholder="Search"
                      aria-label="Search"
                      aria-describedby="search-addon"
                    />
                  </div>
                </div>
              </CardHeader>
            </div>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Users</th>
                  <th scope="col">Email</th>
                  <th scope="col">Service</th>
                  <th scope="col"></th>
                  <th scope="col">View User</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {valores.length != 0 &&
                  currentItems &&
                  currentItems.map((item, index) => (
                    <tr key={index}>
                      <th scope="row">
                        <Media className="align-items-center">
                          <Media>
                            <span className="mb-0 text-sm">
                              {valores[item].name}
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>{valores[item].email}</td>
                      <td>
                        <Badge color="" className="badge-dot mr-4 ">
                          <i className="bg-warning" />
                          <span className="text-black">
                            {valores[item].servicio}
                          </span>
                        </Badge>
                      </td>
                      <td>
                      {/* {valores[item].cant_image} */}
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">
                            <button
                              data-title2="View image"
                              className="bg-white bordeblack"
                              onClick={(e) =>
                                registro(
                                  e,
                                  valores[item].user_id,
                                  valores[item].servicio
                                )
                              }
                            >
                              <i className="bi bi-eye "></i>
                            </button>
                          </span>
                        </div>
                      </td>
                      <td>
                        <Button
                          color="danger"
                          disabled={!cambio}
                          onClick={() =>
                            props.baja(
                              valores[item].user_id,
                                  valores[item].servicio,
                                  valores[item].email
                            )
                          }
                        >
                          <i class="bi bi-x-square" data-title2="Unsubscribe user"></i>
                        </Button>
                      </td>
                    </tr>
                  ))}
                {valores.length == 0 && (
                  <tr className="h1 text-center">
                    <td>Does not have user active</td>
                  </tr>
                )}
              </tbody>
            </Table>
            <CardFooter className="text-center">
              <div className="text-center text-white alinear my-5">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="< "
                  renderOnZeroPageCount={null}
                  containerClassName="pagination justify-content-center"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  activeClassName="active"
                />
              </div>
            </CardFooter>
            <div className="row">
            <div className="col-4 ml-5 mb-3 d-flex align-items-center">
            <label className="text-dark mr-2" >Number of rows: </label> 
            <span>
            <Select options={options} menuPlacement="top" 
            placeholder="" onChange={handleOptionChange}
            styles={customStyles} />
            </span>
                </div>
            </div>
          </Card>
        )}
          {ventana  && (
            <>{html}</>
          )}
        </div>
      </Row>

      <br></br>
      <br />
      <br />
      <div >
      {/* <Modal className="text-center modal-container-register" isOpen={isOpen} onRequestClose={closeModal}>
     
     {html}
     
   </Modal> */}
      </div>
      
    </>
  );
};

export default PaginationYolo;
