import React, { useState, useEffect } from "react";
import Modal from 'react-modal';
import axios from "axios";
import { useHistory } from "react-router-dom";
import { ENDPOINT_NODE } from '../backend';
import jwt_decode from "jwt-decode";

const Register = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confPassword, setConfPassword] = useState('');
    const [msg, setMsg] = useState('');
    const history = useHistory();
    const [token, setToken] = useState("");
    const [expire, setExpire] = useState('');
    const [id_user, setId_user] = useState();
    const [modalIsOpen, setModalIsOpen] = useState(false);


    const Register = async (e) => {
        e.preventDefault();
        try {
            await axios.post(ENDPOINT_NODE+'/users', {
                name: name,
                email: email,
                password: password,
                confPassword: confPassword,
                type: 0,
            }).then((response) => {;
                const user_id = response.data.user_id;
                //Recordar que esto tiene que estar en un for de todos los servicios
                // CreateClientes(user_id,1);
                // CreateClientes(user_id,2);
                // CreateClientes(user_id,3);
                createServices(user_id)
                CreateInfoCLiente(user_id,name)
        });
            setModalIsOpen(true)
            // history.push("/");
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    }
    const CreateClientes = async (id_usuario,id_servicio) => {
        
        try {
            await axios.post(ENDPOINT_NODE+'/register-client', {
                id_user: id_usuario,
                id_service: id_servicio,
                cant_image: 0,
                status: 0
            }, {
                headers: {
                  Authorization: `Bearer ${token}`,
                }});
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    }
    const createServices = async (user) => {
        try {
          await axios
            .post(
              ENDPOINT_NODE + "/register-user-service",
              {
                user_id: user,
              }
            )
            .then((response) => {
              console.log(response)
            });
        } catch (error) {
          if (error.response) {
            setMsg(error.response.data.msg);
          }
        }
      };
    const CreateInfoCLiente= async (id_usuario,usernam) => {
        
        try {
            await axios.post(ENDPOINT_NODE+'/register-user-info', {
                user_id: id_usuario,
                contact_name: usernam,
                phone_number: '',
                company_name: '',
                company_registration_number: '',
                country: '',
            });
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    }

    const openModal = () => {
        setModalIsOpen(true);
      }
    
      const closeModal = () => {
        setModalIsOpen(false);
        history.push("/login");
      }

    return (
        <section className="hero has-background-grey-light is-fullheight is-fullwidth division2" >
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-4-desktop">
                            <form onSubmit={Register} className="box">
                                <a href="/">
                                    <i className="bi bi-arrow-left" data-title="Home"></i>
                                </a>
                                <p className="has-text-centered text-warning">{msg}</p>
                                {/* <div className="field mt-5">
                                    <label className="label">Name Company</label>
                                    <div className="controls">
                                        <input type="text" className="input" placeholder="Name"
                                            value={name} onChange={(e) => setName(e.target.value)} />
                                    </div>
                                </div> */}
                                <div className="field mt-5">
                                    <label className="label">Name</label>
                                    <div className="controls">
                                        <input type="text" className="input" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)}/>
                                    </div>
                                </div>
                                <div className="field mt-5">
                                    <label className="label">Email</label>
                                    <div className="controls">
                                        <input type="text" className="input" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                </div>
                                <div className="field mt-5">
                                    <label className="label">Password</label>
                                    <div className="controls">
                                        <input type="password" className="input" placeholder="******" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    </div>
                                </div>
                                <div className="field mt-5">
                                    <label className="label">Confirm Password</label>
                                    <div className="controls">
                                        <input type="password" className="input" placeholder="******" value={confPassword} onChange={(e) => setConfPassword(e.target.value)} />
                                    </div>
                                </div>
                                <div className="field mt-5">
                                    <button className="button is-success is-fullwidth " data-title="Once registered you will not be able to change the email">Register</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-center container">
            {/* <button onClick={openModal}>Abrir Modal</button> */}
            <Modal 
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Ejemplo de Modal"
                className="text-center modal-container-register"
            ><div className="mt-5">
                <h1 className="h1 text-white">Successful registration</h1>
                <p className="text-white">The process has been carried out successfully.</p>
                <p className="text-white">We have sent you a welcome email.</p>
                <button className="btn btn-info mt-5" onClick={closeModal}>Ok</button>
                </div>
            </Modal>
            </div>
        </section>
    )
}

export default Register
