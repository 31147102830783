import { BrowserRouter, Route, Switch } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import AdquirirServicio from "./components/FormulariosServicios.js/AdquirirServicio";
import AdquirirServicio3 from "./components/FormulariosServicios.js/AdquirirServicio3";
import AdquirirServicio4 from "./components/FormulariosServicios.js/AdquirirServicio4";
import AdquirirServicio5 from "./components/FormulariosServicios.js/AdquirirServicio5";
import AdquirirServicio6 from "./components/FormulariosServicios.js/AdquirirServicio6";
import AdquirirServicio7 from "./components/FormulariosServicios.js/AdquirirServicio7";
import AdquirirServicio8 from "./components/FormulariosServicios.js/AdquiriServicio8";
import Servicio1 from "./components/FormulariosServicios.js/Servicio1";
import Servicio2 from "./components/FormulariosServicios.js/Servicio2";
import Servicio3 from "./components/FormulariosServicios.js/Servicio3";
import Servicio4 from "./components/FormulariosServicios.js/Servicio4";
import Servicio5 from "./components/FormulariosServicios.js/Servicio5";
import Servicio6 from "./components/FormulariosServicios.js/Servicio6";
import Servicio7 from "./components/FormulariosServicios.js/Servicio7";
import Servicio8 from "./components/FormulariosServicios.js/Servicio8";
import Servicio9 from "./components/FormulariosServicios.js/servicio9";
import Home from "./components/home";
import Login from "./components/Login";
import Navbar from "./components/Navbar";
import Register from "./components/Register";
import Services from "./components/Services";
import Imagen from "./components/Imagen";
import "../src/assets/css/styles.css";
import Estadisticas from "./components/estadisticas";
import LoginCms from "./cms/views/loginCms";
import Monitor from "./cms/views/components/monitor";
import Doc from "./components/documentation/doc";
import Profile from "./components/user/profile";
import Header from "./cms/views/components/headers";
import Header2 from "./cms/views/components/headersM";
import DashboardCMS from "./cms/views/components/dashboard";
import Enespera from "./components/FormulariosServicios.js/Enespera";
import Tables from "./cms/views/tables";
import AllImages from "./cms/views/components/allimagen";
import ResetPassword from "./components/password/resetPassword";
import PasswordIA from "./components/password/token_para_api/passwordIA";
import ErrorComponent from "./components/error/ErrorComponent";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import ContacUs from "./components/contacUs";
import UserAdmin from "./cms/views/components/userAdmin";
import VideoMetadataViewer from "./components/DragArea/dragmetaData";
function App() {
  const history = useHistory();

  // useEffect(() => {
  //   if (window.location.protocol === 'http:') {
  //     window.location.href = `https://${window.location.host}`;
  //   }
  // }, []);

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/prueba">
          <PasswordIA></PasswordIA>
        </Route>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/cms">
          <LoginCms></LoginCms>
        </Route>
        <Route exact path="/cms/tables">
          <Tables></Tables>
        </Route>
        <Route exact path="/cms/userAdmin">
          <UserAdmin></UserAdmin>
        </Route>
        <Route exact path="/cms/all-images">
          <AllImages></AllImages>
        </Route>
        <Route exact path="/cms/monitor">
          <Monitor></Monitor>
        </Route>
        <Route exact path="/cms/head-day">
          <Header></Header>
        </Route>
        <Route exact path="/cms/head-month">
          <Header2></Header2>
        </Route>
        <Route exact path="/cms/dashboard">
          <DashboardCMS></DashboardCMS>
        </Route>
        <Route path="/password-reset">
          <ResetPassword></ResetPassword>
        </Route>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route path="/register">
          <Register />
        </Route>
        <Route path="/services">
          <Services />
        </Route>
        {/* <Route path="/dashboard">
        
        <Dashboard/>
      </Route> */}
        <Route path="/contact-us">
          <Navbar />
          <ContacUs />
        </Route>
        <Route path="/docs">
          <Navbar />
          <Doc />
        </Route>
        <Route path="/profile">
          <Profile></Profile>
        </Route>
        <Route path="/image">
          <Imagen />
        </Route>
        <Route path="/statistics">
          <Navbar />
          <Estadisticas />
        </Route>
        <Route path="/eyectracking">
          <Navbar />
          <Servicio1></Servicio1>
        </Route>

        <Route path="/rice-field">
          <Navbar />
          <Servicio2></Servicio2>
        </Route>
        <Route path="/object-detection-v2">
          <Navbar />
          <Servicio3></Servicio3>
        </Route>
        <Route path="/servicio4">
          <Navbar />
          <Servicio4></Servicio4>
        </Route>
        <Route path="/meta-video">
          <Navbar />
          <VideoMetadataViewer></VideoMetadataViewer>
        </Route>
        <Route path="/app-video">
          <Navbar />
          <Servicio5></Servicio5>
        </Route>
        <Route path="/app-audio">
          <Navbar />
          <Servicio9></Servicio9>
        </Route>
        <Route path="/object-detection-v1">
          <Navbar />
          <Servicio6></Servicio6>
        </Route>
        <Route path="/product-detector">
          <Navbar />
          <Servicio7></Servicio7>
        </Route>
        <Route path="/product-classifier">
          <Navbar />
          <Servicio8></Servicio8>
        </Route>
        <Route path="/adquirir">
          <Navbar />
          <AdquirirServicio></AdquirirServicio>
        </Route>
        <Route path="/adquirir3">
          <Navbar />
          <AdquirirServicio3></AdquirirServicio3>
        </Route>
        <Route path="/adquirir4">
          <Navbar />
          <AdquirirServicio4></AdquirirServicio4>
        </Route>
        <Route path="/adquirir5">
          <Navbar />
          <AdquirirServicio5></AdquirirServicio5>
        </Route>
        <Route path="/adquirir6">
          <Navbar />
          <AdquirirServicio6></AdquirirServicio6>
        </Route>
        <Route path="/adquirir7">
          <Navbar />
          <AdquirirServicio7></AdquirirServicio7>
        </Route>
        <Route path="/adquirir8">
          <Navbar />
          <AdquirirServicio8></AdquirirServicio8>
        </Route>
        <Route path="/pendiente">
          <Enespera></Enespera>
        </Route>
        <Route path="*">
          <Navbar />
          <ErrorComponent></ErrorComponent>
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
