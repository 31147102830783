/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom";
import PaginationCms from "../../../components/pagination_cms";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import Alert from "../../../components/error/ErrorAlert";
import Dropdown from "../../../components/hooks/dropdown";
import {
  ENDPOINT_NODE,
  ENDPOINT_PYTHON,
  ENDPOINT_YOLO,
  ENDPOINT_SRC,
  ENDPOINT_PDETECTOR,
  ENDPOINT_VIDEO,
} from "../../../backend" ;
import Pagination_yolo_cms from "../../../components/pagination_yolo_cms";
import Pagination_src_cms from "../../../components/pagination_src_cms";
import PaginationObjv1Cms from "../../../components/pagination_objv1_cms";
import Pagination_clasifiyer_cms from "../../../components/pagination_clasifiyer_cms";
import Pagination_VideoCms from "../../../components/pagination_video_cms";
import Navbar from "../../../components/Navbar";
import Pagination_detector_cms from "../../../components/pagination_detector_cms";

const ImagenCheck = () => {
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");
  const [images, setImages] = useState([]);
  const [html, setHtml] = useState("");
  const history = useHistory();
  const [msg, setMsg] = useState("");
  const [tokenApi, setTokenApi] = useState("");
  const [tokenApiEye, setTokenApiEye] = useState("");
  const [tokenApidetector, setTokenApidetector] = useState("");
  const [tokenApiSRC, setTokenApiSRC] = useState("");
  const [tokenApiVideo, setTokenApiVideo] = useState("");
  const [user, setUser] = useState("");
  const [active1, setActive1] = useState(true);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [active6, setActive6] = useState(false);
  const [active7, setActive7] = useState(false);
  const [active8, setActive8] = useState(false);
  const [active9, setActive9] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [realoadValor,setReloadValor]=useState(0);
  var identificadorTiempoDeEspera = "";
  var identificadorTiempoDeEspera2 = "";
  var identificadorTiempoDeEspera3 = "";
  
  const location = useLocation();
  const options = [
    'one', 'two', 'three'
  ];
  const defaultOption = options[0];
  const items = [
    {
      slug: ()=>server1(),
      anchor: "Eyectracking"
    },
    {
      slug: ()=>server2(),
      anchor: "Rice Field Water Detection"
    },
    {
      slug: ()=>server6(),
      anchor: "Object Detection And Recognition V1"
    },
    {
      slug: ()=>server3(),
      anchor: "Object Detection And Recognition V2"
    },
    {
      slug: ()=>server7(),
      anchor: "Product Detector"
    },
    {
      slug: ()=>server8(),
      anchor: "Product Classifier"
    },
    {
      slug: ()=>server9(),
      anchor: "Video Analytics"
    }
  ];
  const { button } = queryString.parse(location.search);
  useEffect(() => {
    getToken(107, 1);
    refreshToken();
    tenerTokenEye();
    tenerTokenSRC();
    tenerTokendetector();
    tenerTokenVideo();
    tenerToken();
    refrescarToken();
  }, []);
  
  const prueba = (button) => {
    switch (button) {
      case "1":
        setTimeout(() => {
          document.getElementById("button1").click();
        }, 2000);
        break;
      case "2":
        setTimeout(() => {
          document.getElementById("button2").click();
        }, 2000);
        break;
      case "3":
        setTimeout(() => {
          document.getElementById("button3").click();
        }, 2000);
        break
      case "4":
        setTimeout(() => {
          document.getElementById("button4").click();
        }, 2000);
        break;
      case "5":
        setTimeout(() => {
          document.getElementById("button5").click();
        }, 2000);
        break;
        case "6":
        setTimeout(() => {
          document.getElementById("button6").click();
        }, 2000);
        break;
      default:
        break;
    }
  }
  const refreshToken = async () => {
    try {
      const response = await axios.get(ENDPOINT_NODE + "/token");
      setToken(response.data.accessToken);
      const decoded = jwt_decode(response.data.accessToken);
      setUser(decoded.userId);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (error) {
      if (error.response) {
        history.push("/");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(ENDPOINT_NODE + "/token");
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwt_decode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const refrescarToken = () => {
    identificadorTiempoDeEspera = setInterval(tenerTokenEye, 120000);
    identificadorTiempoDeEspera2 = setInterval(tenerTokenSRC, 120000);
    identificadorTiempoDeEspera3 = setInterval(tenerToken, 120000);
  };
  const getToken = async (id_user, service) => {
    try {
      const response = await axios.post(ENDPOINT_NODE + "/get-password-token", {
        id_user: id_user,
        id_service: service,
      });
    } catch (error) {
      if (error.response) {
        // setMsg(error.response.data.msg);
      }
    }
  };
  const tenerToken = () => {
    let formData = new FormData();
    formData.append("username", "cristian@santabros.com.ar");
    formData.append("password", "carlos");
    axios({
      // Endpoint to send files
      url: ENDPOINT_YOLO + "/get-token",
      method: "POST",
      // Attaching the form data
      data: formData,
    }).then((response) => {
      setTokenApi(response.data.access_token);
    });
  };
  const tenerTokenEye = () => {
    let formData = new FormData();
    formData.append("username", "cristian@santabros.com.ar");
    formData.append("password", "carlos");
    axios({
      // Endpoint to send files
      url: ENDPOINT_PYTHON + "/get-token",
      method: "POST",
      // Attaching the form data
      data: formData,
    }).then((response) => {
      setTokenApiEye(response.data.access_token);
    });
  };
  const tenerTokenVideo = () => {
    let formData = new FormData();
    formData.append("username", "cristian@santabros.com.ar");
    formData.append("password", "carlos");
    axios({
      // Endpoint to send files
      url: ENDPOINT_VIDEO + "/get-token",
      method: "POST",
      // Attaching the form data
      data: formData,
    }).then((response) => {
      console.log(response.data.access_token)
      setTokenApiVideo(response.data.access_token);
    });
  };
  const tenerTokendetector = () => {
    let formData = new FormData();
    formData.append("username", "cristian@santabros.com.ar");
    formData.append("password", "carlos");
    axios({
      // Endpoint to send files
      url: ENDPOINT_PDETECTOR + "/get-token",
      method: "POST",
      // Attaching the form data
      data: formData,
    }).then((response) => {
      setTokenApidetector(response.data.access_token);
    });
  };
  const tenerTokenSRC = () => {
    let formData = new FormData();
    formData.append("username", "cristian@santabros.com.ar");
    formData.append("password", "carlos");
    axios({
      // Endpoint to send files
      url: ENDPOINT_SRC + "/get-token",
      method: "POST",
      // Attaching the form data
      data: formData,
    }).then((response) => {
      setTokenApiSRC(response.data.access_token);
    });
  };
  
  const getImages_yolo = async (a) => {
    try {
      await axios
        .get(ENDPOINT_YOLO + "/get-images-data-service/" + a, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + tokenApi,
          },
        })
        .then((response) => {
          setImages(response.data);
          var fecha = "";
          const items = response.data;
          for (let i = 0; i < items.length; i++) {
            fecha = items[i].created_at.split(" ");
            items[i].created_at = fecha[1] + "-" + fecha[2] + "-" + fecha[3] +" " +fecha[4].slice(0,5);
          }
          var ayuda2 = [];
          for (let i = 0; i < items.length; i++) {
            ayuda2.push(i);
          }
          console.log(items);
          setHtml(
            <Pagination_yolo_cms
              informacion={items.reverse()}
              ayuda={ayuda2}
            ></Pagination_yolo_cms>
          );
        });
    } catch (error) {
      setShowAlert(true);
      if (error.response) {
        console.log(error.response.data); // Información detallada sobre el error de respuesta
        console.log(error.response.status); // Código de estado HTTP de la respuesta
        setMessageAlert("Error al borrar");
      } else if (error.request) {
        console.log(error.request); // Información sobre la solicitud sin respuesta
        setMessageAlert("Request error");
        setHtml(<Alert message={"Request error"} />);
      } else {
        console.log(error.message); // Otros errores
        setMessageAlert("Unknown error");
      }
    }
  };
  const getImages_eye = async (a) => {
    try {
      await axios
        .get(ENDPOINT_PYTHON + "/api/get-monitor", {
         
        })
        .then((response) => {
          setImages(response.data);
          
          var fecha = "";
          const items = response.data;
          for (let i = 0; i < items.length; i++) {
            fecha = items[i].created_at.split(" ");
            items[i].created_at = fecha[1] + "-" + fecha[2] + "-" + fecha[3] +" " +fecha[4].slice(0,5);
          }
          var ayuda2 = [];
          for (let i = 0; i < items.length; i++) {
            ayuda2.push(i);
          }
          setHtml(
            <PaginationCms
              informacion={items.reverse()}
              ayuda={ayuda2}
            ></PaginationCms>
          );
        });
    } catch (error) {
      setShowAlert(true);
      if (error.response) {
        console.log(error.response.data); // Información detallada sobre el error de respuesta
        console.log(error.response.status); // Código de estado HTTP de la respuesta
        setMessageAlert("Error al borrar");
      } else if (error.request) {
        console.log(error.request); // Información sobre la solicitud sin respuesta
        setMessageAlert("Request error");
        setHtml(<Alert message={"Request error"} />);
      } else {
        console.log(error.message); // Otros errores
        setMessageAlert("Unknown error");
      }
    }
  };
  const getImages_src = async (a) => {

    try {
      await axios
        .get(ENDPOINT_SRC + "/api/get-monitor", {
         
        })
        .then((response) => {
          
          setImages(response.data);
          var fecha = "";
          const items = response.data;
          for (let i = 0; i < items.length; i++) {
            fecha = items[i].created_at.split(" ");
            // fecha = fecha[0].split("-").reverse()
            items[i].created_at = fecha[1] + "-" + fecha[2] + "-" + fecha[3] +" " +fecha[4].slice(0,5);
          }
          var ayuda2 = [];
          for (let i = 0; i < items.length; i++) {
            ayuda2.push(i);
          }
          setHtml(
            <Pagination_src_cms
              informacion={items.reverse()}
              ayuda={ayuda2}
            ></Pagination_src_cms>
          );
        });
    } catch (error) {
      setShowAlert(true);
      if (error.response) {
        console.log(error.response.data); // Información detallada sobre el error de respuesta
        console.log(error.response.status); // Código de estado HTTP de la respuesta
        setMessageAlert("Error al borrar");
      } else if (error.request) {
        console.log(error.request); // Información sobre la solicitud sin respuesta
        setMessageAlert("Request error");
        setHtml(<Alert message={"Request error"} />);
      } else {
        console.log(error.message); // Otros errores
        setMessageAlert("Unknown error");
      }
    }
  };
  const getImages_objv1 = async (a) => {
    try {
      await axios
        .get(ENDPOINT_PYTHON + "/api/get-monitor", {
      
        })
        .then((response) => {
          setImages(response.data);
          
          var fecha = "";
          const items = response.data;
          for (let i = 0; i < items.length; i++) {
            fecha = items[i].created_at.split(" ");
            // fecha = fecha[0].split("-").reverse()
            items[i].created_at = fecha[1] + "-" + fecha[2] + "-" + fecha[3] +" " +fecha[4].slice(0,5) ;
          }
          var ayuda2 = [];
          for (let i = 0; i < items.length; i++) {
            ayuda2.push(i);
          }
          setHtml(
            <PaginationObjv1Cms 
              informacion={items.reverse()}
              ayuda={ayuda2}
            ></PaginationObjv1Cms>
          );
        });
    } catch (error) {
      setShowAlert(true);
      if (error.response) {
        console.log(error.response.data); // Información detallada sobre el error de respuesta
        console.log(error.response.status); // Código de estado HTTP de la respuesta
        setMessageAlert("Error al borrar");
      } else if (error.request) {
        console.log(error.request); // Información sobre la solicitud sin respuesta
        setMessageAlert("Request error");
        setHtml(<Alert message={"Request error"} />);
      } else {
        console.log(error.message); // Otros errores
        setMessageAlert("Unknown error");
      }
    }
  };
  const getImages_product = async (a) => {
    try {
      await axios
        .get(ENDPOINT_PDETECTOR + "/api/get-monitor", {
       
        })
        .then((response) => {
          setImages(response.data);
          var fecha = "";
          const items = response.data;
          for (let i = 0; i < items.length; i++) {
            fecha = items[i].created_at.split(" ");
            // fecha = fecha[0].split("-").reverse()
            items[i].created_at = fecha[1] + "-" + fecha[2] + "-" + fecha[3] +" " +fecha[4].slice(0,5);
          }
          var ayuda2 = [];
          for (let i = 0; i < items.length; i++) {
            ayuda2.push(i);
          }
          setHtml(
            <Pagination_detector_cms
              informacion={items.reverse()}
              ayuda={ayuda2}
            ></Pagination_detector_cms>
          );
        });
    } catch (error) {
      setShowAlert(true);
      if (error.response) {
        console.log(error.response.data); // Información detallada sobre el error de respuesta
        console.log(error.response.status); // Código de estado HTTP de la respuesta
        setMessageAlert("Error al borrar");
      } else if (error.request) {
        console.log(error.request); // Información sobre la solicitud sin respuesta
        setMessageAlert("Request error");
        setHtml(<Alert message={"Request error"} />);
      } else {
        console.log(error.message); // Otros errores
        setMessageAlert("Unknown error");
      }
    }
  };
  const getImages_clasifiyer = async (a) => {
    try {
      await axios
        .get(ENDPOINT_PDETECTOR + "/api/get-monitor", {

        })
        .then((response) => {
          setImages(response.data);
          var fecha = "";
          const items = response.data;
          
          for (let i = 0; i < items.length; i++) {
            fecha = items[i].created_at.split(" ");
            // fecha = fecha[0].split("-").reverse()
            items[i].created_at = fecha[1] + "-" + fecha[2] + "-" + fecha[3] +" " +fecha[4].slice(0,5);
          }
          var ayuda2 = [];
          for (let i = 0; i < items.length; i++) {
            ayuda2.push(i);
          }
          setHtml(
            <Pagination_clasifiyer_cms
              informacion={items.reverse()}
              ayuda={ayuda2}
            ></Pagination_clasifiyer_cms>
          );
        });
    } catch (error) {
      setShowAlert(true);
      if (error.response) {
        console.log(error.response.data); // Información detallada sobre el error de respuesta
        console.log(error.response.status); // Código de estado HTTP de la respuesta
        setMessageAlert("Error al borrar");
      } else if (error.request) {
        console.log(error.request); // Información sobre la solicitud sin respuesta
        setMessageAlert("Request error");
        setHtml(<Alert message={"Request error"} />);
      } else {
        console.log(error.message); // Otros errores
        setMessageAlert("Unknown error");
      }
    }
  };
  const getImages_Video = async (a) => {
    try {
      await axios
        .get(ENDPOINT_VIDEO + "get-videos-data-service/" + a, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + tokenApiVideo,
          },
        })
        .then((response) => {
          setImages(response.data);
          var fecha = "";
          const items = response.data;
          console.log("response.data");
          console.log(response.data);
          
          for (let i = 0; i < items.length; i++) {
            fecha = items[i].created_at.split(" ");
            // fecha = fecha[0].split("-").reverse()
            items[i].created_at = fecha[1] + "-" + fecha[2] + "-" + fecha[3] +" " +fecha[4].slice(0,5);
          }
          var ayuda2 = [];
          for (let i = 0; i < items.length; i++) {
            ayuda2.push(i);
          }
          setHtml(
            <Pagination_VideoCms
              informacion={items.reverse()}
              ayuda={ayuda2}
            ></Pagination_VideoCms>
          );
        });
    } catch (error) {
      setShowAlert(true);
      if (error.response) {
        console.log(error.response.data); // Información detallada sobre el error de respuesta
        console.log(error.response.status); // Código de estado HTTP de la respuesta
        setMessageAlert("Error al borrar");
      } else if (error.request) {
        console.log(error.request); // Información sobre la solicitud sin respuesta
        setMessageAlert("Request error");
        setHtml(<Alert message={"Request error"} />);
      } else {
        console.log(error.message); // Otros errores
        setMessageAlert("Unknown error");
      }
    }
  };


  const reload = () => {
    console.log(realoadValor)
    window.location.href = `/image?button=${realoadValor}`;   
     // history.push("/image")
  };
  const server1 = () => {
    getImages_eye(user);
    setReloadValor(1)
    setActive1(true);
    setActive2(false);
    setActive3(false);
    setActive6(false);
    setActive7(false);
    setActive8(false);
    setActive9(false);
  };
  const server2 = () => {
    getImages_src(user);
    setReloadValor(2)
    setActive1(false);
    setActive2(true);
    setActive3(false);
    setActive6(false);
    setActive7(false);
    setActive8(false);
    setActive9(false);
  };
  const server3 = () => {
    getImages_yolo(user);
    setReloadValor(3)
    setActive1(false);
    setActive2(false);
    setActive3(true);
    setActive6(false);
    setActive7(false);
    setActive8(false);
    setActive9(false);
  };
  const server6 = () => {
    getImages_objv1(user);
    setReloadValor(4)
    setActive1(false);
    setActive2(false);
    setActive3(false);
    setActive6(true);
    setActive7(false);
    setActive8(false);
    setActive9(false);
  };
  const server7 = () => {
    getImages_product(user);
    setReloadValor(5)
    setActive1(false);
    setActive2(false);
    setActive3(false);
    setActive6(false);
    setActive7(true);
    setActive8(false);
    setActive9(false);
  };
  const server8 = () => {
    getImages_clasifiyer(user);
    setReloadValor(6)
    setActive1(false);
    setActive2(false);
    setActive3(false);
    setActive6(false);
    setActive7(false);
    setActive8(true);
    setActive9(false);
  };
  const server9= () => {
    getImages_Video(user);
    setReloadValor(7)
    setActive1(false);
    setActive2(false);
    setActive3(false);
    setActive6(false);
    setActive7(false);
    setActive8(false);
    setActive9(true);
  };


  return (
    <>
      <Navbar />
      <div className="container mt-5">
        <h3 className="h3 text-white">Select Service <i className="bi bi-question-circle" data-title="You can view all your images according to the selected service"></i></h3>
        <div className="row">
          <div className="col-8 mb-5">
            <Dropdown dropdownTitle="List of Services" 
            items={items} numberbutton={button} funcion={prueba}
              
            />

          </div>
          <div className="col-4 ms-auto text-right">
            <span className="justify-content-right  ">
              <button
                className="btn btn-warning mb-3 text-white bordewhite"
                onClick={reload}
                data-title="Reload the page to update content"
              >
                Reload Screen
              </button>
            </span>
          </div>
        </div>

        {(active1 && <div>{html}</div>) ||
          (active2 && <div className="text-white">{html}</div>) ||
          (active3 && <div>{html}</div>) ||
          (active6 && <div>{html}</div>) ||
          (active7 && <div>{html}</div>) ||
          (active8 && <div>{html}</div>)||
          (active9 && <div>{html}</div>)}
      </div>
    </>
  );
};

export default ImagenCheck;
