import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import "../../../../assets/css/styles.css"
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { ENDPOINT_SRC,ENDPOINT_NODE } from '../../../../backend.js';
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  Media,
  Table,
  Row,
} from "reactstrap";

const ImagenesObjv1 = (props) => {

    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage= 10;
    const [nameImage,setNameImage] = useState('');
    const [vista,setVista] = useState(3);
    const [newImage,setNewImage]= useState();    
    const [imagen,setImagen]=useState('')
    const [tokenApi, setTokenApi] = useState('')
    const history = useHistory();
    const [busqueda,setBusqueda] = useState('')
    const [tabladFiltrado,setTablaFiltrado] = useState(props.informacion)
    const [valores,setValores]=useState(props.informacion)
    const [valoresaux,setValoresAux]=useState(props.ayuda)
    React.useEffect(() => {
      // abriregistro(26);
      filtrarArray(props.informacion)
      tenerToken();
      getToken(107,1);
    }, []);

    const abririmagen = (a) => {
      setVista(2)
      const Endpoint= a
      const baseURL = ENDPOINT_SRC + "/procs/"+Endpoint
      axios.get(baseURL).then((response) => {
        if (response.data.status == 1) {
          imagenCreada(response.data)         
          
         }else if (response.data.status == 0){
          setVista(4)
          
         }
        
      });   
      
    } 
    const getToken= async(id_user,service)=>{
      try {
        const response = await axios.post(ENDPOINT_NODE+'/get-password-token', {
            id_user: id_user,
            id_service: service
        });
    } catch (error) {
        
        if (error.response) {
          // setMsg(error.response.data.msg);
        }
    }
}
    const tenerToken = () => {
      let formData = new FormData();
      formData.append('username', "cristian@santabros.com.ar");
      formData.append('password', "carlos");
      axios({
          // Endpoint to send files
          url: ENDPOINT_SRC+"/get-token",
          method: "POST",
          // Attaching the form data
          data: formData,
        })
        .then((response) => {
          setTokenApi(response.data.access_token);
        });
      }
    const eliminarimagen = async (name) => {
      alert("seguro quiere eliminar el contenido")
      try {
          await axios.get(ENDPOINT_SRC+"/delete/"+name, {
            headers: {
              "Content-Type": "multipart/form-data",
              "Authorization": "Bearer " + tokenApi,
            }
              // filename: name,

          }).then((response)=>{
              window.location.reload()
              
          })
      } catch (error) {
          if (error.response) {
          }
      }
  }

   
    const imagenCreada = (data) => {
      setVista(1)
      setImagen([data.img_base64])
      setNameImage(data.image)
    }
    const filtrarArray=(array) =>{
      var ayuda2 = [];
      var items2 = array
        for (let i = 0; i<items2.length; i++){
          ayuda2.push(i)
        }
        setValores(items2)
        setValoresAux(ayuda2)
        
        setCurrentItems(ayuda2.slice(itemOffset, itemOffset + itemsPerPage));
        setPageCount(Math.ceil(items2.length / itemsPerPage));
    }
    useEffect(() => {
        
        const endOffset = itemOffset + itemsPerPage;
        
        setCurrentItems(valoresaux.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(valores.length / itemsPerPage));
      }, [itemOffset, itemsPerPage]);
      const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % valores.length;
        
        setItemOffset(newOffset);
      };
      const handleChange = (e) => {
        setBusqueda(e.target.value)
        filtrar(e.target.value)
      }
      const filtrar =(terminoBusqueda)=>{
        var resultadosBusqueda=tabladFiltrado.filter((elemento) => {
          if(
            (elemento.image != null &&  elemento.image.includes(terminoBusqueda.toLowerCase()))
          || 
           (elemento.job_id != null && elemento.job_id.includes(terminoBusqueda.toLowerCase() ))
           || 
         (elemento.created_at != null && elemento.created_at.toString().includes(terminoBusqueda.toLowerCase() ))
         || 
         (elemento.user_id != null && elemento.user_id.toString().includes(terminoBusqueda.toLowerCase() ))
         || 
         (elemento.user_id != null && elemento.user_id.toString().includes(terminoBusqueda ))){
            return elemento;
          }
        });
        
        
        filtrarArray(resultadosBusqueda)
      }
    return (
            <>
            <Row>
            <div className="col">
              <Card className="shadow">
                <div className='row'>
                <CardHeader className="border-0">
                  <div className='col-6'>
                  
                  <h3 className="mb-0">Imagen List Object v1</h3>
      
                  </div>
                  <div className='col-6'>
                  <div className="input-group rounded">
                        <input type="search" className="form-control " onChange={handleChange} value={busqueda} placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
                        
                        </div>
                        
                  </div>
                  </CardHeader>
                </div>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" >Name</th>
                      <th scope="col" >User</th>
                      <th scope="col" >ID</th>
                      <th scope="col" >Date</th>
                      <th scope="col" >ATP</th>
                      <th scope="col" >AWT</th>
                      <th scope="col" >View</th>
                      <th scope="col" >Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                  {currentItems && currentItems.map((item,index) => (
                    <tr key={index}>
                      <th scope="row">
                        <Media className="align-items-center">
                          <Media>
                            <span className="mb-0 text-sm">
                            {valores[item].image.slice(0,18)}
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>{valores[item].id_user}</td>
                      
                      <td>{valores[item].job_id}</td>
                      <td>
                      {valores[item].created_at}
                      </td>
                      <td>
                      {valores[item].process_time}
                      </td>
                      <td>
                      {valores[item].process_time_wait}
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">{valores[item].status ==  1 ? <button data-title2="View image" className='bg-white bordeblack' onClick={(e) => abririmagen(valores[item].job_id, e)} >
                                    <i className="bi bi-eye " ></i>
                                    </button> :  (valores[item].status ==  2 ? 
                                    <button data-title2="Your image has been deleted" className='bg-danger bordewhite' >
                                    <i className="bi bi-folder-x text-white"></i>
                                    </button> :
                                    <button data-title2="Your image is not ready yet"  className='bg-danger bordewhite' >
                                    <i className="bi bi-x-lg text-white"></i>
                                    </button>)}</span>
                          
                        </div>
                      </td>
                      <td className="text-left">
                      {valores[item].status ==  1 ? <button data-title2="Delete image" className='bg-danger bordewhite' onClick={(e) => eliminarimagen(valores[item].image_proc, e)} >
                                    <i className="bi bi-trash-fill text-white" ></i>
                                    </button> : ""}
                      </td>
                    </tr>
                    
                  ))}
                  </tbody>
                </Table>
                <CardFooter className="text-center">
                <div className='text-center text-white alinear my-5'>
                <ReactPaginate

                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="< "
                  renderOnZeroPageCount={null}
                  containerClassName="pagination justify-content-center"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  activeClassName="active"
                  />
                </div>
                
                </CardFooter>
              </Card>
            </div>
          </Row>
            
      <br></br>
            
      
      {((vista == "1" ) && (
        <div className='container bg-dark bordewhite mb-5'>
          <h1 className='h1 text-white'>NAME: {nameImage}</h1>
          <div className='row '>
            <div className='col-4 mb-5'>
              
            </div>
            <div className='col-4 mb-5'>
              <img src={imagen[0]} width="448" height="84" alt="logo" className='bordewhite'></img>
            </div>
            <div className='col-4 mb-5'>
                     
            </div>

          </div>

      </div>
      
    )) || ((vista == "2") && (
      <h1 className='h1 text-white'>PROCESSING...</h1>
      ))|| ((vista == "3") && (
      <h1 className='h1 text-white'>You can see your image below <i class="bi bi-arrow-down"></i></h1>
      ))|| ((vista == "4") && (
      <h1 className='h1 text-white'>Your image is not ready yet, try in a few minutes </h1>
      ))}
      <br/>
      <br/>
    </>
    
    );
}

export default ImagenesObjv1;
