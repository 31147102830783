import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { ENDPOINT_NODE, ENDPOINT_YOLO, ENDPOINT_SRC, ENDPOINT_VIDEO} from "../../backend.js";

const FormNewUserVideo = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirpassword, setConfirPassword] = useState("");
  const [ip_address, setip_address] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [msg, setMsg] = useState("");
  const history = useHistory();
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [tokenApi, setTokenApi] = useState("");
  const [expire, setExpire] = useState("");
  const [id_user, setId_user] = useState();

  useEffect(() => {
    refreshToken();
    tenerToken();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(ENDPOINT_NODE + "/token");
      setToken(response.data.accessToken);
      const decoded = jwt_decode(response.data.accessToken);
      setId_user(decoded.userId);
      setEmail(decoded.email);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (error) {
      if (error.response) {
        history.push("/");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(ENDPOINT_NODE + "/token");
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwt_decode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const tenerToken = () => {
    let formData = new FormData();
    formData.append("username", "cristian@santabros.com.ar");
    formData.append("password", "carlos");
    axios({
      // Endpoint to send files
      url: ENDPOINT_VIDEO + "/get-token",
      method: "POST",
      // Attaching the form data
      data: formData,
    }).then((response) => {
      setTokenApi(response.data.access_token);
    });
  };
  const activarServicio = async (e) => {
    try {
      await axios.post(ENDPOINT_NODE + "/change-status", {
        user_id: id_user,
        service: "rice_field",
        status: 2,
      });
      
      history.push("/services");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  const Auth = async (e) => {
    e.preventDefault();
    
    if (password == confirpassword && password != "") {
      
      setMsg("");
      try {
        let formData = new FormData();
        formData.append("username", email);
        formData.append("password", password);
        formData.append("ip_address", ip_address);
        formData.append("id_user", id_user);
        formData.append("status", 2);
        axios({
          url: ENDPOINT_VIDEO + "/create-user-token",
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + tokenApi,
          },
          // Attaching the form data
          data: formData,
        }).then((response) => {
          register_password_node(id_user,email,password,5,0)
          // activarServicio();
          history.push("/services");
        });
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    } else {
      setMsg("The password must match");
    }
  };
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
    if (e.target.checked) {
      setip_address("");
    }
  };
  const register_password_node = async (id_user, correo,password,id_service, status) =>{
    
    await axios.post(ENDPOINT_NODE+'/register-password-token', {
      id_user: id_user,
      username: correo,
      password: password,
      id_service: id_service,
      status: status,
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
    }});
        
}
  return (
    <div>
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column">
              <form onSubmit={Auth} className="box">
                <p className="has-text-centered font-weight-bold">
                  Credentials for Video Analytics
                </p>
                <p className="has-text-centered text-danger">{msg}</p>
                <div className="field mt-5">
                  <label className="label">Username</label>
                  <div className="controls">
                    <input
                      type="text"
                      className="input"
                      placeholder="email"
                      value={email}
                      disabled
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div>
                  <div className="field mt-5">
                    <label className="label">URL Destinations</label>
                    <div className="controls">
                      <input
                        type="text"
                        className="input"
                        placeholder="URL Address"
                        value={ip_address}
                        onChange={(e) => setip_address(e.target.value)}
                        disabled={isChecked}
                      />
                    </div>
                  </div>
                  <div>
                    <input type="checkbox" onChange={handleCheckboxChange} />
                    <label style={{ marginLeft: "10px" }}>Do not use url</label>
                  </div>
                </div>
                <div className="field mt-5">
                  <label className="label">Password</label>
                  <div className="controls">
                    <input
                      type="password"
                      className="input"
                      placeholder="******"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="field mt-5">
                  <label className="label">Confirm Password</label>
                  <div className="controls">
                    <input
                      type="password"
                      className="input"
                      placeholder="******"
                      value={confirpassword}
                      onChange={(e) => setConfirPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="field mt-5">
                  <button
                    className="button is-success is-fullwidth btnInfo "
                    disabled={!(password == confirpassword && password != "")}
                  >
                    Create
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormNewUserVideo;
