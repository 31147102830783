import React from 'react';

const Modal = ({children,isOpenform,closeform}) => {
    const handleModal = e => e.stopPropagation();
    return (
        <div className={`modal ${isOpenform && "is-open"}`} onClick={closeform}>
            <button className='modalclose' onClick={closeform}></button>  
            <div className='modal-container' onClick={handleModal}>
            
                {children}
            </div>

        </div>
    );
}

export default Modal;
