import React from 'react';
import emailjs from 'emailjs-com';

const Email = () => {
    const sendEmail = (e) => {
        e.preventDefault()
        emailjs.sendForm('service_3hw4rno', 'template_u37vjcc', e.target, 'QcqhNYFcjD_NirDxS')
        .then((result) => {
        }, (error) => {
        });
        e.target.reset()
    }
    
    return (
        <div className='container'>
            <form onSubmit={sendEmail}>
                <div className='col-8 form-group mx-auto'>
                    <input type="text" className="form-control" placeholder="Name" name="name"/>

                </div>
                <div className='col-8 pt-2 form-group mx-auto'>
                    <input type="email" className="form-control" placeholder="email" name="email"/>

                </div>
                <div className='col-8 pt-2 form-group mx-auto'>
                    <input type="text" className="form-control" placeholder="Servicio" name="servicio"/>

                </div>
                <div className='col-8 pt-2 form-group mx-auto'>
                    <input type="text" className="form-control" placeholder="Cantidad de imagenes" name="imagenes"/>

                </div>
                <div className='col-8 pt-2 form-group mx-auto'>
                    <input type="submit" className="btn btn-info" value="send mesage" />

                </div>
            </form>
            
        </div>
    );
}

export default Email;
