import {
  Container,
  Row,
  Card,
  CardHeader,
  Table,
  Badge,
  Button,
  Media,
} from "reactstrap";
import React, { useState, useEffect } from "react";

import "./../../../assets/css/tables.css";
import MenuLateral from "./../components/menuLateral";
import { ENDPOINT_NODE } from "../../../backend";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom";
import Imagen from "../../../components/Imagen";
import ImagenCheck from "./monitorCheck";
const Monitor = () => {
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [services, Setservices] = useState([{service: 'eyectracking'},
  {service: 'Object V1'},
  {service: 'Object V2'},
  {service: 'Spices'},
  {service: 'Product detector'},
  {service: 'Rice field'}])

  useEffect(() => {
    refreshToken();
  }, []);
  // usamos el token de Sesion del node
  const refreshToken = async () => {
    try {
      const response = await axios.get(ENDPOINT_NODE + "/token");
      setToken(response.data.accessToken);
      const decoded = jwt_decode(response.data.accessToken);
      setEmail(decoded.email);
      setExpire(decoded.exp);
    } catch (error) {
      if (error.response) {
        history.push("/");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(ENDPOINT_NODE + "/token");
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwt_decode(response.data.accessToken);

        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return (
    <>
      <MenuLateral></MenuLateral>
      <div className="mcw">
        <div className="cv">
          <div>
            <div className="inbox">
              <div className="inbox-sb"></div>
              <div className="inbox-bx container-fluid">
              <ImagenCheck></ImagenCheck>
                {/* <div className="row">
                  <div className="container">
                    <br></br>
                    <Container fluid>

                      <div className="container mt-5">
                        <h3 className="h3 text-white">Select Service</h3>

                        <Row>
                          <div className="col">
                            <Card className="shadow">
                              <CardHeader className="border-0">
                                <h3 className="mb-0">Monitor APIS</h3>
                              </CardHeader>
                             
                              <Table
                          className="align-items-center table-flush"
                          responsive
                        >
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">Service</th>
                              <th scope="col">Active</th>
                              <th scope="col">Deactivate</th>
                              <th scope="col">Status</th>
                              <th scope="col">Reboot</th>
                            </tr>
                          </thead>
                          <tbody>
                            {services && services.map((item,index)=>(
                                <tr key={index}>
                              <th scope="row">
                                <Media className="align-items-center">
                                  <Media>
                                    <span className="mb-0 text-sm">
                                      edwin
                                    </span>
                                  </Media>
                                </Media>
                              </th>
                              <td><Button color="success" disabled={false}>
                                  <i
                                    class="bi bi-check-circle"
                                    data-title2="Active Service"
                                  ></i>
                                </Button></td>
                              <td>
                              <Button color="danger" disabled={false}>
                                  <i
                                    class="bi bi-x-square"
                                    data-title2="Active Service"
                                  ></i>
                                </Button>
                                
                              </td>
                              <td>OK</td>

                              <td>
                                <Button color="warning" disabled={false}>
                                  <i
                                    class="bi bi-arrow-clockwise"
                                    data-title2="Reboot Service"
                                  ></i>
                                </Button>
                                
                              </td>
                            </tr>
                            ))}
                            <tr>
                              <th scope="row">
                                <Media className="align-items-center">
                                  <Media>
                                    <span className="mb-0 text-sm">
                                      edwin
                                    </span>
                                  </Media>
                                </Media>
                              </th>
                              <td><Button color="success" disabled={false}>
                                  <i
                                    class="bi bi-check-circle"
                                    data-title2="Active Service"
                                  ></i>
                                </Button></td>
                              <td>
                              <Button color="danger" disabled={false}>
                                  <i
                                    class="bi bi-x-square"
                                    data-title2="Active Service"
                                  ></i>
                                </Button>
                                
                              </td>
                              <td>OK</td>

                              <td>
                                <Button color="warning" disabled={false}>
                                  <i
                                    class="bi bi-arrow-clockwise"
                                    data-title2="Reboot Service"
                                  ></i>
                                </Button>
                                
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                            </Card>
                          </div>
                        </Row>
                        
                      </div>
                    </Container>
                    <br></br>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Monitor;
