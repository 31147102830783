import React from 'react';
import Error from './../../assets/istockphoto3.png'
import { useHistory } from "react-router-dom";

const ErrorComponent = ({ message }) => {
  const history = useHistory();
  return (
    <div className='text-center align-center mt-5'>
      <img src={Error} width={500} alt="logo" className='ml-5'></img>
      <div>
        <h1 className='h1 text-white'>PAGE NOT FOUND</h1>
        <h4 className='h4 text-white'> We're sorry, the page you requested could not be found</h4>
        <h4 className='h4 text-white'> Please go back to the home page</h4>
      </div>
      <div>
      <button className='btn btn-primary btn-lg mt-2'
        data-title="Go Home" onClick={()=>history.push("/services")}> GO HOME </button>
      </div>
    </div>
  );
}

export default ErrorComponent;