// reactstrap components
import { Card, Row, Col ,Table,CardHeader} from "reactstrap";
import axios from 'axios';
import { ENDPOINT_YOLO, ENDPOINT_PYTHON , ENDPOINT_SRC, ENDPOINT_NODE, ENDPOINT_PDETECTOR,ENDPOINT_CLASSIFIER } from "../../../backend";
import React , { useState , useEffect } from "react";
import MenuLateral from "./menuLateral";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom";
import Dropdown from "../../../components/hooks/dropdown";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

const HeaderM = () => {
  const [statisticsSrcMonth, setStatisticsSrcMonth] = useState([])
  const [statisticsYoloMonth, setStatisticsYoloMonth] = useState([])
  const [statisticsEyeMonth, setStatisticsEyeMonth] = useState([])
  const [statisticsobjev1Month, setStatisticsobjev1Month] = useState([])
  const [statisticsdetectorMonth, setStatisticsdetectorMonth] = useState([])
  const [statisticsClassifierMonth, setStatisticsClassifierMonth] = useState([])
  const [active1,setActive1] = useState(true);
  const [active2,setActive2] = useState(false);
  const [active3,setActive3] = useState(false);
  const [active6, setActive6] = useState(false);
  const [active7, setActive7] = useState(false);
  const [active8, setActive8] = useState(false);
  const [realoadValor,setReloadValor]=useState(0);
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");
  const history = useHistory();
  const [user, setUser] = useState("");
  const [textoServicio, setTextoServicio] = useState("Eyetracking")
  const refreshToken = async () => {
    try {
      const response = await axios.get(ENDPOINT_NODE + "/token");
      setToken(response.data.accessToken);
      const decoded = jwt_decode(response.data.accessToken);
      setUser(decoded.userId);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (error) {
      if (error.response) {
        history.push("/");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(ENDPOINT_NODE + "/token");
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwt_decode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  useEffect(() => {  
    getStaticsEyeMonth() 
    refreshToken()
  },[] );


        const getStaticsEyeMonth = async () => {
          const response = await axios.get(ENDPOINT_PYTHON +'/api/statistics-month', {
              
          });
          var fecha =  "" ;
          const items3 = response.data    
          for (let i = 0; i<items3.length; i++){
              fecha = items3[i].created_at.split(" ") 
              items3[i].created_at = fecha[3] + '-' + fecha[2]
          }
          setStatisticsEyeMonth(items3)
              
          }
        const getStaticsYoloMonth = async () => {
          var fecha =  "" ;
          const response = await axios.get(ENDPOINT_YOLO +'/api/statistics-month', {
              
          });
          const items4 = response.data    
          for (let i = 0; i<items4.length; i++){
            fecha = items4[i].created_at.split(" ") 
            items4[i].created_at = fecha[3] + '-' + fecha[2]
        }
          setStatisticsYoloMonth(items4)
          console.log(items4)
              
          }
          const getStaticsSrcMonth = async () => {
            const response = await axios.get(ENDPOINT_SRC +'/api/statistics-month', {
                
            });
            var fecha =  "" ;
            const items2 = response.data    
            for (let i = 0; i<items2.length; i++){
                fecha = items2[i].created_at.split(" ") 
                items2[i].created_at = fecha[3] + '-' + fecha[2]
            setStatisticsSrcMonth(items2)
                
            }
          }
          const getStaticsobjv1Month = async () => {
            const response = await axios.get(ENDPOINT_PDETECTOR +'/api/statistics-month-yolo', {
                
            });
            var fecha =  "" ;
            const items2 = response.data    
            for (let i = 0; i<items2.length; i++){
                fecha = items2[i].created_at.split(" ") 
                items2[i].created_at = fecha[3] + '-' + fecha[2]
            setStatisticsobjev1Month(items2)
                
            }
          }
          const getStaticsP_detector = async () => {
            const response = await axios.get(ENDPOINT_PDETECTOR +'/api/statistics-month', {
                
            });
            var fecha =  "" ;
            const items2 = response.data    
            for (let i = 0; i<items2.length; i++){
                fecha = items2[i].created_at.split(" ") 
                items2[i].created_at = fecha[3] + '-' + fecha[2]
            setStatisticsdetectorMonth(items2)
                
            }
          }
          const getStaticsP_Classifier = async () => {
            const response = await axios.get(ENDPOINT_CLASSIFIER +'/api/statistics-month', {
                
            });
            var fecha =  "" ;
            const items2 = response.data    
            for (let i = 0; i<items2.length; i++){
                fecha = items2[i].created_at.split(" ") 
                items2[i].created_at = fecha[3] + '-' + fecha[2]
            setStatisticsClassifierMonth(items2)
                
            }
          }

          const items = [
            {
              slug: ()=>server1(),
              anchor: "Eyectracking"
            },
            {
              slug: ()=>server2(),
              anchor: "Rice Field Water Detection"
            },
            {
              slug: ()=>server6(),
              anchor: "Object Detection And Recognition V1"
            },
            {
              slug: ()=>server3(),
              anchor: "Object Detection And Recognition V2"
            },
            {
              slug: ()=>server7(),
              anchor: "Product Detector"
            },
            {
              slug: ()=>server8(),
              anchor: "Product Classifier"
            }
          ];
          const prueba = (button) => {
            switch (button) {
              case "1":
                setTimeout(() => {
                  document.getElementById("button1").click();
                }, 2000);
                break;
              case "2":
                setTimeout(() => {
                  document.getElementById("button2").click();
                }, 2000);
                break;
              case "3":
                setTimeout(() => {
                  document.getElementById("button3").click();
                }, 2000);
                break
              case "4":
                setTimeout(() => {
                  document.getElementById("button4").click();
                }, 2000);
                break;
              case "5":
                setTimeout(() => {
                  document.getElementById("button5").click();
                }, 2000);
                break;
              default:
                break;
            }
          }
          const location = useLocation();
      const { button } = queryString.parse(location.search);
      const server1 = () => {
          //getStaticsEye()
          getStaticsEyeMonth()
          setTextoServicio("Eyetracking")
          setActive1(true);
          setActive2(false);
          setActive3(false)
      }
      const server2 = () => {
        //   getStaticsSrc()
          getStaticsSrcMonth()
          setTextoServicio("Rice Field")
          setActive1(false);
          setActive2(true);
          setActive3(false);
          
      }
      const server3 = () => {
        // getStaticsYolo()
        getStaticsYoloMonth()
        setTextoServicio("Object v2")
          setActive1(false);
          setActive2(false);
          setActive3(true);
          
      }
      const server6 = () => {
        setReloadValor(4)
        setTextoServicio("Object v1")
        getStaticsobjv1Month()
        setActive1(false);
        setActive2(false);
        setActive3(false);
        setActive6(true);
        setActive7(false);
        setActive8(false);
      };
      const server7 = () => {
        getStaticsP_detector()
        setTextoServicio("Product Detector")
        setReloadValor(5)
        setActive1(false);
        setActive2(false);
        setActive3(false);
        setActive6(false);
        setActive7(true);
        setActive8(false);
      };
      const server8 = () => {
        getStaticsP_Classifier()
        setTextoServicio("Product Classifier")
        setReloadValor(6)
        setActive1(false);
        setActive2(false);
        setActive3(false);
        setActive6(false);
        setActive7(false);
        setActive8(true);
      };
      
  return (
    <>
    <MenuLateral></MenuLateral>
    <div className="mcw">
  <div className="cv">
    <div>
     <div className="inbox">
       <div className="inbox-sb">
         
       </div>
       <div className="inbox-bx container-fluid">
         <div className="row">
            <div className='container'>
            <br></br>
            <br></br>
            <div className=" pb-8 pt-5 pt-md-8">
      <div className="container mt-5">
            <h1 className='h1 text-white'>Statistics {textoServicio}</h1>
            <h3 className='h3 text-white'>Select Service</h3> 
            <div className='row'>
                <div className='col-10'>
                <Dropdown dropdownTitle="List of Services" items={items} numberbutton={button} funcion={prueba}/>

                    {/* <div>
                        
                        <button className='btn btn-warning mr-2 mb-3 text-white bordewhite' onClick={server1}>Eye-Tracking</button>
                        <button className='btn btn-warning mr-2 mb-3 text-white bordewhite' onClick={server2}>Rice Field Water Detection</button>
                        <button className='btn btn-warning mr-2 mb-3 text-white bordewhite' onClick={server3}>Object Detection And Recognition</button>
                    </div> */}
                </div>

            </div>            
        <div className="mt-3" >
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Statistics Month {textoServicio}</h3>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">DATE</th>
                    <th scope="col">QUANTITY</th>
                    <th scope="col">APT</th>
                    <th scope="col">SD_APT</th>
                    <th scope="col">AWT</th>
                    <th scope="col">STD_AWT</th>
                    
                  </tr>
                </thead>
                <tbody>
                {active2 && statisticsSrcMonth.map( (item,index) => (
                  <tr>
                    <td>{item.created_at}</td>
                    <td>{item.cant_img_proc}</td>
                    <td>{item.time_proc_prom}</td>
                    <td>{item.time_proc_stddev}</td>
                    <td>{item.time_wait_prom}</td>
                    <td>{item.time_wait_stddev}</td>
                    
                  </tr>
                  ))}
                  {active3 && statisticsYoloMonth.map( (item,index) => (
                  <tr>
                    <td>{item.created_at}</td>
                    <td>{item.cant_img_proc}</td>
                    <td>{item.time_proc_prom}</td>
                    <td>{item.time_proc_stddev}</td>
                    <td>{item.time_wait_prom}</td>
                    <td>{item.time_wait_stddev}</td>
                    
                  </tr>
                  ))}
                  {active1 && statisticsEyeMonth.map( (item,index) => (
                  <tr>
                    <td>{item.created_at}</td>
                    <td>{item.cant_img_proc}</td>
                    <td>{item.time_proc_prom}</td>
                    <td>{item.time_proc_stddev}</td>
                    <td>{item.time_wait_prom}</td>
                    <td>{item.time_wait_stddev}</td>
                  </tr>
                  ))}
                  {active6 && statisticsobjev1Month.map( (item,index) => (
                  <tr>
                    <td>{item.created_at}</td>
                    <td>{item.cant_img_proc}</td>
                    <td>{item.time_proc_prom}</td>
                    <td>{item.time_proc_stddev}</td>
                    <td>{item.time_wait_prom}</td>
                    <td>{item.time_wait_stddev}</td>
                  </tr>
                  ))}
                  {active7 && statisticsdetectorMonth.map( (item,index) => (
                  <tr>
                    <td>{item.created_at}</td>
                    <td>{item.cant_img_proc}</td>
                    <td>{item.time_proc_prom}</td>
                    <td>{item.time_proc_stddev}</td>
                    <td>{item.time_wait_prom}</td>
                    <td>{item.time_wait_stddev}</td>
                  </tr>
                  ))}
                  {active8 && statisticsClassifierMonth.map( (item,index) => (
                  <tr>
                    <td>{item.created_at}</td>
                    <td>{item.cant_img_proc}</td>
                    <td>{item.time_proc_prom}</td>
                    <td>{item.time_proc_stddev}</td>
                    <td>{item.time_wait_prom}</td>
                    <td>{item.time_wait_stddev}</td>
                  </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </Col>
          
        </Row>
        </div>    
        </div>
      </div>
            </div>
            
         </div>
       </div>
     </div>
    </div>
  </div>
</div>
    
      
      
    </>
  );
};

export default HeaderM;

