import React , {useState,useEffect} from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import Modal from '../Modals/modal_userNew';
import {ENDPOINT_NODE} from "../../backend";
import { useModal } from '../hooks/modals';
import NewuserClassifier from './newuser_classifier';
const AdquirirServicio8 = (props) => {
    const servicio = props;
    const history = useHistory();
    const [msg, setMsg] = useState('');
    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');    
    const [user, setUser] = useState({});
    const [isOpenform,openform,closeform] = useModal(false)
    
    useEffect(() => {
        refreshToken();
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get(ENDPOINT_NODE+'/token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setUser(decoded)
            setName(decoded.name);
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                history.push("/");
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(ENDPOINT_NODE+'/token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setName(decoded.name);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
    // const activarServicio = async (e) => {
    //     e.preventDefault();
    //     try {
    //         await axios.post(ENDPOINT_NODE+'/update-client', {
    //             id_user: user.userId,
    //             id_service: 1,
    //             status: 1,
    //         })
    //         history.push("/services");
    //     } catch (error) {
    //         if (error.response) {
    //             setMsg(error.response.data.msg);
    //         }
    //     }
    // }
    

    
    return (
        <div className='height'>
            <div className='container text-center mt-6 '>
                <div className='row'>
                    <h1 className='h1 text-white mb-5'>You do not have this service</h1>
                    <div className='col-6 mb-4'>
                        <p className='text-white mb-2' >Do you want to buy it?</p>
                        <button className='btn btn-primary btn-lg' onClick={openform}
                         data-title="Request activate the service">Buy Now</button>
                    </div>
                    <div className='col-6 mb-4'>
                        <p className='text-white mb-2'>Do you want more information?</p>
                        <button className='btn btn-primary btn-lg'
                        data-title="Currently disabled">More information</button>
                    </div>
                    <Modal isOpenform={isOpenform} closeform={closeform}>
                    <NewuserClassifier></NewuserClassifier>
                    </Modal>
                </div>
                <div className='row'>
                    <small className='text-white'> Once you have filled out the form, you will have to wait for the administrator to accept the request.</small>

                </div>
            </div>
            
        </div>
    );
}

export default AdquirirServicio8;
