import React ,{ useState,useEffect } from 'react';
import axios from "axios";
import { ENDPOINT_SRC,ENDPOINT_NODE  } from "../../backend";
import uploadService from "../../services/upload.service";
import Alert from '../../components/error/ErrorAlert';
import {Toaster, toast } from "react-hot-toast";
import Spinner from '../hooks/spinners';

const DragAreaSrc = (props) => {
    const [ImageSelectedPrevious, setImageSelectedPrevious] = useState([]);
    const [archivos,setArchivos] = useState("");
    const [myvalue,setmyValue] = useState('')
    const [prueba,setPrueba] = useState([])
    const [base64, setBase64] = useState([])
    const [activate,setActivate] = useState(false)
    const [progress,setProgress] = useState(0)
    const [token,setToken ] = useState('')  
    const [finish,setFinish] = useState(false)
    const [showAlert, setShowAlert] = useState(false);
    const [messageAlert, setMessageAlert] = useState('');
    const [valorspinner,setValorspinner] = useState(false)

    const changeImage = (e) => {   
      añadirImagen(e.target.files)
      tenerToken()
      for (let i = 0; i<e.target.files.length; i++){     
        if (e.target.files[i] !== undefined) {
          const reader = new FileReader();        
          reader.readAsDataURL(e.target.files[i]);
          reader.onload = (e) => {
            e.preventDefault();
            setBase64(base64=>base64.concat(e.target.result))
            
          };
        }
      }
    };
    useEffect(() => {
      getToken(107,1);
  }, []);
    const UploadFiles = async ()=>{

      try {
        setValorspinner(true)
        setActivate(true)
        setProgress(Math.round(100/2))
        const EndPoint = ENDPOINT_SRC+"/api-send-images"
        console.log(prueba[0].name)
        console.log(props.email)
        let formData = new FormData();
            formData.append("name", prueba[0].name);
            formData.append("image", base64[0]);
        
            formData.append("username", props.email)
            await axios({
              url: EndPoint,
              method: "POST",
              headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer " + token,
              },
              // Attaching the form data
              data: formData,
            })
            .then((response) => {
              setProgress(100)
              toast.success('Your image has been processed')
              setFinish(true)
              setPrueba([])
              setBase64([])
              // sumarCantidad(this.id_usuario,this.servicio);
              setValorspinner(false)
              // this.guardarNombreImage( this.id_usuario ,this.servicio,currentFile.name,response.data.Jobid)
            })
      } catch (error) {
        setShowAlert(true);
        setActivate(false)
        setValorspinner(false)
        if (error.response) {
          console.log(error.response.data); // Información detallada sobre el error de respuesta
          console.log(error.response.status); // Código de estado HTTP de la respuesta
          setMessageAlert("Error displaying");
        } else if (error.request) {
          console.log(error.request); // Información sobre la solicitud sin respuesta
          setMessageAlert("Request error");
        } else {
          console.log(error.message); // Otros errores
          setMessageAlert("Unknown error");
        }
      }  
    }
    const getToken= async(id_user,service)=>{
      try {
        const response = await axios.post(ENDPOINT_NODE+'/get-password-token', {
            id_user: id_user,
            id_service: service
        });
        console.log("prueba")
        console.log(response.data)
    } catch (error) {
        
        if (error.response) {
          // setMsg(error.response.data.msg);
        }
    }
}
    const tenerToken = () => {
      let formData = new FormData();
      formData.append('username', "cristian@santabros.com.ar");
      formData.append('password', "carlos");
      axios({
          // Endpoint to send files
          url: ENDPOINT_SRC+"/get-token",
          method: "POST",
          // Attaching the form data
          data: formData,
        })
        .then((response) => {
          setToken(response.data.access_token);
        });
      }
    const añadirImagen = (value) => {
      Array.from(value).forEach(archivos=>{
          setPrueba(prueba=>prueba.concat(archivos))
      })
    }
    const removerImage = (value) =>{
      const a = base64
      const b = a.splice(value,1)
      setBase64(a)
      setPrueba(prueba.filter((item) => item !== prueba[value]))
    }
    return (
      <div>
        {/* <StyleDragArea> */}
        {showAlert && <Alert message={messageAlert} />}
          <br />
          <div className="image-upload-wrap">
            <input
              className="file-upload-input"
              type="file"
              accept=".png, .jpg, .jpeg"
              value={myvalue}
              onChange={(e) => {
                changeImage(e);
              }}
            />
            <div className="text-information my-3">
              <h6 className="h6 text-white">Drag and drop a file or select add Image</h6>
              <h6 className='h6 text-white'>Only .jpg .png .jpeg images are accepted</h6>
              <h4 className="h4 text-white" >Number of files: {prueba.length}</h4>
            </div>
          </div>
          
          <div className="row mt-5 ">
          {base64.map((imagen, index) => (
              <div key={index} className="text-center col-2">
              <img 
                src={imagen}
                alt=""
                height="100px"
                width="150px"
                className="imagenredonda"
              />
              <div>
              <button onClick={(e)=>removerImage(index,e)} className="my-3 btn btn-danger"
              data-title="Remove this image">REMOVE</button>
              </div>
            </div>
                      ))}
  
          </div>
        {/* </StyleDragArea> */}
        {valorspinner && (<Spinner loading={true} />)}
        {activate && (
          <div className="progress2">
              <div className="progress-bar2" style={{ width: progress + "%" }}>
                  <span className="progress-bar-text2">{progress}%</span>
              </div>
          </div>
            )}
            { finish && (<div>
              <h4 className="h4 text-white">
                
              You can view your image in <a href="/image?button=2"> Images</a>
              </h4>
            </div>)
  
            }
        <div className="row">
              <div className="col-6 text-center mt-5">
                <a href="/services">
                  <button className="btn btn-primary btn-lg ml-2 mb-4"
                  data-title="Return to services">Return</button>
                </a>
              </div>
              
              <div className="col-6 ">
                <div className=" my-5 " >
                <div className="text-center">
                <button
                    className="btn btn-success"
                    disabled={prueba==''}
                    onClick={UploadFiles}
                    data-title="Process images"
                  >
                    Start Audit
                  </button>
                </div>
  
                </div>
              </div>
            </div>
            <Toaster
            position="bottom-center"
            reverseOrder={false}
          />
      </div>
      
    );
}

export default DragAreaSrc;
