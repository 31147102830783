import React, { useState, useEffect } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom";
import {
  ENDPOINT_NODE,
  ENDPOINT_YOLO,
  ENDPOINT_SRC,
  ENDPOINT_PYTHON,
  ENDPOINT_PDETECTOR,
} from "../backend";

function Statistics({ data }) {
  const [sortBy, setSortBy] = useState("date");
  const [name, setName] = useState("");
  const [last_date_yolo, setLast_date_yolo] = useState("");
  const [last_date_yolov1, setLast_date_yolov1] = useState("");
  const [last_date_pdetector, setLast_date_pdetector] = useState("");
  const [last_date_pclassifier, setLast_date_pclassifier] = useState("");
  const [last_date_eye, setLast_date_eye] = useState("");
  const [last_date_src, setLast_date_src] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");
  const [user, setUser] = useState("");
  const history = useHistory();
  const [serviciosActivos, SetServiciosActivos] = useState([0, 0, 0, 0]);
  const [msg, setMsg] = useState("");
  const [tokenApi, setTokenApi] = useState("");
  const [tokenApiEye, setTokenApiEye] = useState("");
  const [tokenApiSRC, setTokenApiSRC] = useState("");
  const [contador_img_eye, setContador_img_eye] = useState(0);
  const [contador_img_yolo, setContador_img_yolo]= useState(0)
  const [contador_img_yolov1, setContador_img_yolov1]= useState(0)
  const [contador_img_pdetector, setContador_img_pdetector]= useState(0)
  const [contador_img_pclassifier, setContador_img_pclassifier]= useState(0)
  const [contador_img_src, setContador_img_src]= useState(0)
  const [serviciosActivos2, SetServiciosActivos2] = useState({});
  useEffect(() => {
    refreshToken();
    getToken(107,1);
  }, []);

  const handleSort = (e) => {
    setSortBy(e.target.value);
  };
  const refreshToken = async () => {
    try {
      const response = await axios.get(ENDPOINT_NODE + "/token");
      setToken(response.data.accessToken);
      const decoded = jwt_decode(response.data.accessToken);
      setUser(decoded.userId);
      setName(decoded.name);
      tenerToken(decoded.userId);
      tenerTokenEye(decoded.userId);
      tenerTokenSRC(decoded.userId);
      tenerTokendetector(decoded.userId);
      // tenerTokenclassifier(decoded.userId);
      getClientes(decoded.userId, response.data.accessToken);
      getServicesActivobyid(decoded.userId, response.data.accessToken)
      setExpire(decoded.exp);
    } catch (error) {
      if (error.response) {
        history.push("/");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(ENDPOINT_NODE + "/token");
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwt_decode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const ServiciosActivos = (servicios) => {
    var eyetracking = 0;
    var src = 0;
    var objectDetection = 0;

    const momentoServicio = servicios.map(function (servicio) {
      if (servicio.id_service == 1) {
        eyetracking = servicio.status;
      } else if (servicio.id_service == 2) {
        src = servicio.status;
      } else if (servicio.id_service == 3) {
        objectDetection = servicio.status;
      }
      return servicio;
    });
    SetServiciosActivos([eyetracking, src, objectDetection, 0]);
  };
  const getClientes = async (usuario, tToken) => {
    try {
      await axios
        .post(ENDPOINT_NODE + "/clientById", {
          id_user: usuario,
        }, {
          headers: {
            Authorization: `Bearer ${tToken}`,
          }})
        .then((response) => {
          ServiciosActivos(response.data);
        });
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  const getToken= async(id_user,service)=>{
    try {
      const response = await axios.post(ENDPOINT_NODE+'/get-password-token', {
          id_user: id_user,
          id_service: service
      });
      console.log("prueba")
      console.log(response.data)
  } catch (error) {
      
      if (error.response) {
        // setMsg(error.response.data.msg);
      }
  }
}
  const tenerToken = (id) => {
    let formData = new FormData();
    formData.append("username", "cristian@santabros.com.ar");
    formData.append("password", "carlos");
    axios({
      // Endpoint to send files
      url: ENDPOINT_YOLO + "/get-token",
      method: "POST",
      // Attaching the form data
      data: formData,
    }).then((response) => {
      setTokenApi(response.data.access_token);
      getImages_yolo(response.data.access_token, id);
    });
  };
  const tenerTokenEye = (id) => {
    let formData = new FormData();
    formData.append("username", "cristian@santabros.com.ar");
    formData.append("password", "carlos");
    axios({
      // Endpoint to send files
      url: ENDPOINT_PYTHON + "/get-token",
      method: "POST",
      // Attaching the form data
      data: formData,
    }).then((response) => {
      setTokenApiEye(response.data.access_token);
      getImages_eye(response.data.access_token, id);

    });
  };
  const tenerTokendetector = (id) => {
    let formData = new FormData();
    formData.append("username", "cristian@santabros.com.ar");
    formData.append("password", "carlos");
    axios({
      // Endpoint to send files
      url: ENDPOINT_PDETECTOR + "/get-token",
      method: "POST",
      // Attaching the form data
      data: formData,
    }).then((response) => {
      getImages_Pdetector(response.data.access_token, id);    });
  };
  const tenerTokenclassifier = (id) => {
    let formData = new FormData();
    formData.append("username", "cristian@santabros.com.ar");
    formData.append("password", "carlos");
    axios({
      // Endpoint to send files
      url: ENDPOINT_PDETECTOR + "/get-token",
      method: "POST",
      // Attaching the form data
      data: formData,
    }).then((response) => {
      getImages_Pclassifier(response.data.access_token, id);    });
  };
  const tenerTokenSRC = (id) => {
    let formData = new FormData();
    formData.append("username", "cristian@santabros.com.ar");
    formData.append("password", "carlos");
    axios({
      // Endpoint to send files
      url: ENDPOINT_SRC + "/get-token",
      method: "POST",
      // Attaching the form data
      data: formData,
    }).then((response) => {
      setTokenApiSRC(response.data.access_token);
      getImages_src(response.data.access_token, id);
    });
  };
  const getImages_yolo = async (token, a) => {
    try {
      await axios
        .get(ENDPOINT_YOLO + "/get-images-data-service/" + a, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          var fecha = "";
          const items = response.data;
          setContador_img_yolo(items.length);
          for (let i = 0; i < items.length; i++) {
            fecha = items[i].created_at.split(" ");
            items[i].created_at = fecha[1] + "-" + fecha[2] + "-" + fecha[3] +" " +fecha[4].slice(0,5);
          }
          items.reverse();
          if (items.length == 0) {
            setLast_date_yolo("Never used");
          } else {
            setLast_date_yolo(items[0].created_at);
          }
        });
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  const getImages_yolov1 = async (token, a) => {
    console.log(token)
    try {
      await axios
        .get(ENDPOINT_PYTHON + "/get-images-data-service-yolo/" + a, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          var fecha = "";
          const items = response.data;
          setContador_img_yolov1(items.length);
          for (let i = 0; i < items.length; i++) {
            fecha = items[i].created_at.split(" ");
            items[i].created_at = fecha[1] + "-" + fecha[2] + "-" + fecha[3] +" " +fecha[4].slice(0,5);
          }
          items.reverse();
          if (items.length == 0) {
            setLast_date_yolov1("Never used");
          } else {
            setLast_date_yolov1(items[0].created_at);
          }
        });
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  const getImages_eye = async (token, a) => {
    try {
      await axios
        .get(ENDPOINT_PYTHON + "/get-images-data-service/" + a, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          getImages_yolov1(token, a);

          var fecha = "";
          const items = response.data;
          setContador_img_eye(items.length);
          for (let i = 0; i < items.length; i++) {
            fecha = items[i].created_at.split(" ");
            items[i].created_at = fecha[1] + "-" + fecha[2] + "-" + fecha[3] +" " +fecha[4].slice(0,5);
          }
          items.reverse();
          if (items.length == 0) {
            setLast_date_eye("Never used");
          } else {
            setLast_date_eye(items[0].created_at);
          }
        });
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  const getImages_src = async (token, a) => {
    try {
      await axios
        .get(ENDPOINT_SRC + "/get-images-data-service/" + a, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          var fecha = "";
          const items = response.data;
          setContador_img_src(items.length);
          for (let i = 0; i < items.length; i++) {
            fecha = items[i].created_at.split(" ");
            items[i].created_at = fecha[1] + "-" + fecha[2] + "-" + fecha[3] +" " +fecha[4].slice(0,5);
          }
          items.reverse();
          if (items.length == 0) {
            setLast_date_src("Never used");
          } else {
            setLast_date_src(items[0].created_at);
          }
        });
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  const getImages_Pdetector = async (token, a) => {
    try {
      await axios
        .get(ENDPOINT_PDETECTOR + "/get-images-data-service/" + a, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          var fecha = "";
          const items = response.data;
          setContador_img_pdetector(items.length);
          for (let i = 0; i < items.length; i++) {
            fecha = items[i].created_at.split(" ");
            items[i].created_at = fecha[1] + "-" + fecha[2] + "-" + fecha[3] +" " +fecha[4].slice(0,5);
          }
          items.reverse();
          if (items.length == 0) {
            setLast_date_pdetector("Never used");
          } else {
            setLast_date_pdetector(items[0].created_at);
          }
        });
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  const getImages_Pclassifier = async (token, a) => {
    try {
      await axios
        .get(ENDPOINT_PDETECTOR + "/get-images-data-service/" + a, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          var fecha = "";
          const items = response.data;
          setContador_img_pclassifier(items.length);
          for (let i = 0; i < items.length; i++) {
            fecha = items[i].created_at.split(" ");
            items[i].created_at = fecha[1] + "-" + fecha[2] + "-" + fecha[3] +" " +fecha[4].slice(0,5);
          }
          items.reverse();
          if (items.length == 0) {
            setLast_date_pclassifier("Never used");
          } else {
            setLast_date_pclassifier(items[0].created_at);
          }
        });
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  const getServicesActivobyid = async (user,tToken) => {
    try {
      await axios
        .post(
          ENDPOINT_NODE + "/active_servicesbyid",
          {
            user_id: user,
          },
          {
            headers: {
              Authorization: `Bearer ${tToken}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data[0])
          SetServiciosActivos2(response.data[0])
        });
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  return (
    <div className="container mt-5">
      <div className="card">
        <div className="card-header">
          <h3 className="h3">Statistics of {name}</h3>
        </div>
        <div className="card-body">
          <table className="table table-striped table-bordered table-hover table-responsive-sm">
            <thead>
              <tr>
                <th>
                  Services
                  <button value="images" onClick={handleSort} className="btn">
                    <i class="bi bi-display"></i>
                  </button>
                </th>
                <th>
                  Actived
                  <button value="images" onClick={handleSort} className="btn"  data-title="Services that are active">
                    <i class="bi bi-display"></i>
                  </button>
                </th>
                <th>
                  Images Sent
                  <button value="images" onClick={handleSort} className="btn" data-title="Number of images you have sent">
                    <i class="bi bi-images"></i>
                  </button>
                </th>
                <th>
                  Last Used Date
                  <button value="date" onClick={handleSort} className="btn " data-title="Last time you used the service">
                    <i class="bi bi-calendar"></i>
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              {/* {data.sort((a, b) => (a[sortBy] > b[sortBy]) ? 1 : -1).map(({ service, images, date }, index) => (
              <tr key={index}>
                <td>{service}</td>
                <td>{images}</td>
                <td>{date}</td>
              </tr>
            ))} */}
              <tr>
                <td>Eyectracking</td>
                {serviciosActivos2 && serviciosActivos2.eyetracking == "1" ? (
                  <td>Yes</td>
                ) : serviciosActivos2 && serviciosActivos2.eyetracking == "2" ? (
                  <td>Pendient</td>
                ) : (
                  <td>No</td>
                )}
                <td>{contador_img_eye}</td>
                <td>{last_date_eye}</td>
              </tr>
              <tr>
                <td>Rice Field </td>
                {serviciosActivos2 && serviciosActivos2.rice_field == 1 ? (
                  <td>Yes</td>
                ) : serviciosActivos2 && serviciosActivos2.rice_field == 2 ? (
                  <td>Pendient</td>
                ) : (
                  <td>No</td>
                )}
                <td>{contador_img_src}</td>
                <td>{last_date_src}</td>
              </tr>
              <tr>
                <td>Object detection and recognition V.1 </td>
                {serviciosActivos2 && serviciosActivos2.object_v1 == 1 ? (
                  <td>Yes</td>
                ) : serviciosActivos2 && serviciosActivos2.object_v1 == 2 ? (
                  <td>Pendient</td>
                ) : (
                  <td>No</td>
                )}
                <td>{contador_img_yolov1}</td>
                <td>{last_date_yolov1}</td>
              </tr>
              <tr>
                <td>Object detection and recognition V.2 </td>
                {serviciosActivos2 && serviciosActivos2.object_v2 == 1 ? (
                  <td>Yes</td>
                ) : serviciosActivos2 && serviciosActivos2.object_v2 == 2 ? (
                  <td>Pendient</td>
                ) : (
                  <td>No</td>
                )}
                <td>{contador_img_yolo}</td>
                <td>{last_date_yolo}</td>
              </tr>
              <tr>
                <td>Product Detector</td>
                {serviciosActivos2 && serviciosActivos2.product_detector == 1 ? (
                  <td>Yes</td>
                ) : serviciosActivos2 && serviciosActivos2.product_detector == 2 ? (
                  <td>Pendient</td>
                ) : (
                  <td>No</td>
                )}
                <td>{contador_img_pdetector}</td>
                <td>{last_date_pdetector}</td>
              </tr>
              <tr>
                <td>Product Classifier </td>
                {serviciosActivos2 && serviciosActivos2.product_classifier == 1 ? (
                  <td>Yes</td>
                ) : serviciosActivos2 && serviciosActivos2.product_classifier == 2 ? (
                  <td>Pendient</td>
                ) : (
                  <td>No</td>
                )}
                <td>{contador_img_pclassifier}</td>
                <td>{last_date_pclassifier}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td className="font-weight-bold text-lg">Total</td>
                <td></td>
                <td>{contador_img_eye+ contador_img_src+contador_img_yolo+contador_img_yolov1+contador_img_pdetector+contador_img_pclassifier}</td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Statistics;
