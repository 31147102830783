//entorno local

// export const ENDPOINT_NODE = "http://localhost:5000"
// export const ENDPOINT_PYTHON = "http://194.163.136.163:4001"
export const ENDPOINT_PYTHON = "https://suitepy.deepgaze.xyz"
// export const ENDPOINT_YOLO = "http://194.163.136.163:4002"
export const ENDPOINT_YOLO = "https://objrec.deepgaze.xyz"
//export const ENDPOINT_SRC = "http://194.163.136.163:4003"
export const ENDPOINT_SRC = "https://riceirrigation.deepgaze.xyz"
//export const ENDPOINT_PDETECTOR = "http://194.163.136.163:4005"
export const ENDPOINT_PDETECTOR = "https://productdet.deepgaze.xyz"
export const ENDPOINT_CLASSIFIER = "https://productdet.deepgaze.xyz"
export const ENDPOINT_VIDEO = "https://deepgaze02.ddns.net/aplicacion"

//entorno server 
export const ENDPOINT_NODE = "https://suitend.deepgaze.xyz"