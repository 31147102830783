import React , { useState, useEffect }from 'react';
import UploadFiles from '../Upload';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { useHistory } from 'react-router-dom';
import { ENDPOINT_NODE } from '../../backend';

const Servicio1 = () => {

    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const history = useHistory();    
    const[id_user,setId_user] = useState('');
    const [msg, setMsg] = useState("");
    const [serviciosActivos2, SetServiciosActivos2] = useState({});
    const [html,setHtml] = useState('')
    const [email,setEmail] = useState('')
    const [serviciosActivos, SetServiciosActivos] = useState([0, 0, 0, 0]);
    useEffect(() => {
        refreshToken();
        
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get(ENDPOINT_NODE+'/token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);            
            setId_user(decoded.userId)
            setEmail(decoded.email)
            // getClientes(decoded.userId, response.data.accessToken,decoded.email);
            setId_user(decoded.userId)
            getServicesActivobyid(decoded.userId,response.data.accessToken,decoded.email);
            setName(decoded.name);
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                history.push("/");
            }
        }
    }
    // const getClientes = async (user, tToken,email) => {
    //     try {
    //       await axios
    //         .post(ENDPOINT_NODE + "/clientById", {
    //           id_user: user,
    //         }, {
    //           headers: {
    //             Authorization: `Bearer ${tToken}`,
    //           }})
    //         .then((response) => {
    //           ServiciosActivos(response.data,email);
    //         });
    //     } catch (error) {
    //       if (error.response) {
    //       }
    //     }
    //   };
      
    
    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(ENDPOINT_NODE+'/token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setName(decoded.name);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
    const getServicesActivobyid = async (user,tToken,email) => {

      try {
        await axios
          .post(
            ENDPOINT_NODE + "/active_servicesbyid",
            {
              user_id: user,
            },
            {
              headers: {
                Authorization: `Bearer ${tToken}`,
              },
            }
          )
          .then((response) => {
            SetServiciosActivos2(response.data[0])
            cambiarhtml(user,email,response.data[0].eyetracking)
          });
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    // const ServiciosActivos = (servicios,email) => {
    //   var eyetracking = 0;
    //   var src = 0;
    //   var objectDetection = 0;
    //   const momentoServicio = servicios.map(function (servicio) {
    //     if (servicio.id_service == 1) {
    //       eyetracking = servicio.status;
    //     } else if (servicio.id_service == 2) {
    //       src = servicio.status;
    //     } else if (servicio.id_service == 3) {
    //       objectDetection = servicio.status;
    //     }
    //     return servicio;
    //   });
    //   SetServiciosActivos([eyetracking, src, objectDetection, 0]);
    //   cambiarhtml(id_user,email,eyetracking)
    // };
    const cambiarhtml = async (id,ema,eyectracking) => {
      console.log(eyectracking)
      const usuario = {
          servicio:1,
          id_cliente: id,
          email:ema
      }
      setHtml(
          <div className='card bg-dark'>
         
              <h2 className='text-white h2 text-center my-3'>EYETRACKING</h2>
              
              {(eyectracking==1 ? <>
              <h5 className='my-3 text-white h5  text-center'>Select the images you want to process by selection files on your computer</h5>
              <UploadFiles servicio={usuario}></UploadFiles>
              </>
              
              : history.push("/services")) }
              
          </div>
      )
  }
    return (
        <div className='my-5 container height' >
            {html}
        </div>
    );
}

export default Servicio1;
