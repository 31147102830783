import styled from "styled-components";

export const NavbarWrapper = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: black;
  position: fixed;
  top: 10vh;
  right: ${props => (props.open ? "0" : "-100%")};
  width: 100%;
  height: 90vh;
  transition: right 0.3s linear;
  z-index: 100;
  .desktop{
    flex-direction: row;
    position: initial;
    height: 70px;
    justify-content: center;
    background: black;
    .espacio{
        margin-top: 10px;
    }
    .navbar-item.presion.espacio:first-child{
       order: -1;
    }
    .button.espacio.is-light{
      order: 1;
    }
  }
  @media only screen and (min-width: 624px) {
    flex-direction: row;
    position: initial;
    height: 70px;
    justify-content: center;
    background: black;
    .espacio{
        margin-top: 10px;
    }
    .espacio2{
      margin-right: 19%;
    }
    .espacio3{
      margin-left: 19%;
    }
    .espacio4{
      margin-left: 19%;
    }
    .espacio5{
      margin-right: 19%;
    }
    @media (max-width: 1000px) {
      .espacio5{
        margin-right: 0;
      }
      .espacio4{
        margin-left: 0;
      }
  }
  }

  a {
    padding: 0.5rem 0.8rem;
    color: white;
    text-decoration: none;
  }
  .left{
  order: -1;
  float: left;
}
.right{
  order: 1;
  float: right;
}
`;