import React, { useState } from 'react'
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import  '../assets/css/styles.css';
import { ENDPOINT_NODE } from '../backend';
import Modal from './Modals/modal_userNew';
import useModal from './hooks/modals';
import Password from './password/password';
import { Toaster , toast } from 'react-hot-toast';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [login_fallido, setLogin_fallido] = useState('');
    const [msg, setMsg] = useState('');
    const history = useHistory();
    const [isOpen, openModal, closeModal] = useModal(false);

    const auth =  () => {

            axios({
                // Endpoint to send files
                url: ENDPOINT_NODE +'/login',
                method: "POST",
                // Attaching the form data
                data: {email: email,
                    password: password,
                },
              })
              .then((response) => {
                console.log(response)
                toast.error('Welcome to deepgaze');
                toast.error('Welcome to deepgaze ');
                 setTimeout(()=>history.push("/services"), 1000) 
                console.log("paso")
                setLogin_fallido('');
              }).catch(
                function (error) {
                  console.log('Show error notification!')
                  setLogin_fallido('Failed login');
                //   return Promise.reject(error)
                }
              );
            //  axios.post(ENDPOINT_NODE+'/login', {
            //     email: email,
            //     password: password
            // }).then((response) => {
            //     console.log(response)
            //     toast.error('Welcome to deepgaze');
            //     // toast.error('Welcome to deepgaze ');
            //     //  setTimeout(()=>history.push("/services"), 1000) 
            //     console.log("paso")
            //   });
            
            
            
            // history.push("/services");
       
    }
    const prueba = () => {
        toast.error('Welcome to deepgaze');
    }
    const register = () => {
        history.push("/register");
    }

    const recuperar = (e)=>{
        e.preventDefault()
        openModal()
        
    }
    return (
        <section className="hero has-background-grey-light is-fullheight is-fullwidth division2" >
            <div className="hero-body">
            
                <div className="container">
                <Toaster
                    position="bottom-center"
                    reverseOrder={false}
                    />
                    <div className="columns is-centered">
                        <div className="column is-4-desktop">
                        
                            <form className="box">
                                <a href="/">
                                    <i className="bi bi-arrow-left" data-title="Home"></i>
                                </a>
                                <p className="has-text-centered text-danger">{msg}</p>
                                <div className="field mt-5">
                                    <label className="label">Email</label>
                                    <div className="controls">
                                        <input type="text" className="input" placeholder="Username" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                </div>
                                <div className="field mt-5">
                                    <label className="label">Password</label>
                                    <div className="controls">
                                        <input type="password" className="input" placeholder="******" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    </div>
                                </div>
                                <div className="field mt-5">
                                    <button className="button is-success is-fullwidth btnInfo " onClick={(e) => auth(e.preventDefault())} disabled={email=='' || password==''} 
                                    data-title="Enter the system">Login</button>
                                </div>
                                <div className="field mt-5">
                                    <button className="button is-success is-fullwidth btnRegister" onClick={register}
                                    data-title="Register the system">Register</button>
                                    <br></br>
                                    <div className='text-center'><a className='texto-primary' onClick={recuperar} data-title="Recover your password by email"><u>Forgot password?</u> </a></div>
                                    <div className='text-center'><p className='text-warning h3' >{login_fallido}</p></div>
                                </div>
                            </form>
                           
                        </div>
                    </div>
                </div>
            </div>
            
            <Modal isOpenform={isOpen} closeform={closeModal}>
                <Password closeform={closeModal}></Password>
                
        </Modal>
        </section>
    )
}

export default Login
