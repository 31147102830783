import React, { useState, useEffect } from "react";
import SinServicio from "./FormulariosServicios.js/sinServicio";
import Servicio1 from "./FormulariosServicios.js/Servicio1";
import Servicio2 from "./FormulariosServicios.js/Servicio2";
import Servicio3 from "./FormulariosServicios.js/Servicio3";
import Servicio4 from "./FormulariosServicios.js/Servicio4";
import AdquirirServicio from "./FormulariosServicios.js/AdquirirServicio";
import { Link } from "react-router-dom";
import "../assets/css/styles.css";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom";
import weapon from "./../assets/weapon.jpg";
import crime from "./../assets/crime.png";
import rice_field from "./../assets/rice_field.png";
import recognition from "./../assets/recognition.jpg";
import plate from "./../assets/object_v1.jpg";
import product from "./../assets/product_detect.jpg";
import eyetracking from "./../assets/eyetracking.jpg";
import face_match from "./../assets/face_match.png";
import classifier from "./../assets/classifier.png"
import smartParking from "./../assets/smart parking.png"
import monitoreo from "./../assets/monitoreo.jpg"
import { ENDPOINT_NODE } from "../backend";
import Navbar from "./Navbar";
import Spinner from "./hooks/spinners";

const Services = () => {
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");
  const history = useHistory();
  const [msg, setMsg] = useState("");
  const [serviciosActivos, SetServiciosActivos] = useState([0, 0, 0, 0, 0]);
  const [serviciosActivos2, SetServiciosActivos2] = useState({});
  const [cliente, setCliente] = useState();
  const [id_user, setId_user] = useState();
  const [html, setHtml] = useState("");
  const contenido_CARTOES = [
    {
      titulo: "Object Detection and Recognition V.2",
      contenido:
        "Is a computer vision technique for identifying objects in images or videos",
      imagen: recognition,
    },
    {
      titulo: "Face Match",
      contenido:
        "It is a method of surveillance and control that allows the identification of the face of people",
      imagen: face_match,
    },
    {
      titulo: "Weapon Detection",
      contenido:
        "Technology is an ability for cameras to determine when a weapon is seen.",
      imagen: weapon,
    },
    {
      titulo: "Crime Detection",
      contenido:
        "It is a method of surveillance and control that allows the identification of the face of people",
      imagen: crime,
    },
    {
      titulo: "Eyectracking",
      contenido:
        "It is based on an eye tracking technology that will allow us to know the user's visual behavior when viewing an image.",
      imagen: eyetracking,
    },
    {
      titulo: "Rice Field Analysis",
      contenido:
        "The technology allows cameras to determine how to observe the evolution of a rice crop.",
      imagen: rice_field,
    },
    {
      titulo: "Product Detector",
      contenido: "A.I. that allows to detect the products of a food shelf",
      imagen: product,
    },
    {
      titulo: "Object Detection and Recognition V.1",
      contenido:"Is a computer vision technique for identifying objects in images or videos",
      imagen: plate,
    },
    {
      titulo: "Product Classifier",
      contenido: "A.I that allows to classify the products of a food line ",
      imagen:classifier,
    },
    {
      titulo: "Smart Parking",
      contenido: "A.I that allows to classify the products of a food line ",
      imagen:smartParking,
    },
    {
      titulo: "Video Analytics",
      contenido: "A.I that allows to classify the products of a food line ",
      imagen:monitoreo,
    }
  ];
  useEffect(() => {
    refreshToken();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(ENDPOINT_NODE + "/token");
      setToken(response.data.accessToken);
      const decoded = jwt_decode(response.data.accessToken);
      setId_user(decoded.userId);
      getServicesActivobyid(decoded.userId,response.data.accessToken);
      getClientes(decoded.userId, response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (error) {
      if (error.response) {
        history.push("/");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(ENDPOINT_NODE + "/token");
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwt_decode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getClientes = async (user, tToken) => {
    try {
      await axios
        .post(
          ENDPOINT_NODE + "/clientById",
          {
            id_user: user,
          },
          {
            headers: {
              Authorization: `Bearer ${tToken}`,
            },
          }
        )
        .then((response) => {
          setCliente(response.data);
          ServiciosActivos(response.data);
        });
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  const getServicesActive = async () => {
    try {
      await axios
        .get(
          ENDPOINT_NODE + "/active_services"
          // {
          //   headers: {
          //     Authorization: `Bearer ${tToken}`,
          //   },
          // }
        )
        .then((response) => {
        });
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  const getServicesActivobyid = async (user,tToken) => {
    try {
      await axios
        .post(
          ENDPOINT_NODE + "/active_servicesbyid",
          {
            user_id: user,
          },
          {
            headers: {
              Authorization: `Bearer ${tToken}`,
            },
          }
        )
        .then((response) => {
          SetServiciosActivos2(response.data[0])
        });
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  
  const ServiciosActivos = (servicios) => {
    var eyetracking = 0;
    var src = 0;
    var objectDetection = 0;
    var objectv1 = 0;

    const momentoServicio = servicios.map(function (servicio) {
      if (servicio.id_service == 1) {
        eyetracking = servicio.status;
      } else if (servicio.id_service == 2) {
        src = servicio.status;
      } else if (servicio.id_service == 3) {
        objectDetection = servicio.status;
      } else if (servicio.id_service == 6) {
        objectv1 = servicio.status;
      }else if (servicio.id_service == 8) {
        objectv1 = servicio.status;
      }
      return servicio;
    });
    SetServiciosActivos([eyetracking, src, objectDetection, 0, objectv1, 1]);
  };

  return (
    <div className="division">
      <Navbar />
      <div className="container ">
        <div className="h1 text-center text-white mt-5" >Our services </div>
        <div className="text-center">
          <h3 className="h3 text-white">Select the service you want to use</h3>
        </div>
        <div class="row custom-row mt-5">
          <div class="col-md-15 text-center">
            <div className=" tarjeta-card ">
              <a
                href={
                  serviciosActivos2 && serviciosActivos2.object_v1 == "1"
                    ? "/object-detection-v1"
                    : serviciosActivos2 && serviciosActivos2.object_v1 == "0"
                    ? "/adquirir6"
                    : "/pendiente"
                }
                className="a-tarjeta cartao2 fondo-cognitive "
              >
                <div className=" text-center ">
                  <img
                    className="imagen-card   mt-3"
                    src={contenido_CARTOES[7].imagen}
                    alt=""
                  />

                  <h3 className="cognitive-rosa mt-3 ">
                    {contenido_CARTOES[7].titulo}
                  </h3>
                  {/* <p className="text-white">{contenido_CARTOES[7].contenido}</p> */}
                </div>
              </a>
            </div>
          </div>
          <div class="col-md-15 text-white text-center">
          <div className=" tarjeta-card  " >
              <a
                href={
                  serviciosActivos2 && serviciosActivos2.object_v2 == "1"
                    ? "/object-detection-v2"
                    : serviciosActivos2 && serviciosActivos2.object_v2 == "0"
                    ? "/adquirir3"
                    : "/pendiente"
                }
                className="a-tarjeta cartao2 fondo-cognitive"
              >
                <div className=" text-center ">
                  <img
                    className="imagen-card  mt-3 "
                    src={contenido_CARTOES[0].imagen}
                    alt=""
                  />

                  <h3 className="cognitive-rosa mt-3">
                    {contenido_CARTOES[0].titulo}
                  </h3>
                  {/* <p className="text-white">{contenido_CARTOES[0].contenido}</p> */}
                </div>
              </a>
            </div> 
          </div>
          <div class="col-md-15 text-white text-center">
          <div className=" tarjeta-card">
              <a
                href={
                  serviciosActivos2 && serviciosActivos2.product_detector == "1"
                    ? "/product-detector"
                    : serviciosActivos2 && serviciosActivos2.product_detector == "0"
                    ? "/adquirir7"
                    : "/pendiente"
                }
                className="a-tarjeta cartao2 fondo-cognitive"
              >
                <div className=" text-center">
                  <img
                    className="imagen-card  mt-3"
                    src={contenido_CARTOES[6].imagen}
                    alt=""
                  />
                  <h3 className="cognitive-rosa mt-3 ">
                    {contenido_CARTOES[6].titulo}
                  </h3>
                  {/* <p className="text-white">{contenido_CARTOES[6].contenido}</p> */}
                </div>
              </a>
            </div>
          </div>
          <div class="col-md-15 text-white text-center">
          <div className=" tarjeta-card">
              <a
                href={
                  serviciosActivos2 && serviciosActivos2.eyetracking == "1"
                    ? "/eyectracking"
                    : serviciosActivos2 && serviciosActivos2.eyetracking == "0"
                    ? "/adquirir"
                    : "/pendiente"
                }
                className="a-tarjeta cartao2 fondo-cognitive"
              >
                <div className=" text-center">
                  <img
                    className="imagen-card  mt-3"
                    src={contenido_CARTOES[4].imagen}
                    alt=""
                  />
                  <h3 className="cognitive-rosa mt-3">
                    {contenido_CARTOES[4].titulo}
                  </h3>
                  {/* <p className="text-white" >{contenido_CARTOES[4].contenido}</p> */}
                </div>
              </a>
            </div>
          </div>
          <div class="col-md-15 text-white text-center">
          <div className=" tarjeta-card">
              <a
                href={
                  serviciosActivos2 && serviciosActivos2.rice_field == "1"
                    ? "/rice-field"
                    : serviciosActivos2 && serviciosActivos2.rice_field == "0"
                    ? "/adquirir4"
                    : "/pendiente"
                }
                className="a-tarjeta cartao2 fondo-cognitive"
              >
                <div className=" text-center">
                <img
                    className="imagen-card  mt-3"
                    src={contenido_CARTOES[5].imagen}
                    alt=""
                  />
                  <h3 className="cognitive-rosa mt-3">
                    {contenido_CARTOES[5].titulo}
                  </h3>
                  {/* <p className="text-white">{contenido_CARTOES[5].contenido}</p> */}
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="row custom-row mt-5">
          <div class="col-md-15 text-center">
          <div className=" tarjeta-card">
              <a
                href={
                  serviciosActivos2 && serviciosActivos2.app_video == "1"
                    ? "/app-video"
                    : serviciosActivos2 && serviciosActivos2.app_video == "0"
                    ? "/adquirir5"
                    : "/pendiente"
                }
                // href="/app-video"
                className="a-tarjeta cartao2 fondo-cognitive"
              >
                <div className=" text-center">
                  <img
                    className="imagen-card  mt-3"
                    src={contenido_CARTOES[10].imagen}
                    alt=""
                  />
                  <h3 className="cognitive-rosa mt-3">
                    {contenido_CARTOES[10].titulo}
                  </h3>
                  {/* <p className="text-white">{contenido_CARTOES[5].contenido}</p> */}
                </div>
              </a>
            </div>
          </div>
          <div class="col-md-15 text-white text-center">
          <div className=" tarjeta-card">
              <a
                href={
                  serviciosActivos2 && serviciosActivos2.eyetracking == "1"
                    ? "/servicio4"
                    : serviciosActivos2 && serviciosActivos2.eyetracking == "0"
                    ? "/adquirir"
                    : "/pendiente"
                }
                className="a-tarjeta cartao2 fondo-cognitive"
              >
                <div className=" text-center">
                  <img
                    className="imagen-card  mt-3"
                    src={contenido_CARTOES[3].imagen}
                    alt=""
                  />
                  <h3 className="cognitive-rosa mt-3">
                    {contenido_CARTOES[3].titulo}
                  </h3>
                  {/* <p className="text-white">{contenido_CARTOES[3].contenido}</p> */}
                </div>
              </a>
            </div> 
          </div>
          <div class="col-md-15 text-white text-center">
          <div className=" tarjeta-card">
              <a
                href={
                  serviciosActivos2 && serviciosActivos2.eyetracking == "1"
                    ? "/servicio4"
                    : serviciosActivos2 && serviciosActivos2.eyetracking == "0"
                    ? "/adquirir"
                    : "/pendiente"
                }
                className="a-tarjeta cartao2 fondo-cognitive"
              >
                <div className=" text-center">
                  <img
                    className="imagen-card  mt-3"
                    src={contenido_CARTOES[1].imagen}
                    alt=""
                  />
                  <h3 className="cognitive-rosa mt-3">
                    {contenido_CARTOES[1].titulo}
                  </h3>
                  {/* <p className="text-white">{contenido_CARTOES[1].contenido}</p> */}
                </div>
              </a>
            </div>
          </div>
          <div class="col-md-15 text-white text-center">
          <div className=" tarjeta-card">
              <a
                href={
                  serviciosActivos2 && serviciosActivos2.eyetracking == "1"
                    ? "/servicio4"
                    : serviciosActivos2 && serviciosActivos2.eyetracking == "0"
                    ? "/adquirir"
                    : "/pendiente"
                }
                className="a-tarjeta cartao2 fondo-cognitive"
              >
                <div className=" text-center">
                  <img
                    className="imagen-card  mt-3"
                    src={contenido_CARTOES[2].imagen}
                    alt=""
                  />
                  <h3 className="cognitive-rosa mt-3">
                    {contenido_CARTOES[2].titulo}
                  </h3>
                  {/* <p className="text-white">{contenido_CARTOES[2].contenido}</p> */}
                </div>
              </a>
            </div>
          </div>
          <div class="col-md-15 text-white text-center">
          <div className=" tarjeta-card">
              <a
                href={
                  serviciosActivos2 && serviciosActivos2.eyetracking == "1"
                    ? "/servicio4"
                    : serviciosActivos2 && serviciosActivos2.eyetracking == "0"
                    ? "/adquirir"
                    : "/pendiente"
                }
                className="a-tarjeta cartao2 fondo-cognitive"
              >
                <div className=" text-center">
                  <img
                    className="imagen-card  mt-3"
                    src={contenido_CARTOES[9].imagen}
                    alt=""
                  />
                  <h3 className="cognitive-rosa mt-3">
                    {contenido_CARTOES[9].titulo}
                  </h3>
                  {/* <p className="text-white">{contenido_CARTOES[2].contenido}</p> */}
                </div>
              </a>
            </div>
          </div>
        </div>
        
      </div>
      <br></br>
    </div>
  );
};

export default Services;
