import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import "../../../../assets/css/styles.css";
import axios from "axios";
import { useHistory } from "react-router-dom";
import {
  ENDPOINT_YOLO,
  ENDPOINT_NODE,
  ENDPOINT_PYTHON,
  ENDPOINT_SRC,
  ENDPOINT_PDETECTOR,
  ENDPOINT_CLASSIFIER,
} from "../../../../backend";
// import Modal from "../../../../components/Modals/modal_userNew";
import Modal from "react-modal";
import useModal from "../../../../components/hooks/modals";
import Select from "react-select";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  Media,
  Table,
  Row,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  PaginationItem,
  PaginationLink,
  Progress,
  Container,
  UncontrolledTooltip,
  Button,
} from "reactstrap";
import Pagination_yolo from "../../../../components/pagination_yolo";
import Pagination_src from "../../../../components/pagination_src";
import Pagination from "../../../../components/pagination";
import PaginationObjv1 from "../../../../components/pagination_objv1";
import PaginationPdetector from "../../../../components/pagination_detector";
import PaginationClassifier from "../../../../components/pagination_clasifiyer";
const PaginationAdmin = (props) => {
  var ayuda = [];
  const items = props.informacion;
  for (let i = 0; i < items.length; i++) {
    ayuda.push(i);
  }
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setitemsPerPage] = useState(5);
  const [nameImage, setNameImage] = useState("");
  const [vista, setVista] = useState(3);
  const [newImage, setNewImage] = useState();
  const [itemsImage, setItemsImage] = useState();
  const [imagen, setImagen] = useState("");
  const history = useHistory();
  const [arrayitems, setArrayItems] = useState([]);
  const [arrayitemsVeces, setArrayItemsVeces] = useState([]);
  const [token, setToken] = useState("");
  const [busqueda, setBusqueda] = useState("");
  const [tabladFiltrado, setTablaFiltrado] = useState(props.informacion);
  const [valores, setValores] = useState(props.informacion);
  const [valoresaux, setValoresAux] = useState(props.ayuda);
  const [expire, setExpire] = useState("");
  const [usersAll, setUsersAll] = useState([]);
  const [cambio, setCambio] = useState(true);
  const [isOpen, openModal, closeModal] = useModal(false);
  const [msg, setMsg] = useState("");
  const [html, setHtml] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      width: "80px", // Ajusta el ancho según tus necesidades
    }),
  };
  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setitemsPerPage(selectedOption.value);
    filtrarArray(props.informacion);
  };
  const originalOptions = [
    { value: 5, label: "5" },
    { value: 10, label: "10" },
    { value: 25, label: "25" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
    { value: 250, label: "250" },
  ];
  const options = originalOptions.map((option) => ({
    value: option.value,
    label: option.label,
  }));
  React.useEffect(() => {
    filtrarArray(props.informacion);
  }, []);

  const filtrarArray = (array) => {
    var ayuda2 = [];
    var items2 = array;
    for (let i = 0; i < items2.length; i++) {
      ayuda2.push(i);
    }
    setValores(items2);
    setValoresAux(ayuda2);

    setCurrentItems(ayuda2.slice(itemOffset, itemOffset + itemsPerPage));
    setPageCount(Math.ceil(items2.length / itemsPerPage));
  };
  const handleChange = (e) => {
    setBusqueda(e.target.value);
    filtrar(e.target.value);
  };
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(valoresaux.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(valores.length / itemsPerPage));
  }, [itemOffset, itemsPerPage]);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % valores.length;

    setItemOffset(newOffset);
  };
  const filtrar = (terminoBusqueda) => {
    var resultadosBusqueda = tabladFiltrado.filter((elemento) => {
      if (
        (elemento.servicio != null &&
          elemento.servicio
            .toString()
            .includes(terminoBusqueda.toLowerCase())) ||
        (elemento.name != null &&
          elemento.name.toString().includes(terminoBusqueda.toLowerCase())) ||
        (elemento.email != null &&
          elemento.email.toString().includes(terminoBusqueda.toLowerCase()))
        //      ||
        // (elemento.id != null &&
        //   elemento.id.toString().includes(terminoBusqueda.toLowerCase()))
      ) {
        return elemento;
      }
    });

    filtrarArray(resultadosBusqueda);
  };

  /////////////////////////////NUEVO///////////////////////////////////////
  const update_type = async (type, id, tToken, email) => {
    console.log("email");
    console.log(email);
    try {
      await axios.post(
        ENDPOINT_NODE + "/update-user-type",
        {
          type: type,
          id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${tToken}`,
          },
        }
      );
      if (type == 20) {
        activarServices(id, "eyetracking", email, 1);
        activarServices(id, "rice_field", email, 1);
        activarServices(id, "object_v1", email, 1);
        activarServices(id, "object_v2", email, 1);
        activarServices(id, "product_detector", email, 1);
        activarServices(id, "product_classifier", email, 1);
        activarServices(id, "app_video", email, 1);
        tenerTokenClassifier(id, email, 20);
        tenerTokenEyetracking(id, email, 20);
        tenerTokenObjectV2(id, email, 20);
        tenerTokenSRC(id, email, 20);
        setTimeout(()=>history.push("/cms/userAdmin"),2000)
      } else if (type == 0) {
        activarServices(id, "eyetracking", email, 0);
        activarServices(id, "rice_field", email, 0);
        activarServices(id, "object_v1", email, 0);
        activarServices(id, "object_v2", email, 0);
        activarServices(id, "product_detector", email, 0);
        activarServices(id, "product_classifier", email, 0);
        activarServices(id, "app_video", email, 0);
        tenerTokenClassifier(id, email, 0);
        tenerTokenEyetracking(id, email, 0);
        tenerTokenObjectV2(id, email, 0);
        tenerTokenSRC(id, email, 0);
        setTimeout(()=>history.push("/cms/userAdmin"),2000)
      }
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  const activarServices = async (user, service, email, status) => {
    try {
      await axios.post(ENDPOINT_NODE + "/change-status", {
        user_id: user,
        service: service,
        status: status,
      });
      //   activarServicioServer(email, service);
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  ////////////////////////Classifier//////////////////////////////////
  const tenerTokenClassifier = (id, email, valor) => {
    let formData = new FormData();
    formData.append("username", "cristian@santabros.com.ar");
    formData.append("password", "carlos");
    axios({
      // Endpoint to send files
      url: ENDPOINT_CLASSIFIER + "/get-token",
      method: "POST",
      // Attaching the form data
      data: formData,
    }).then((response) => {
      verificarUserClassifier(email, response.data.access_token, id, valor);
    });
  };

  const activarClassifier = async (id_user, email, tokenApi) => {
    try {
      let formData = new FormData();
      formData.append("username", email);
      formData.append("password", "superAdmin123456");
      formData.append("ip_address", "");
      formData.append("id_user", id_user);
      formData.append("status", 1);
      axios({
        url: ENDPOINT_CLASSIFIER + "/create-user-token",
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + tokenApi,
        },
        // Attaching the form data
        data: formData,
      }).then((response) => {
        console.log(response.data);
        console.log("se creo Usuario");
      });
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  const cambiarClassifier = (usuario, status, tok) => {
    let formData = new FormData();
    formData.append("username", usuario);
    formData.append("status", status);
    axios({
      // Endpoint to send files
      url: ENDPOINT_CLASSIFIER + "/change-status-usertoken",
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + tok,
      },
      // Attaching the form data
      data: formData,
    }).then((response) => {
      //   window.location.reload();
    });
  };
  const verificarUserClassifier = async (email, token, id, valor) => {
    try {
      await axios
        .get(ENDPOINT_CLASSIFIER + "/get-isuser-token/" + email, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (valor == 20 && !response.data.exists) {
            activarClassifier(id, email, token);
          } else if (valor == 20 && response.data.exists) {
            cambiarClassifier(email, 1, token);
          } else if (valor == 0) {
            cambiarClassifier(email, 0, token);
          }
          console.log(response.data.exists);
        });
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  //////////////////////// product detector //////////////////////////////////

  ////////////////////////Rice Field //////////////////////////////////
  const tenerTokenSRC = (id, email, valor) => {
    let formData = new FormData();
    formData.append("username", "cristian@santabros.com.ar");
    formData.append("password", "carlos");
    axios({
      // Endpoint to send files
      url: ENDPOINT_SRC + "/get-token",
      method: "POST",
      // Attaching the form data
      data: formData,
    }).then((response) => {
      verificarUserSRC(email, response.data.access_token, id, valor);
    });
  };

  const activarSRC = async (id_user, email, tokenApi) => {
    console.log("-------------------------");
    console.log(email);
    try {
      let formData = new FormData();
      formData.append("username", email);
      formData.append("password", "superAdmin123456");
      formData.append("ip_address", "");
      formData.append("id_user", id_user);
      formData.append("status", 1);
      axios({
        url: ENDPOINT_SRC + "/create-user-token",
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + tokenApi,
        },
        // Attaching the form data
        data: formData,
      }).then((response) => {
        console.log(response.data);
        console.log("se creo Usuario");
      });
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  const cambiarSRC = (usuario, status, tok) => {
    let formData = new FormData();
    formData.append("username", usuario);
    formData.append("status", status);
    axios({
      // Endpoint to send files
      url: ENDPOINT_SRC + "/change-status-usertoken",
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + tok,
      },
      // Attaching the form data
      data: formData,
    }).then((response) => {
      //   window.location.reload();
    });
  };
  const verificarUserSRC = async (email, token, id, valor) => {
    try {
      await axios
        .get(ENDPOINT_SRC + "/get-isuser-token/" + email, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (valor == 20 && !response.data.exists) {
            activarSRC(id, email, token);
          } else if (valor == 20 && response.data.exists) {
            cambiarSRC(email, 1, token);
          } else if (valor == 0) {
            cambiarSRC(email, 0, token);
          }
          console.log(response.data.exists);
        });
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  ////////////////////////eyetracking//////////////////////////////////

  const tenerTokenEyetracking = (id, email, valor) => {
    let formData = new FormData();
    formData.append("username", "cristian@santabros.com.ar");
    formData.append("password", "carlos");
    axios({
      // Endpoint to send files
      url: ENDPOINT_PYTHON + "/get-token",
      method: "POST",
      // Attaching the form data
      data: formData,
    }).then((response) => {
      verificarUserEyectracking(email, response.data.access_token, id, valor);
      
    });
  };

  const activarEyetracking = async (id_user, email, tokenApi) => {
    console.log("-------------------------");
    console.log(email);
    try {
      let formData = new FormData();
      formData.append("username", email);
      formData.append("password", "superAdmin123456");
      formData.append("ip_address", "");
      formData.append("id_user", id_user);
      formData.append("status", 1);
      axios({
        url: ENDPOINT_PYTHON + "/create-user-token",
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + tokenApi,
        },
        // Attaching the form data
        data: formData,
      }).then((response) => {
        console.log(response.data);
        console.log("se creo Usuario");
      });
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  const cambiaroEye = (usuario, status, tok,id,valor) => {
    let formData = new FormData();
    formData.append("username", usuario);
    formData.append("status", status);
    axios({
      // Endpoint to send files
      url: ENDPOINT_PYTHON + "/change-status-usertoken",
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + tok,
      },
      // Attaching the form data
      data: formData,
    }).then((response) => {
      verificarUserObjecV1(usuario, tok, id, valor)
      //   window.location.reload();
    });
  };

  const verificarUserEyectracking = async (email, token, id, valor) => {
    try {
      await axios
        .get(ENDPOINT_PYTHON + "/get-isuser-token/" + email, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log("Eyectraking");
          console.log(response.data.exists);
        //falta ver si existe en el objectv1
          if (valor == 20 && !response.data.exists) {
            activarEyetracking(id, email, token);
          } else if (valor == 20 && response.data.exists) {
            cambiaroEye(email, 1, token,id,valor);
          } else if (valor == 0 && response.data.exists) {
            cambiaroEye(email, 0, token,id,valor);
          }
          
          
        });
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  ////////////////////////objec V1 //////////////////////////////////
  //el token del object es el mismo que el del eyectraking
  const activarObjectv1 = async (id_user, email, tokenApi) => {
    console.log("-------------------------");
    console.log(email);
    try {
      let formData = new FormData();
      formData.append("username", email);
      formData.append("password", "superAdmin123456");
      formData.append("ip_address", "");
      formData.append("id_user", id_user);
      formData.append("status", 1);
      axios({
        url: ENDPOINT_PYTHON + "/create-user-token-yolo",
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + tokenApi,
        },
        // Attaching the form data
        data: formData,
      }).then((response) => {
        console.log(response.data);
        console.log("se creo Usuario");
      });
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  const cambiarobjv1 = (usuario, status, tok) => {
    let formData = new FormData();
    formData.append("username", usuario);
    formData.append("status", status);
    axios({
      // Endpoint to send files
      url: ENDPOINT_PYTHON + "/change-status-usertoken-yolo",
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + tok,
      },
      // Attaching the form data
      data: formData,
    }).then((response) => {
      //   window.location.reload();
    });
  };
  const verificarUserObjecV1 = async (email, token, id, valor) => {
    try {
      await axios
        .get(ENDPOINT_PYTHON + "/get-isuser-token-yolo/" + email, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log("V1");
          console.log(response.data.exists);
        //falta ver si existe en el objectv1
          if (valor == 20 && !response.data.exists) {
            activarObjectv1(id, email, token);
          } else if (valor == 20 && response.data.exists) {
            cambiarobjv1(email, 1, token);
          } else if (valor == 0) {
            cambiarobjv1(email, 0, token);
          }
        });
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  ////////////////////////objec V2 //////////////////////////////////
  const tenerTokenObjectV2 = (id, email, valor) => {
    let formData = new FormData();
    formData.append("username", "cristian@santabros.com.ar");
    formData.append("password", "carlos");
    axios({
      // Endpoint to send files
      url: ENDPOINT_YOLO + "/get-token",
      method: "POST",
      // Attaching the form data
      data: formData,
    }).then((response) => {
        verificarUserObjecV2(email, response.data.access_token, id, valor);
    });
  };

  const activarObjectv2 = async (id_user, email, tokenApi) => {
    console.log("-------------------------");
    console.log(email);
    try {
      let formData = new FormData();
      formData.append("username", email);
      formData.append("password", "superAdmin123456");
      formData.append("ip_address", "");
      formData.append("id_user", id_user);
      formData.append("status", 1);
      axios({
        url: ENDPOINT_YOLO + "/create-user-token",
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + tokenApi,
        },
        // Attaching the form data
        data: formData,
      }).then((response) => {
        console.log(response.data);
        console.log("se creo Usuario");
      });
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  const cambiarobjv2 = (usuario, status, tok) => {
    let formData = new FormData();
    formData.append("username", usuario);
    formData.append("status", status);
    axios({
      // Endpoint to send files
      url: ENDPOINT_YOLO + "/change-status-usertoken",
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + tok,
      },
      // Attaching the form data
      data: formData,
    }).then((response) => {
      //   window.location.reload();
    });
  };
  const verificarUserObjecV2 = async (email, token, id, valor) => {
    try {
      await axios
        .get(ENDPOINT_YOLO + "/get-isuser-token/" + email, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (valor == 20 && !response.data.exists) {
            activarObjectv2(id, email, token);
          } else if (valor == 20 && token) {
            cambiarobjv2(email, 1, token);
          } else if (valor == 0) {
            cambiarobjv2(email, 0, token);
          }
          console.log(response.data.exists);
        });
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  ///////////////////////////////////////////////////////////////////////////
  const obtener_listado = async (token, endpoint, usuario, servicio) => {
    setHtml("");
    if (servicio == "object_v1") {
      try {
        await axios
          .get(endpoint + "/get-images-data-service-yolo/" + usuario, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + token,
            },
          })
          .then((response) => {
            var fecha = "";
            const items = response.data;
            for (let i = 0; i < items.length; i++) {
              fecha = items[i].created_at.split(" ");
              items[i].created_at =
                fecha[1] +
                "-" +
                fecha[2] +
                "-" +
                fecha[3] +
                " " +
                fecha[4].slice(0, 5);
            }
            var ayuda2 = [];
            for (let i = 0; i < items.length; i++) {
              ayuda2.push(i);
            }
            if (endpoint == ENDPOINT_YOLO) {
              setHtml(
                <Pagination_yolo
                  informacion={items.reverse()}
                  ayuda={ayuda2}
                ></Pagination_yolo>
              );
            } else if (
              endpoint == ENDPOINT_PYTHON &&
              servicio == "eyetracking"
            ) {
              setHtml(
                <Pagination
                  informacion={items.reverse()}
                  ayuda={ayuda2}
                ></Pagination>
              );
            } else if (endpoint == ENDPOINT_PYTHON && servicio == "object_v1") {
              setHtml(
                <PaginationObjv1
                  informacion={items.reverse()}
                  ayuda={ayuda2}
                ></PaginationObjv1>
              );
            } else if (endpoint == ENDPOINT_SRC) {
              setHtml(
                <Pagination_src
                  informacion={items.reverse()}
                  ayuda={ayuda2}
                ></Pagination_src>
              );
            } else if (
              endpoint == ENDPOINT_PDETECTOR &&
              servicio == "product_detector"
            ) {
              setHtml(
                <PaginationPdetector
                  informacion={items.reverse()}
                  ayuda={ayuda2}
                ></PaginationPdetector>
              );
            } else if (
              endpoint == ENDPOINT_CLASSIFIER &&
              servicio == "product_classifier"
            ) {
              setHtml(
                <PaginationClassifier
                  informacion={items.reverse()}
                  ayuda={ayuda2}
                ></PaginationClassifier>
              );
            }
          });
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    } else {
      try {
        await axios
          .get(endpoint + "/get-images-data-service/" + usuario, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + token,
            },
          })
          .then((response) => {
            var fecha = "";
            const items = response.data;
            for (let i = 0; i < items.length; i++) {
              fecha = items[i].created_at.split(" ");
              items[i].created_at =
                fecha[1] +
                "-" +
                fecha[2] +
                "-" +
                fecha[3] +
                " " +
                fecha[4].slice(0, 5);
            }
            var ayuda2 = [];
            for (let i = 0; i < items.length; i++) {
              ayuda2.push(i);
            }
            if (endpoint == ENDPOINT_YOLO) {
              setHtml(
                <Pagination_yolo
                  informacion={items.reverse()}
                  ayuda={ayuda2}
                ></Pagination_yolo>
              );
            } else if (
              endpoint == ENDPOINT_PYTHON &&
              servicio == "eyetracking"
            ) {
              setHtml(
                <Pagination
                  informacion={items.reverse()}
                  ayuda={ayuda2}
                ></Pagination>
              );
            } else if (endpoint == ENDPOINT_PYTHON && servicio == "object_v1") {
              setHtml(
                <PaginationObjv1
                  informacion={items.reverse()}
                  ayuda={ayuda2}
                ></PaginationObjv1>
              );
            } else if (endpoint == ENDPOINT_SRC) {
              setHtml(
                <Pagination_src
                  informacion={items.reverse()}
                  ayuda={ayuda2}
                ></Pagination_src>
              );
            } else if (
              endpoint == ENDPOINT_PDETECTOR &&
              servicio == "product_detector"
            ) {
              setHtml(
                <PaginationPdetector
                  informacion={items.reverse()}
                  ayuda={ayuda2}
                ></PaginationPdetector>
              );
            } else if (
              endpoint == ENDPOINT_CLASSIFIER &&
              servicio == "product_classifier"
            ) {
              setHtml(
                <PaginationClassifier
                  informacion={items.reverse()}
                  ayuda={ayuda2}
                ></PaginationClassifier>
              );
            }
          });
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    }
  };

  const registro = (e, usuario, servicio) => {
    console.log(servicio);
    let EndPoint = "";
    e.preventDefault();
    if (servicio == "eyetracking") {
      EndPoint = ENDPOINT_PYTHON;
    } else if (servicio == "rice_field") {
      EndPoint = ENDPOINT_SRC;
    } else if (servicio == "object_v2") {
      EndPoint = ENDPOINT_YOLO;
    } else if (servicio == "object_v1") {
      EndPoint = ENDPOINT_PYTHON;
    } else if (servicio == "product_detector") {
      EndPoint = ENDPOINT_PDETECTOR;
    } else if (servicio == "product_classifier") {
      EndPoint = ENDPOINT_CLASSIFIER;
    }
    openModal();
    if (servicio == "object_v1") {
      let formData = new FormData();
      formData.append("username", "cristian@santabros.com.ar");
      formData.append("password", "carlos");
      axios({
        // Endpoint to send files

        url: EndPoint + "/get-token-yolo",
        method: "POST",
        // Attaching the form data
        data: formData,
      }).then((response) => {
        obtener_listado(
          response.data.access_token,
          EndPoint,
          usuario,
          servicio
        );
      });
    } else {
      let formData = new FormData();
      formData.append("username", "cristian@santabros.com.ar");
      formData.append("password", "carlos");
      axios({
        // Endpoint to send files

        url: EndPoint + "/get-token",
        method: "POST",
        // Attaching the form data
        data: formData,
      }).then((response) => {
        obtener_listado(
          response.data.access_token,
          EndPoint,
          usuario,
          servicio
        );
      });
    }
  };

  return (
    <>
      <Row>
        <div className="col">
          <Card className="shadow">
            <div className="row">
              <CardHeader className="border-0">
                <div className="col-6"></div>
                <div className="col-6">
                  <div className="input-group rounded">
                    <input
                      type="search"
                      className="form-control "
                      onChange={handleChange}
                      value={busqueda}
                      placeholder="Search"
                      aria-label="Search"
                      aria-describedby="search-addon"
                    />
                  </div>
                </div>
              </CardHeader>
            </div>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Users</th>
                  <th scope="col">Email</th>
                  <th scope="col">SuperAdmin</th>
                  <th scope="col">CreateADmin</th>
                </tr>
              </thead>
              <tbody>
                {valores.length != 0 &&
                  currentItems &&
                  currentItems.map((item, index) => (
                    <tr key={index}>
                      <th scope="row">
                        <Media className="align-items-center">
                          <Media>
                            <span className="mb-0 text-sm">
                              {valores[item].name}
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>{valores[item].email}</td>
                      <td>
                        <Badge color="" className="badge-dot mr-4 ">
                          {valores[item].type != 20 && (
                            <span className="text-black">Standar User</span>
                          )}
                          {valores[item].type == 20 && (
                            <span className="text-success">SuperAdmin</span>
                          )}
                        </Badge>
                      </td>
                      <td>
                        {valores[item].type == 20 &&
                          valores[item].email != props.email && (
                            <Button
                              color="danger"
                              disabled={!cambio}
                              onClick={
                                () =>
                                  update_type(
                                    0,
                                    valores[item].id,
                                    props.token,
                                    valores[item].email
                                  )
                                // props.baja(
                                //   valores[item].id,
                                //       valores[item].servicio,
                                //       valores[item].email
                                // )
                              }
                            >
                              <i
                                class="bi bi-x-square"
                                data-title2="Unsubscribe userAdmin"
                              ></i>
                            </Button>
                          )}
                        {valores[item].type == 20 &&
                          valores[item].email == props.email && (
                            <p>cannot be deleted</p>
                          )}
                        {valores[item].type != 20 && (
                          <Button
                            color="success"
                            disabled={!cambio}
                            onClick={() =>
                              update_type(
                                20,
                                valores[item].id,
                                props.token,
                                valores[item].email
                              )
                            }
                          >
                            <i
                              class="bi bi-check"
                              data-title2="Subscribe userAdmin"
                            ></i>
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
                {valores.length == 0 && (
                  <tr className="h1 text-center">
                    <td>Does not have user active</td>
                  </tr>
                )}
              </tbody>
            </Table>
            <CardFooter className="text-center">
              <div className="text-center text-white alinear my-5">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="< "
                  renderOnZeroPageCount={null}
                  containerClassName="pagination justify-content-center"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  activeClassName="active"
                />
              </div>
            </CardFooter>
            <div className="row">
              <div className="col-4 ml-5 mb-3 d-flex align-items-center">
                <label className="text-dark mr-2">Number of rows: </label>
                <span>
                  <Select
                    options={options}
                    menuPlacement="top"
                    placeholder=""
                    onChange={handleOptionChange}
                    styles={customStyles}
                  />
                </span>
              </div>
            </div>
          </Card>
        </div>
      </Row>

      <br></br>
      <br />
      <br />
      <div>
        <Modal
          className="text-center modal-container-register"
          isOpen={isOpen}
          onRequestClose={closeModal}
        >
          {html}
        </Modal>
      </div>
    </>
  );
};

export default PaginationAdmin;
