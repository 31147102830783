import React  from 'react';
import { useHistory } from 'react-router-dom';
import Navbar from '../Navbar';
const Enespera = (props) => {
    const history =useHistory()
    const goback =() =>{
        history.push("/services")
    }
    return (
        <div>
            <Navbar></Navbar>
            <div className='height'>
            <div className='container text-center mt-6 '>
                <div className='row'>
                    <h1 className='h1 text-white mb-5'>You have already sent a request to activate the service.</h1>
                    <div className='col-6 mb-4'>
                        <p className='text-white mb-2' >See more services</p>
                        <button className='btn btn-primary btn-lg' onClick={goback}
                        data-title="See all services" >Go back</button>
                    </div>
                    <div className='col-6 mb-4'>
                        <p className='text-white mb-2'>Do you want more information?</p>
                        <button className='btn btn-primary btn-lg' data-title="Currently disabled">More information</button>
                    </div>
                </div>
                <div className='row'>
                    <small className='text-white'> The administrator has to accept your request so that you can enjoy the service.</small>

                </div>
            </div>
            
        </div>
        </div>
    );
}

export default Enespera;
